import React from 'react';

import locales from './locales';
import { en, nl, fr, de } from './messages';

export const messages: Record<string, Record<string, string>> = {
  en,
  fr,
  nl,
  de,
};

export { default as flags } from './flags';
export { default as locales } from './locales';

const defaultLocale = process.env.DEFAULT_LOCALE || locales[0];

export const IntlContext = React.createContext(defaultLocale);
export const IntlContextProvider = IntlContext.Provider;
export const IntlContextConsumer = IntlContext.Consumer;
