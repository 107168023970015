import * as Utils from './common';

export { default as useScrollDirection } from './useScrollDirection';
export { default as search } from './search';
export { default as Config } from './config';
export { default as handleNetlifyFormSubmission } from './netlify';
export { default as handleNewsletterFormSubmission } from './newsletter';

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default Utils;
