import * as React from 'react';
import { StyledNavigation, StyledNavItem, StyledSelectedNavItem } from './Styled';
import Cta from '../../Cta';
import { NavItemType } from '../types';

interface Props {
  items: Array<NavItemType>;
  currentHoverItem: NavItemType | null;
  activeMenuItem: NavItemType | null;
  onMouseEnter: (item: NavItemType) => void;
  variant?: string;
}

const PrimaryNavigation = ({ items, activeMenuItem, currentHoverItem, onMouseEnter, variant }: Props) => {
  const [clicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    setClicked(false);
  }, [currentHoverItem]);
  return (
    <StyledNavigation variant={variant}>
      {items.map((item: NavItemType, idx: number) => {
        if (activeMenuItem && activeMenuItem.to === item.to) {
          return (
            <StyledSelectedNavItem
              // eslint-disable-next-line react/no-array-index-key
              key={`primary-active-${item.to}-${idx}`}
              onMouseEnter={() => onMouseEnter(item)}
              onMouseLeave={() => onMouseEnter({} as NavItemType)}
              onFocus={() => onMouseEnter(item)}
              variant={variant}
            >
              <Cta
                to={item.to}
                onClick={e => {
                  if (!clicked && currentHoverItem && currentHoverItem.to === item.to) {
                    e.preventDefault();
                    setClicked(true);
                  }
                  onMouseEnter(item);
                }}
              >
                {item.label}
              </Cta>
            </StyledSelectedNavItem>
          );
        }

        return (
          <StyledNavItem
            // eslint-disable-next-line react/no-array-index-key
            key={`primary-${item.to}-${idx}`}
            onMouseEnter={() => onMouseEnter(item)}
            onMouseLeave={() => onMouseEnter({} as NavItemType)}
            variant={variant}
            onFocus={() => onMouseEnter(item)}
          >
            <Cta
              to={item.to}
              onClick={e => {
                if (!clicked && currentHoverItem && currentHoverItem.to === item.to) {
                  e.preventDefault();
                  setClicked(true);
                }
                onMouseEnter(item);
              }}
            >
              {item.label}
            </Cta>
          </StyledNavItem>
        );
      })}
    </StyledNavigation>
  );
};

export default PrimaryNavigation;
