import * as React from 'react';
import { useIntl } from 'react-intl';
//
import EventCardProps from './types';
import Cta from '../../Cta';
import Arrow from '../../../icons/Arrow';
import Calendar from '../../../icons/Calendar';
import LocationPin from '../../../icons/LocationPin';
import {
  StyledTitle,
  StyledImageBoxLink,
  StyledImageBox,
  StyledContentBox,
  StyledEventDetails,
  StyledEventDate,
  StyledEventLocation,
  StyledDescription,
  StyledCtaWrapper,
  StyledCta,
  StyledContentWrapper,
} from './Styled';

const Vertical = ({ title, shortDescription, image, link, displayDate, location }: EventCardProps) => {
  const intl = useIntl();

  return (
    <StyledContentWrapper>
      {image ? (
        <StyledImageBoxLink to={link}>
          <StyledImageBox
            sx={{
              backgroundImage: `url(${image})`,
            }}
          />
        </StyledImageBoxLink>
      ) : null}

      <StyledContentBox>
        <StyledTitle>
          <Cta to={link}>{title}</Cta>
        </StyledTitle>
        {displayDate || location ? (
          <StyledEventDetails>
            <StyledEventDate>
              {displayDate && <Calendar />}
              <span>{displayDate || null}</span>
            </StyledEventDate>
            <StyledEventLocation>
              {location && <LocationPin />}
              <span>{location || null}</span>
            </StyledEventLocation>
          </StyledEventDetails>
        ) : null}
        <StyledDescription>{shortDescription}</StyledDescription>

        <StyledCtaWrapper>
          <StyledCta to={link}>
            <Arrow />
            <span>{intl.formatMessage({ id: 'cards.event.link' })}</span>
          </StyledCta>
        </StyledCtaWrapper>
      </StyledContentBox>
    </StyledContentWrapper>
  );
};

export default Vertical;
