import React from 'react';

import { StyledModuleContainer } from '../../Layout/Styled';
import ShortBanner from '../../ShortBanner';

const ShortBannerModule = ({ ...rest }) => (
  <StyledModuleContainer smallTopSpacer>
    <ShortBanner {...(rest as any)} />
  </StyledModuleContainer>
);

export default ShortBannerModule;
