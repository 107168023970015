import styled from 'styled-components';

export const StyledArrow = styled.span`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 4.25rem;
  cursor: pointer;

  > span {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;

    > svg {
      > * {
        fill: ${props => props.theme.mobileNavigation.chevronColor};
      }
    }
  }
`;

export const StyledSubMenu = styled.ul`
  background-color: ${props => props.theme.colors.superLightGrey};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const StyledSubMenuItem = styled.li`
  background-color: ${props => props.theme.colors.superLightGrey};
  position: relative;
  min-height: 3.5rem;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 1.25rem;
      height: 1px;
      background: ${props => props.theme.colors.lightGray};
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: ${props => props.theme.navItems.textTransform};
    letter-spacing: ${props => props.theme.navItems.letterSpacing};
    text-decoration: none;
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: ${props => props.theme.fontWeights.heading};
    font-size: 0.875rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    padding-left: 2.5rem;
    width: 100%;
    height: 100%;

    > span:first-child {
      width: calc(100% - 4.25rem);
    }

    svg,
    svg > * {
      fill: ${props => props.theme.mobileNavigation.arrowColor};
    }

    color: ${props => props.theme.colors.darkGray};
    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledNavItemWithChildren = styled.li`
  list-style: none;
  color: ${props => props.theme.colors.darkGray};

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  }

  .mobile-menu-cta {
    height: 4.5rem;
    display: flex;
    width: 100%;

    a {
      display: flex;
      align-items: center;
      text-transform: ${props => props.theme.navItems.textTransform};
      letter-spacing: ${props => props.theme.navItems.letterSpacing};
      text-decoration: none;
      font-size: ${props => props.theme.fontSizes[2]};
      font-weight: ${props => props.theme.fontWeights.heading};
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      padding: 0 0.5rem 0 1.5rem;
      width: calc(100% - 2rem - 4.25rem);
      height: 100%;

      color: ${props => props.theme.colors.darkGray};

      &:hover {
        color: ${props => props.theme.colors.black};
      }
    }
  }

  ${StyledSubMenu} {
    border-top: 1px solid ${props => props.theme.colors.lightGray};
    border-bottom: none;

    ${StyledSubMenuItem} {
      width: 100%;

      &:not(:last-child) {
        &::after {
          left: 0;
        }
      }
    }
  }
`;

export const StyledNavItem = styled.li`
  height: 4.5rem;
  list-style: none;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  }

  a {
    display: flex;
    align-items: center;
    text-transform: ${props => props.theme.navItems.textTransform};
    letter-spacing: ${props => props.theme.navItems.letterSpacing};
    text-decoration: none;
    font-size: ${props => props.theme.fontSizes[2]};
    font-weight: ${props => props.theme.fontWeights.heading};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    padding: 0 1.5rem;
    width: calc(100% - 3rem);
    height: 100%;

    color: ${props => props.theme.colors.darkGray};
    &:hover {
      color: ${props => props.theme.colors.black};
    }
  }
`;
