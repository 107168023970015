/* eslint-disable react/require-default-props */
import React from 'react';
import { useIntl } from 'react-intl';
import { StyledModuleContainer } from '../../Layout/Styled';

import {
  StyledModuleHeading,
  StyledModuleDescription,
  StyledLoadMoreWrapper,
  StyledHeadingAndDescriptionContainer,
  StyledHeadingAndDescriptionWrapper,
} from './Styled';
import Button from '../../Button';
import Cta from '../../Cta';
import { Vertical } from './Vertical/Vertical';

interface CategoryListingModuleProps {
  /** title */
  title: string;
  /** optional module short description */
  shortDescription: string;
  /** events  */
  categories: Array<any>;
  initialAmount?: number;
  incrementAmount?: number;
  twoColumns?: boolean;
}

const CategoryListingModule = ({
  title,
  shortDescription,
  categories,
  initialAmount = 12,
  incrementAmount = 12,
  twoColumns,
}: CategoryListingModuleProps) => {
  const [amountOfCategoriesToDisplay, setDisplayLimit] = React.useState(initialAmount);
  const intl = useIntl();

  return (
    <StyledModuleContainer data-appsearch-ignore="true">
      <StyledHeadingAndDescriptionContainer>
        <StyledHeadingAndDescriptionWrapper>
          <StyledModuleHeading>{title}</StyledModuleHeading>
          {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
        </StyledHeadingAndDescriptionWrapper>
      </StyledHeadingAndDescriptionContainer>

      <Vertical categories={categories.slice(0, amountOfCategoriesToDisplay)} twoColumns={twoColumns} />

      {categories.length > amountOfCategoriesToDisplay ? (
        <StyledLoadMoreWrapper>
          <Cta
            onClick={() => {
              setDisplayLimit(amountOfCategoriesToDisplay + incrementAmount);
            }}
          >
            <Button>{intl.formatMessage({ id: 'sections.category.loadmore' })}</Button>
          </Cta>
        </StyledLoadMoreWrapper>
      ) : null}
    </StyledModuleContainer>
  );
};

export default CategoryListingModule;
