/* eslint-disable react/require-default-props */
import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useIntl } from 'react-intl';
import { CardProps as RebassCardProps } from 'rebass';

import Arrow from '../../../icons/Arrow';
import {
  StyledCard,
  StyledImageBox,
  StyledImageBoxLink,
  StyledContentBox,
  StyledTitle,
  StyledDescription,
  StyledCtaWrapper,
  StyledCta,
} from './Styled';

interface CardProps extends RebassCardProps {
  /** category id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: IGatsbyImageData;
  /** card cta */
  link: string;
  /** display link text */
  showLink?: boolean;
}

const CustomBlockCard = React.forwardRef(
  ({ title, shortDescription, image, link, showLink = true, ...rest }: CardProps, ref) => {
    const intl = useIntl();

    return (
      <StyledCard ref={ref} {...(rest as any)}>
        <StyledImageBoxLink
          to={link || '#'}
          onClick={(e: any) => {
            if (!link) e.preventDefault();
          }}
        >
          <StyledImageBox
            sx={{
              backgroundImage: `url(${getSrc(image as IGatsbyImageData)})`,
            }}
          />
        </StyledImageBoxLink>

        <StyledContentBox>
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{shortDescription}</StyledDescription>

          {link && showLink && (
            <StyledCtaWrapper>
              <StyledCta to={link}>
                <Arrow />
                <span>{intl.formatMessage({ id: 'cards.category.link' })}</span>
              </StyledCta>
            </StyledCtaWrapper>
          )}
        </StyledContentBox>
      </StyledCard>
    );
  },
);
CustomBlockCard.displayName = 'CustomBlockCard';

export default CustomBlockCard;
