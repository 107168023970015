import * as React from 'react';
import { useCookies } from 'react-cookie';
//
import {
  StyledBannerContainer,
  StyledContentContainer,
  StyledRichTextContainer,
  StyledHeading,
  StyledCloseButton,
} from './Styled';

import { Close as CloseIcon } from '../../icons';

import RichText, { RichTextProps } from '../RichText';

export interface Props {
  title: string;
  description: RichTextProps;
}

const CookieBanner = ({ title, description }: Props) => {
  const [cookies, setCookie] = useCookies(['cookie_consent']);
  const [loaded, setLoaded] = React.useState(false);

  const setConsentCookie = () => {
    setCookie('cookie_consent', 'cookie_consent', { path: '/' });
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded || cookies.cookie_consent) {
    return null;
  }

  return (
    <StyledBannerContainer>
      <StyledCloseButton onClick={() => setConsentCookie()}>
        <CloseIcon />
      </StyledCloseButton>
      <StyledContentContainer>
        <StyledRichTextContainer>
          <StyledHeading>{title}</StyledHeading>
          <RichText {...description} />
        </StyledRichTextContainer>
      </StyledContentContainer>
    </StyledBannerContainer>
  );
};

export default CookieBanner;
