// / <reference types="resize-observer-browser" />
import React, { useState, useEffect, useRef } from 'react';

import {
  StyledWrapper,
  StyledWrapperInner,
  StyledTable,
  StyledHead,
  StyledBody,
  StyledRow,
  StyledCell,
  StyledHeadingCell,
  StyledCellContents,
} from './Styled';

interface TableInterface {
  headings: string[];
  rows: string[][];
  fixFirstColumn?: boolean;
}

const minWidth = 125;

const Table = ({ headings, rows, fixFirstColumn }: TableInterface) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [scrollPositions, setScroll] = useState({ isScrolledToLeft: true, isScrolledToRight: true });
  const [minCellWidth, setMinCellWidth] = useState(minWidth);
  const [scrollableWidth, setScrollableWidth] = useState(0);

  const scrollHandler = () => {
    if (scrollableRef.current) {
      const isScrolledToLeft = scrollableRef.current.scrollLeft === 0;
      const isScrolledToRight =
        scrollableRef.current.scrollLeft === scrollableRef.current.scrollWidth - scrollableRef.current.offsetWidth;
      setScroll({ isScrolledToLeft, isScrolledToRight });
    }
  };

  useEffect(() => {
    scrollHandler();
    if (scrollableRef.current && scrollableWidth) {
      setMinCellWidth(Math.max(minWidth, scrollableWidth / 6 - 1));
    }
  }, [scrollableRef, scrollableWidth]);

  useEffect(() => {
    const ro =
      typeof ResizeObserver !== 'undefined'
        ? new ResizeObserver(entries => {
            setScrollableWidth(entries[0] && entries[0].contentRect && entries[0].contentRect.width);
          })
        : null;

    if (ro && scrollableRef.current) {
      ro.observe(scrollableRef.current);
    }

    return () => {
      if (ro && scrollableRef.current) {
        ro.unobserve(scrollableRef.current);
      }
    };
  }, [scrollableRef]);

  return (
    <StyledWrapper>
      <StyledWrapperInner
        ref={scrollableRef}
        fixFirstColumn={fixFirstColumn}
        isScrolledToLeft={scrollPositions.isScrolledToLeft}
        isScrolledToRight={scrollPositions.isScrolledToRight}
        onScroll={scrollHandler}
      >
        <StyledTable cellPadding={0} cellSpacing={0} fixFirstColumn={fixFirstColumn}>
          <StyledHead>
            <StyledRow>
              {headings.map(heading => (
                <StyledHeadingCell key={heading}>
                  <StyledCellContents style={{ minWidth: minCellWidth }}>{heading}</StyledCellContents>
                </StyledHeadingCell>
              ))}
            </StyledRow>
          </StyledHead>
          <StyledBody>
            {rows.map((row, rowIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledCell key={cellIndex}>
                    <StyledCellContents style={{ minWidth: minCellWidth }}>{cell}</StyledCellContents>
                  </StyledCell>
                ))}
              </StyledRow>
            ))}
          </StyledBody>
        </StyledTable>
      </StyledWrapperInner>
    </StyledWrapper>
  );
};

export default Table;
