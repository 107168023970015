/* eslint-disable react/require-default-props */
import React from 'react';
import { useIntl } from 'react-intl';
import { CardProps as RebassCardProps } from 'rebass';

import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';
import Cta from '../../Cta';
import Arrow from '../../../icons/Arrow';
import {
  StyledCard,
  StyledImageBox,
  StyledImageBoxLink,
  StyledContentBox,
  StyledTitle,
  StyledDescription,
  StyledCtaWrapper,
  StyledCta,
} from './Styled';

export interface CardProps extends RebassCardProps {
  /** category id */
  id: string;
  /** card title */
  title: string;
  /** optional card description */
  shortDescription?: string | null;
  /** card image */
  image?: { asset: IGatsbyImageData } | IGatsbyImageData | string | undefined;
  /** card cta */
  link: string;
  /** display link text */
  showLink?: boolean;
}

const CategoryCard = React.forwardRef(
  ({ title, shortDescription, image, link, showLink = true, ...rest }: CardProps, ref) => {
    const intl = useIntl();

    return (
      <StyledCard ref={ref} {...(rest as any)}>
        <StyledImageBoxLink to={link}>
          {image && (
            <StyledImageBox
              sx={{
                backgroundImage: `url(${
                  typeof image === 'string' ? image : getSrc('asset' in image ? image.asset : image)
                })`,
              }}
            />
          )}
        </StyledImageBoxLink>

        <StyledContentBox>
          <StyledTitle>
            <Cta to={link}>{title}</Cta>
          </StyledTitle>

          <StyledDescription>{shortDescription}</StyledDescription>

          {showLink && (
            <StyledCtaWrapper>
              <StyledCta to={link}>
                <Arrow />
                <span>{intl.formatMessage({ id: 'cards.category.link' })}</span>
              </StyledCta>
            </StyledCtaWrapper>
          )}
        </StyledContentBox>
      </StyledCard>
    );
  },
);
CategoryCard.displayName = 'CategoryCard';

export default CategoryCard;
