import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import ProductCardProps from '../../../Cards/ProductCard/types';
import ProductCard from '../../../Cards/ProductCard';
import { StyledHorizontalProductWrapper, StyledCardsWrapper } from './Styled';
import { chunkArray } from '../../../../utils/common';
import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import { mediumBreakpoint } from '../../../../utils/breakpoints';

interface HorizontalProps extends BoxProps {
  products: ProductCardProps[];
  variant: 'horizontalLeft' | 'horizontalRight';
}

const Horizontal = ({ products, variant }: HorizontalProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const chunkedProducts = chunkArray(products, 2);

  return (
    <>
      {chunkedProducts.map(({ chunk, key }) => {
        const chunkHasPriceOrEnquiry: boolean = chunk.reduce((acc, { price, enquiryCta }) => {
          if (acc === true) {
            return true;
          }
          if (price || enquiryCta) {
            return true;
          }

          return false;
        }, false);
        return (
          <StyledCardsWrapper key={key} className={`cards-${chunk.length}`}>
            {chunk.map(({ id, ...productRest }) => (
              <StyledHorizontalProductWrapper key={id} className={`cards-${chunk.length}`}>
                <ProductCard
                  id={id}
                  variant={variant}
                  showCommerceSection={currentBreakpoint === mediumBreakpoint ? chunkHasPriceOrEnquiry : false}
                  {...productRest}
                />
              </StyledHorizontalProductWrapper>
            ))}
          </StyledCardsWrapper>
        );
      })}
    </>
  );
};

export default Horizontal;
