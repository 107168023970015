import styled from 'styled-components';
import { Flex } from 'rebass/styled-components';
//
import Cta from '../../../Cta';
import Image from '../../../Image';
import Media from '../../../../utils/Media';

export const StyledCategoriesAndImageContainer = styled(Flex)`
  flex-wrap: wrap;
`;

export const StyledCategoriesContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-around;

  ${Media({
    marginRight: ['0px', null, null, '2rem'],
    flex: ['100%', '100%', '100%', '30%'],
    marginBottom: ['0.5rem', null, null, '0px'],
  })}

  &.cards-2 {
    justify-content: flex-start;
  }

  &.cards-1 {
    justify-content: flex-start;
  }

  &:last-child {
    margin-bottom: 800px;
  }
`;

export const StyledCategoryCta = styled(Cta)`
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
  color: ${props => props.theme.colors.black};

  > span {
    display: inline-block;
    margin-left: 1.75rem;
    flex: 1;
  }

  > svg {
    display: inline-block;
    margin-left: 0.75rem;
    margin-right: 1.75rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.colors.primary};

    > * {
      fill: ${props => props.theme.colors.primary};
    }
  }

  ${Media({
    marginBottom: ['0.5rem', null, null, '2rem'],
  })}

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledImage = styled(Image)`
  ${Media({
    flex: ['100%', '30%', '30%', '30%'],
  })}
`;
