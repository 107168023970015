import styled from 'styled-components';
import Media from '../../../utils/Media';

interface IExpandedStyled {
  expanded?: boolean;
  checked?: boolean;
}

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 18px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<IExpandedStyled>`
  display: flex;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? `${props.theme.dealer.textColor}` : 'white')};
  border: 1px solid white;
  transition: all 150ms;
`;

export const StyledCheckboxContainer = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  transition: all 150ms;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  padding-left: 1.5rem;
  border-radius: ${props => props.theme.formInputs.borderRadius};
  height: 48px;
  align-items: center;

  > span {
    align-self: center;
  }
`;

export const StyledDropDown = styled.div<IExpandedStyled>`
  position: absolute;
  top: 197px;
  z-index: 1;
  padding: 8px;
  border-bottom-left-radius: ${props => (props.expanded ? props.theme.formInputs.borderRadius : 0)};
  border-bottom-right-radius: ${props => (props.expanded ? props.theme.formInputs.borderRadius : 0)};
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 393px) {
    top: 268px;
  }

  @media (max-width: 1084px) {
    min-width: 300px;
  }

  @media (min-width: 1024px) {
    min-width: 300px;
  }

  @media (max-width: 1084px) {
    min-width: 250px;
  }

  @media (max-width: 820px) {
    min-width: 200px;
  }

  @media (max-width: 690px) {
    min-width: 180px;
  }
  @media (max-width: 601px) {
    min-width: 100%;
  }

  ${Media({
    top: ['238px', null, '197px'],
  })}
`;

export const StyledDropDownContainer = styled.div<IExpandedStyled>`
  color: ${props => props.theme.colors.text};
  min-width: 300px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  height: 48px;
  border: ${props => (props.expanded ? '0px' : '1px')} solid black;
  border-radius: ${props => !props.expanded && props.theme.formInputs.borderRadius};
  border-top-left-radius: ${props => props.expanded && props.theme.formInputs.borderRadius};
  border-top-right-radius: ${props => props.expanded && props.theme.formInputs.borderRadius};

  ${Media({
    marginLeft: ['0px', null, '8px', '0px'],
    marginRight: ['0px', null, '8px', '0px'],
    marginBottom: ['8px', null, null, '0px'],
  })}

  @media (min-width: 1024px) {
    margin-right: 16px;
  }

  @media (max-width: 1084px) {
    min-width: 250px;
  }

  @media (max-width: 820px) {
    min-width: 200px;
  }

  @media (max-width: 690px) {
    min-width: 180px;
  }
`;

export const StyledDropDownItemSeparator = styled.div`
  padding: 8px 0px 4px 4px;

  > label {
    display: flex;
    height: 26px;
    align-items: center;

    > span {
      height: 18px;
      line-height: 20px;
      display: inline-block;
    }
  }
`;

export const StyledDropDownClear = styled.div`
  color: ${props => props.theme.dealer.textColor};
  padding: 12px 8px 8px 8px;
  cursor: pointer;
`;

export const StyledDropDownSeparator = styled.div<IExpandedStyled>`
  border-bottom: ${props => (props.expanded ? '1px' : '0px')} solid lightgray;
  height: 16%;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -8px;
`;
