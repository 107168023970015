import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  StyledWrapperOuter,
  StyledWrapperInner,
  StyledListWrapper,
  StyledHeadingWrapper,
  StyledHeading,
  StyledChevronWrapper,
  StyledList,
  StyledListItem,
  StyledButton,
  StyledClearButton,
} from '../Categories/Styled';
import { StyledCheckbox } from './Styled';
import ArrowDown from '../../../../icons/ArrowDown';
import ArrowUp from '../../../../icons/ArrowUp';
import useOverlay from '../../../../utils/hooks/overlay';

interface Filters {
  filters: string[];
  state: any;
  dispatch: (data: any) => void;
}

const Filters = ({ filters, state, dispatch }: Filters) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOverlay({ setIsOpen });

  const toggleFilter = (filter: string) => {
    const isSelected = state.filters.includes(filter);
    const newFilters = isSelected
      ? state.filters.filter((item: string) => item !== filter)
      : [...state.filters, filter];
    dispatch({ filters: newFilters });
  };

  return filters.length ? (
    <StyledWrapperOuter ref={ref}>
      <StyledWrapperInner isOpen={isOpen}>
        <StyledHeadingWrapper onClick={() => setIsOpen(!isOpen)}>
          <StyledHeading>{intl.formatMessage({ id: 'brandListing.seeProductsFor' })}</StyledHeading>
          <StyledChevronWrapper>{isOpen ? <ArrowUp /> : <ArrowDown />}</StyledChevronWrapper>
        </StyledHeadingWrapper>
        <StyledListWrapper isOpen={isOpen}>
          <StyledList>
            {filters.map(filter => (
              <StyledListItem key={filter}>
                <StyledButton onClick={() => toggleFilter(filter)}>
                  <StyledCheckbox isSelected={state.filters.includes(filter)} />
                  {filter}
                </StyledButton>
              </StyledListItem>
            ))}
          </StyledList>
          <StyledClearButton onClick={() => dispatch({ filters: [] })}>
            {intl.formatMessage({ id: 'brandListing.clear' })}
          </StyledClearButton>
        </StyledListWrapper>
      </StyledWrapperInner>
    </StyledWrapperOuter>
  ) : null;
};

export default Filters;
