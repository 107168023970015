import React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import Cta from '../../Cta';
import Button from '../../Button';
import {
  StyledTextBoxWrapper,
  StyledTextBox,
  StyledImage,
  StyledTextBoxTitle,
  StyledTextBoxDescription,
} from './Styled';
import { StyledModuleContainer } from '../../Layout/Styled';

type CtaProps = {
  to: string;
  label: string;
};

export interface CtaSectionModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional module short description */
  shortDescription?: string;
  /** card cta */
  cta?: any;
  /** the background image */
  image: any;
  /** the box placement orientation */
  orientation?: 'left' | 'right';
  description?: string;
}

const CtaSectionModule = React.forwardRef(
  ({ title, shortDescription, description, cta, orientation = 'left', image, ...rest }: CtaSectionModuleProps, ref) => {
    return (
      <StyledModuleContainer ref={ref} {...(rest as any)}>
        <StyledTextBoxWrapper>
          <StyledImage>
            {image?.asset?.gatsbyImageData && (
              // <Img loading="eager" fluid={image.asset.fluid} alt={image.alt} style={{ width: '100%', height: '100%' }} />
              <GatsbyImage
                image={image?.asset?.gatsbyImageData}
                alt={image.alt ? image.alt : image.title}
                loading="eager"
                style={{ width: '100%', height: '100%' }}
              />
            )}
          </StyledImage>
          <StyledTextBox className={orientation}>
            <StyledTextBoxTitle>{title}</StyledTextBoxTitle>

            {shortDescription && <StyledTextBoxDescription>{shortDescription}</StyledTextBoxDescription>}

            {cta && (
              <Box>
                <Cta to={cta.to}>
                  <Button variant="primary-with-background">{cta.label}</Button>
                </Cta>
              </Box>
            )}
          </StyledTextBox>
        </StyledTextBoxWrapper>
      </StyledModuleContainer>
    );
  },
);
CtaSectionModule.displayName = 'CtaSectionModule';

export default CtaSectionModule;
