import styled from 'styled-components';
import Media from '../../../../utils/Media';

export const StyledWrapperOuter = styled.div`
  position: relative;

  ${Media({
    minHeight: ['48px', null, 'auto'],
    marginBottom: ['8px', null, 0],
  })}
`;

interface StyledWrapperInner {
  isOpen: boolean;
}

export const StyledWrapperInner = styled.div<StyledWrapperInner>`
  background: #fff;
  border-color: ${props => props.theme.colors.dark};
  border-style: solid;
  border-radius: 4px;
  top: 0;
  left: 0;
  z-index: ${props => (props.isOpen ? 2 : 1)};

  ${props =>
    Media({
      borderWidth: [props.isOpen ? 0 : '1px', null, 0],
      padding: ['0 24px', null, 0],
      boxShadow: [props.isOpen ? '0 2px 20px 0 rgba(0, 0, 0, 0.1)' : 'none', null, 'none'],
      position: ['relative', null, 'static'],
      width: ['100%', null, 'auto'],
      cursor: ['pointer', null, 'default'],
    })}
`;

export const StyledHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Media({
    margin: [0, null, '0 0 12px'],
    padding: ['9px 0', null, 0],
    borderBottom: ['1px solid rgba(222, 222, 222, 0.5)', null, 'none'],
  })}
`;

export const StyledHeading = styled.h2`
  line-height: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.dark};
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;

  ${Media({
    fontSize: ['16px', null, '14px'],
  })}
`;

export const StyledHeadingText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 3px;
`;

export const StyledChevronWrapper = styled.div`
  margin-left: 19px;

  ${Media({
    display: ['block', null, 'none'],
  })}
`;

interface StyledListWrapper {
  isOpen: boolean;
}

export const StyledListWrapper = styled.div<StyledListWrapper>`
  ${props =>
    Media({
      display: [props.isOpen ? 'block' : 'none', null, 'block'],
    })}
`;

export const StyledList = styled.ul`
  padding: 0;
  list-style: none;
  overflow: auto;

  ${Media({
    margin: ['12px 0 0', null, '0 0 28px'],
    borderBottom: ['1px solid rgba(222, 222, 222, 0.5)', null, 'none'],
    maxHeight: ['300px', null, 'none'],
  })}
`;

export const StyledListItem = styled.li`
  margin: 0 0 12px;
`;

interface StyledButton {
  isSelected?: boolean;
  hasHover?: boolean;
}

export const StyledButton = styled.button<StyledButton>`
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  text-align: left;
  line-height: 24px;
  color: ${props => (props.isSelected ? props.theme.colors.primary : props.theme.colors.dark)};
  font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};
  cursor: pointer;
  outline: none;
  text-underline-position: under;
  display: flex;
  align-items: center;
  width: 100%;

  &:focus-visible {
    outline: 1px solid blue;
  }

  &:hover {
    ${props =>
      Media({
        color: [props.theme.colors.dark, null, props.hasHover ? props.theme.colors.primary : null],
        textDecoration: ['none', null, props.hasHover ? 'underline' : null],
      })}
  }

  ${Media({
    fontSize: ['16px', null, '14px'],
  })}
`;

export const StyledClearButton = styled.button`
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${props => props.theme.colors.secondary};
  padding: 16px;
  border: none;
  background: none;
  margin: 0;
  width: 100%;
  text-align: center;
  outline: none;

  &:focus-visible {
    outline: 1px solid blue;
  }

  ${Media({
    display: ['block', null, 'none'],
  })}
`;
