import React from 'react';
import {
  StyledWrapper,
  StyledImageWrapper,
  StyledImage,
  StyledContentWrapper,
  StyledTitle,
  StyledDescription,
} from './Styled';

interface BrandCard {
  id: string;
  title: string;
  shortDescription: string;
  logo: string;
  dispatch: (data: any) => void;
}

const BrandCard = ({ id, title, shortDescription, logo, dispatch }: BrandCard) => (
  <StyledWrapper onClick={() => dispatch({ selectedBrandId: id })}>
    <StyledImageWrapper>
      <StyledImage src={logo} />
    </StyledImageWrapper>
    <StyledContentWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{shortDescription}</StyledDescription>
    </StyledContentWrapper>
  </StyledWrapper>
);

export default BrandCard;
