import styled from 'styled-components';
import Button from '../../../Button';
import Media from '../../../../utils/Media';

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(10px);
  z-index: 100;
`;

export const StyledModal = styled.div`
  box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  max-width: 1161px;
  display: flex;
  flex-direction: column;
  z-index: 101;
  overflow: auto;
  position: fixed;
  overflow-x: hidden;

  ${Media({
    width: ['100%', null, '95%', '80%'],
    top: [0, null, '50%'],
    left: [0, null, '50%'],
    bottom: [0, null, 'auto'],
    right: [0, null, 'auto'],
    maxHeight: ['none', null, 'calc(100vh - 40px)'],
    transform: ['translate(0)', null, 'translate(-50%, -50%)'],
  })}
`;

export const StyledModalTop = styled.div`
  border-bottom: 1px solid rgba(222, 222, 222, 0.5);
  display: flex;
  margin: -16px 0;

  > * {
    margin: 16px 0;
  }

  ${Media({
    flexDirection: ['column', null, null, 'row'],
    padding: ['20px', null, '32px'],
  })}
`;

export const StyledMediaWrapper = styled.div`
  margin-right: 32px;
  position: relative;

  ${Media({
    order: [2, null, null, 1],
    width: ['100vw', null, '306px'],
    left: ['-20px', null, 0],
  })}
`;

export const StyledContentWrapper = styled.div`
  flex: 1;

  ${Media({
    order: [3, null, null, 2],
  })}
`;

export const StyledTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 12px;
`;

export const StyledRichText = styled.div`
  * {
    color: ${props => props.theme.colors.dark};
    font-size: 16px;
  }

  p,
  ul {
    margin: 0 0 12px;
  }
`;

export const StyledCloseButtonWrapper = styled.div`
  display: grid;
  justify-content: flex-end;

  ${Media({
    order: [1, null, null, 3],
  })}
`;

export const StyledCloseButton = styled.button`
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  &:focus-visible {
    outline: 1px solid blue;
  }
`;

export const StyledModalBottom = styled.div`
  display: flex;
  margin: -16px;
  flex: 1;

  > * {
    margin: 16px;
  }

  ${Media({
    padding: ['20px', null, '32px'],
    flexDirection: ['column', null, 'row'],
  })}
`;

export const StyledAddressWrapper = styled.div`
  flex: 3;
`;

export const StyledLogo = styled.img`
  max-width: 150px;
  margin-bottom: 20px;
`;

export const StyledAddress = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark};
  white-space: pre-wrap;
  margin-bottom: 20px;
`;

export const StyledCTAButton = styled(Button)`
  min-width: 150px;
`;

export const StyledProductsAndServicesWrapper = styled.div`
  flex: 3;
`;

export const StyledSubTitle = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 12px;
`;

export const StyledProductsAndServices = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: ${props => props.theme.colors.dark};
  white-space: pre-wrap;
  margin: 0;
`;

export const StyledSimilarBrandsWrapper = styled.div`
  flex: 4;
`;

export const StyledSimilarBrandsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  > * {
    margin: 4px;
  }
`;

export const StyledBrandButton = styled(Button)`
  min-width: 0;
  height: 32px;
  padding: 0 1rem;
`;
