/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

import Cta from '../../Cta';
import Button from '../../Button';
import { CardProps } from '../types';
import {
  StyledContainer,
  StyledModuleHeading,
  StyledModuleDescription,
  StyledCategoryCard,
  StyledCardContainer,
  StyledBlueBox,
} from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

interface CategoriesModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  advices: CardProps[];
  cta?: CtaProps | null;
}

const AdviceCardModule = ({ title, shortDescription, advices, cta }: CategoriesModuleProps) => (
  <StyledContainer data-appsearch-ignore="true">
    <StyledModuleHeading>{title}</StyledModuleHeading>
    {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}

    <StyledCardContainer className={`cards-${advices.length}`}>
      {advices.map((card: CardProps) => (
        <StyledCategoryCard
          id={card.id}
          key={`category-${card.id}`}
          title={card.title}
          shortDescription={card.shortDescription || null}
          link={card.link}
          image={card.image}
          className={`cards-${advices.length}`}
        />
      ))}
      <StyledBlueBox />
    </StyledCardContainer>

    {cta && (
      <Box>
        <Cta to={cta.to}>
          <Button>{cta.label}</Button>
        </Cta>
      </Box>
    )}
  </StyledContainer>
);

export default AdviceCardModule;
