import * as React from 'react';
import queryString from 'query-string';
import { BoxProps } from 'rebass';

import { useIntl } from 'react-intl';
import NoResults from './NoResults';
import Results from './Results';
import { StyledContainer, StyledLoadingContainer } from './Styled';

interface SearchResultsProps extends BoxProps {
  /** Optional default search query to pass into the component */
  defaultQuery?: string;
  /** Title for the contacts form */
  contactsTitle: string;
  /** Short description for the contacts form */
  contactsShortDescription?: string;
  /** Function that will handle the search */
  handleSearch: (query: string, locale: string) => any;
  /** Function that will handle the contacts form submission */
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => void;
  /** property to handle if the no results contact form will display a email subscribe form */
  showContactFormSubscribeOption?: boolean;

  /** Data to drive cta redirects in category modules */
  productPageSlug: string;
  eventPageSlug: string;
  newsPageSlug: string;
}

const SearchResultsModule = React.forwardRef(
  (
    {
      defaultQuery = '',
      contactsTitle,
      contactsShortDescription,
      handleSearch,
      handleFormSubmit,
      showContactFormSubscribeOption = false,
      productPageSlug,
      eventPageSlug,
      newsPageSlug,
      ...rest
    }: SearchResultsProps,
    ref,
  ) => {
    const isMounted = React.useRef(false);
    const intl = useIntl();
    let query = defaultQuery;

    if (typeof window !== 'undefined') {
      const parsedQuery = queryString.parse(window.location.search);
      if (typeof parsedQuery.q === 'string' && parsedQuery.q.length) {
        query = parsedQuery.q;
      }
    }

    const [results, setResults] = React.useState([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
      isMounted.current = true;

      if (query && !loaded) {
        (async function performSearch() {
          const response = await handleSearch(query, intl.locale);
          if (isMounted.current) {
            setResults(response);
            setLoaded(true);
          }
        })();
      }

      return () => {
        isMounted.current = false;
      };
    }, []);

    if (!loaded) {
      return <StyledLoadingContainer />;
    }

    return (
      <StyledContainer ref={ref} {...(rest as any)}>
        {results && results.length ? (
          <Results
            key={`results-${query}`}
            query={query}
            results={results}
            productPageSlug={productPageSlug}
            eventPageSlug={eventPageSlug}
            newsPageSlug={newsPageSlug}
          />
        ) : (
          <NoResults
            key={`noresults-${query}`}
            title={contactsTitle}
            shortDescription={contactsShortDescription}
            query={query}
            handleFormSubmit={handleFormSubmit}
            showContactFormSubscribeOption={showContactFormSubscribeOption}
          />
        )}
      </StyledContainer>
    );
  },
);
SearchResultsModule.displayName = 'SearchResultsModule';

export default SearchResultsModule;
