import React from 'react';
import Img from 'gatsby-image';
import { useIntl } from 'react-intl';
import RichText from '../../../RichText';
import Video from '../../../Video';
import Close from '../../../../icons/Close';
import Cta from '../../../Cta';
import {
  StyledOverlay,
  StyledModal,
  StyledModalTop,
  StyledModalBottom,
  StyledMediaWrapper,
  StyledContentWrapper,
  StyledTitle,
  StyledRichText,
  StyledCloseButtonWrapper,
  StyledCloseButton,
  StyledAddressWrapper,
  StyledLogo,
  StyledAddress,
  StyledCTAButton,
  StyledProductsAndServicesWrapper,
  StyledSubTitle,
  StyledProductsAndServices,
  StyledSimilarBrandsWrapper,
  StyledSimilarBrandsList,
  StyledBrandButton,
} from './Styled';

interface Modal {
  state: any;
  dispatch: (data: any) => void;
}

function Modal({ state, dispatch }: Modal) {
  const intl = useIntl();
  const brand = state.allBrands.find(({ id }: any) => id === state.selectedBrandId);

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <StyledOverlay onClick={() => dispatch({ selectedBrandId: null })} />
      <StyledModal>
        <StyledModalTop>
          <StyledMediaWrapper>
            {brand.media && brand.media.asset && (
              <Img loading="eager" fluid={brand.media.asset.fluid} alt={brand.media.alt} />
            )}
            {brand.media && brand.media.videoUrl && <Video videoUrl={brand.media.videoUrl} />}
          </StyledMediaWrapper>
          <StyledContentWrapper>
            <StyledTitle>{brand.title}</StyledTitle>
            <StyledRichText>
              <RichText data={brand.richText.json} />
            </StyledRichText>
          </StyledContentWrapper>
          <StyledCloseButtonWrapper>
            <StyledCloseButton onClick={() => dispatch({ selectedBrandId: null })}>
              <Close />
            </StyledCloseButton>
          </StyledCloseButtonWrapper>
        </StyledModalTop>
        <StyledModalBottom>
          <StyledAddressWrapper>
            <StyledLogo src={brand.logo} />
            <StyledAddress>{brand.address}</StyledAddress>
            {brand.cta && (
              <Cta to={brand.cta.to}>
                <StyledCTAButton>{brand.cta.label}</StyledCTAButton>
              </Cta>
            )}
          </StyledAddressWrapper>
          <StyledProductsAndServicesWrapper>
            <StyledSubTitle>{intl.formatMessage({ id: 'brandListing.productsAndServices' })}</StyledSubTitle>
            <StyledProductsAndServices>{brand.productsAndServices}</StyledProductsAndServices>
          </StyledProductsAndServicesWrapper>
          <StyledSimilarBrandsWrapper>
            <StyledSubTitle>{intl.formatMessage({ id: 'brandListing.similarBrands' })}</StyledSubTitle>
            <StyledSimilarBrandsList>
              {brand.categories.map((category: string) => (
                <StyledBrandButton
                  key={category}
                  variant="secondary"
                  onClick={() => {
                    dispatch({
                      selectedBrandId: null,
                      category,
                    });
                    scrollToTop();
                  }}
                >
                  {category}
                </StyledBrandButton>
              ))}
            </StyledSimilarBrandsList>
          </StyledSimilarBrandsWrapper>
        </StyledModalBottom>
      </StyledModal>
    </>
  );
}

export default Modal;
