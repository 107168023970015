/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Text } from 'rebass';
import { useIntl } from 'react-intl';
import { StyledDealerContent, StyledDealerTitle, StyledDealerTelephone } from './Styled';
import LocationPin from '../../../../icons/LocationPin';

export type DealerProps = {
  title: string;
  address?: string;
  mapUrl?: string;
  telephone?: string;
  // latitude: number;
  // longitude: number;
};

export type DealerCategoriesProps = {
  category: [string];
};

function Dealer({ title, address, mapUrl, telephone }: DealerProps) {
  const intl = useIntl();

  return (
    <StyledDealerContent>
      <StyledDealerTitle>{title}</StyledDealerTitle>
      {address && <Text sx={{ whiteSpace: 'pre-wrap', flex: '1' }} dangerouslySetInnerHTML={{ __html: address }} />}
      {mapUrl && (
        <a rel="noopener noreferrer" target="_blank" className="dealer-location-link" href={mapUrl}>
          <LocationPin />
          <span>{intl.formatMessage({ id: 'sections.dealer.maplink' })}</span>
        </a>
      )}
      <StyledDealerTelephone>
        <a className="dealer-telephone" href={`tel:${telephone}`}>
          {telephone}
        </a>
      </StyledDealerTelephone>
    </StyledDealerContent>
  );
}

export default Dealer;
