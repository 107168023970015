import styled from 'styled-components';
//

export const StyledFooterWrapper = styled.div`
  padding: 60px 30px;
  margin-top: 80px;
  background: #f3f3f6;
  position: relative;
`;

export const StyledFooterInnerContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;

  @media all and (min-width: 576px) {
    max-width: 540px;
  }

  @media all and (min-width: 768px) {
    max-width: 720px;
  }

  @media all and (min-width: 992px) {
    max-width: 960px;
  }

  @media all and (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const StyledColumn = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const StyledFooterLangSwitcher = styled.ul`
  width: 100%;
  padding: 0 0 20px;
  margin: 0 0 40px;
  border-bottom: 1px solid #ddd;
  list-style: none;
  text-align: center;
  display: inline-block;

  @media all and (min-width: 767px) {
    display: none;
  }
`;

export const StyledFooterLangSwitcherListItem = styled.li`
  list-style: none;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
`;

export const StyledFooterLangSwitcherLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.875rem;
  color: #999;

  &.active {
    color: #475629;
    font-weight: 400;
  }
`;

export const StyledLogo = styled.a`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
  width: 100%;
  > img {
    margin-right: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  padding: 0px;
`;

export const StyledFooterBlockContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 90px 0 60px;
  font-family: 'Encode Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.0313rem;
  color: #333;

  @media all and (max-width: 991px) {
    margin: 40px 0;
    flex-flow: column nowrap;
    justify-content: center;
  }
`;

export const StyledFooterBlock = styled.div`
  width: 30%;

  @media all and (max-width: 991px) {
    margin: 0 0 20px;
    text-align: center;
    line-height: 1.7;
    width: 100%;
  }
`;

export const StyledFooterBlockSocial = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;

  @media all and (max-width: 991px) {
    width: 100%;
    justify-content: center;
    margin: 20px 0;
  }
`;

export const StyledFooterSocialIcon = styled.div`
  font-size: 1.56rem !important;
  margin: 0 15px;
  color: #546223;
  &:hover {
    color: #84bd00;
  }

  &.fa-youtube-play {
    padding-top: 2px;
  }
`;

export const StyledLegalText = styled.small`
  color: #999;
  font-family: 'Encode Sans', sans-serif;
  font-size: 12.8px;
  font-weight: 400;
  height: auto;
  letter-spacing: 0.5008px;
  line-height: 19.2px;
  text-align: left;
  text-size-adjust: 100%;
  width: auto;
  -webkit-font-smoothing: antialiased;

  > a {
    color: #999;
    &:hover {
      color: #84bd00;
    }
  }
  @media all and (max-width: 991px) {
    text-align: center;
    width: 100%;
    display: inline-block;
  }
`;
