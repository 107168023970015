import React from 'react';

type Props = {
  type: string;
  code?: string;
};
const EmbeddedContent = ({ type, code }: Props) => {
  if (type === 'OneTrust CookieList') {
    return <div id="ot-sdk-cookie-policy" />;
  }
  if (type === 'OneTrust CookiePreferences') {
    return (
      <button id="ot-sdk-btn" className="ot-sdk-show-settings" type="button">
        {code || 'Cookie Settings'}
      </button>
    );
  }

  return null;
};

export default EmbeddedContent;
