import React from 'react';
import {
  ContentfulAdvice,
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulNews,
  ContentfulProduct,
} from '../../../generated/gatsby.types';

const TranslatedSlugContext = React.createContext<
  Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>
>([]);
export const TranslatedSlugProvider = TranslatedSlugContext.Provider;
export default TranslatedSlugContext;
