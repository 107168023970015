import React from 'react';
import { BoxProps as RebassBoxProps } from 'rebass';
//
import {
  StyledBox,
  StyledQuote,
  StyledSeparator,
  StyledAuthor,
  StyledInfo,
  StyledAuthorWrapper,
  StyledAuthorContentWrapper,
  StyledImage,
} from './Styled';

interface BlockquoteProps extends RebassBoxProps {
  /** quote text */
  quote: string;
  /** optional author */
  author?: string | null;
  /** author info */
  authorInfo?: string;
  imageUrl?: string;
}

const Blockquote = React.forwardRef(({ quote, author, authorInfo, imageUrl, ...rest }: BlockquoteProps, ref) => (
  <StyledBox ref={ref} {...(rest as any)} as="blockquote">
    <StyledQuote>{quote}</StyledQuote>
    {author && (
      <StyledAuthorWrapper>
        {imageUrl && (
          <StyledImage
            // TODO use imageUrl from gatsy-plugin-image
            sx={{
              backgroundImage: `url(${imageUrl}?f=face)`,
            }}
          />
        )}
        <StyledAuthorContentWrapper>
          <StyledSeparator />
          <StyledAuthor>{author}</StyledAuthor>
          {authorInfo && <StyledInfo>{authorInfo}</StyledInfo>}
        </StyledAuthorContentWrapper>
      </StyledAuthorWrapper>
    )}
  </StyledBox>
));
Blockquote.displayName = 'Blockquote';

export default Blockquote;
