import React from 'react';
import { BoxProps } from 'rebass/styled-components';
import { useIntl } from 'react-intl';

import Cta from '../../Cta';
import Button from '../../Button';
import CardProps from '../../Cards/EventCard/types';
import EventCard from '../../Cards/EventCard';
import {
  StyledModuleHeading,
  StyledModuleDescription,
  StyledEventCardWrapper,
  StyledCardContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledHorizontalEventsCardWrapper,
  StyledHorizontalEventCardWrapper,
} from './Styled';
import { useCurrentBreakpoint } from '../../../utils/hooks/breakpoints';
import { mediumBreakpoint } from '../../../utils/breakpoints';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../Layout/Styled';

export interface EventListingModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  events: CardProps[];
  featuredEvent?: CardProps;
  initialAmount?: number;
  incrementAmount?: number;
}

const EventListingModule = React.forwardRef(
  (
    {
      title,
      shortDescription,
      events,
      featuredEvent,
      initialAmount = 3,
      incrementAmount = 8,
      ...rest
    }: EventListingModuleProps,
    ref,
  ) => {
    const intl = useIntl();
    const [amountOfEventsToDisplay, setDisplayLimit] = React.useState(initialAmount);
    const currentBreakpoint = useCurrentBreakpoint();
    const isMediumBreakpoint = currentBreakpoint === mediumBreakpoint;

    return (
      <StyledModuleContainer ref={ref} {...(rest as any)}>
        <StyledHeadingAndDescriptionWrapper>
          <StyledModuleHeading>{title}</StyledModuleHeading>
          {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
        </StyledHeadingAndDescriptionWrapper>
        <StyledCardContainer className={`cards-${events.length}`}>
          {events
            .map(({ id, ...eventRest }: CardProps) => (
              <StyledEventCardWrapper key={id} className={`cards-${events.length}`}>
                <EventCard id={id} {...eventRest} />
              </StyledEventCardWrapper>
            ))
            .slice(0, 3)}
          {isMediumBreakpoint && featuredEvent && (
            <StyledEventCardWrapper className={`cards-${events.length}`}>
              <EventCard {...featuredEvent} />
            </StyledEventCardWrapper>
          )}
        </StyledCardContainer>
        <StyledHorizontalEventsCardWrapper>
          {!isMediumBreakpoint && featuredEvent && (
            <StyledHorizontalEventCardWrapper>
              <EventCard variant="horizontal" {...featuredEvent} />
            </StyledHorizontalEventCardWrapper>
          )}
          {events.length > 3
            ? events
                .map(({ id, ...eventRest }: CardProps) => (
                  <StyledHorizontalEventCardWrapper key={id}>
                    <EventCard id={id} variant="horizontal" {...eventRest} />
                  </StyledHorizontalEventCardWrapper>
                ))
                .slice(3, amountOfEventsToDisplay)
            : null}
        </StyledHorizontalEventsCardWrapper>
        <StyledModuleCtaContainer>
          {events.length > amountOfEventsToDisplay ? (
            <Cta
              onClick={() => {
                setDisplayLimit(amountOfEventsToDisplay + incrementAmount);
              }}
            >
              <Button>{intl.formatMessage({ id: 'sections.events.loadmore' })}</Button>
            </Cta>
          ) : null}
        </StyledModuleCtaContainer>
      </StyledModuleContainer>
    );
  },
);
EventListingModule.displayName = 'EventListingModule';

export default EventListingModule;
