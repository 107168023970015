import React, { MutableRefObject } from 'react';

import { StyledModuleContainer } from '../../Layout/Styled';
import Table from '../../Table';

const TableModule = React.forwardRef(({ ...rest }: any, ref) => (
  <StyledModuleContainer ref={ref as MutableRefObject<HTMLDivElement>}>
    <Table {...(rest as any)} />
  </StyledModuleContainer>
));
TableModule.displayName = 'TableModule';

export default TableModule;
