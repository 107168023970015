import styled from 'styled-components';
import Media from '../../../utils/Media';

interface StyledImageWrapper {
  alignImageRight?: boolean;
}

export const StyledContainer = styled.div<StyledImageWrapper>`
  background-color: ${props => props.theme.imageAndText.backgroundColor};
  color: ${props => props.theme.imageAndText.color};
  display: flex;

  ${props =>
    Media({
      flexDirection: ['column', null, 'row'],
      width: ['100%', '100%', '100%', 'calc(100% / 12 * 11)'],
      margin: ['0', '0', '0', props.alignImageRight ? '0 auto 0 0' : '0 0 0 auto'],
    })}
`;

export const StyledImageWrapper = styled.div<StyledImageWrapper>`
  ${props =>
    Media({
      width: ['100%', null, '53%'],
      order: [1, null, props.alignImageRight ? 2 : 1],
    })}

  > .gatsby-image-wrapper {
    div {
      ${Media({
        minHeight: [null, null, null, '440px'],
        paddingBottom: [null, null, null, '0px !important'],
      })}
    }
  }
`;

interface StyledTextWrapper {
  alignImageRight?: boolean;
}

export const StyledTextWrapper = styled.div<StyledTextWrapper>`
  ${props =>
    Media({
      width: ['100%', null, '47%'],
      padding: ['32px 20px', null, null, '64px'],
      order: [1, null, props.alignImageRight ? 1 : 2],
    })}

  *:first-child {
    margin-top: 0;
  }

  ul li:before {
    background-color: ${props => props.theme.imageAndText.color};
  }

  a,
  a:hover {
    color: ${props => props.theme.imageAndText.color};
  }

  a:hover {
    text-decoration: none;
  }
`;
