import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import Media from '../../../utils/Media';
//

const StyledLogo = styled(Box)`
  ${props =>
    Media({
      transform: ['scale(0.8)', null, null, 'scale(1)'],
      marginRight: [0, 0, 0, props.variant === 'secondary' ? '24px' : 0, 0],
    })}

  a, svg {
    display: block;
    max-width: 100%;
  }
`;

export default StyledLogo;
