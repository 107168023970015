import styled from 'styled-components';
//
import Media from '../../../utils/Media';

interface StyledWrapper {
  variant?: string;
}

export const StyledWrapper = styled.div<StyledWrapper>`
  height: ${props => (props.variant === 'secondary' ? '24px' : '40px')};
  max-width: ${props => (props.variant === 'secondary' ? '105px' : '120px')};
  position: relative;
  border-radius: ${props => props.theme.search.borderRadius};
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  margin-right: 8px;
  border: ${props => (props.variant === 'secondary' ? 'none' : `1px solid ${props.theme.colors.darkGray}`)};
  color: ${props => props.theme.colors.text};
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;

  &:focus,
  &:hover {
    border-color: ${props => props.theme.search.activeColor};

    svg {
      fill: ${props => `${props.theme.search.activeColor}`};

      > * {
        fill: ${props => `${props.theme.search.activeColor}`};
      }
    }
  }

  span:last-child {
    cursor: pointer;

    svg {
      transform: scale(0.85);
    }
  }

  &.focused-input {
    ${Media({
      maxWidth: ['150px', null, null, null, '206px'],
    })}

    input {
      padding-right: 30px;
      &::-webkit-input-placeholder {
        color: #999;
      }
      &::-moz-placeholder {
        color: #999;
      }
      &:-ms-input-placeholder {
        color: #999;
      }
      &:-moz-placeholder {
        color: #999;
      }
      &::placeholder {
        color: #999;
      }
    }
  }
`;

export const StyledInput = styled.input<any>`
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0 0 0 10px;
  flex: 1;
  height: 100%;
  font-size: ${props => props.theme.search.fontSize || '12px'};
  text-transform: ${props => props.theme.search.textTransform || 'uppercase'};
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  outline: none;

  &::-webkit-input-placeholder {
    color: ${props => `${props.theme.colors.text}`};
  }
  &::-moz-placeholder {
    color: ${props => `${props.theme.colors.text}`};
  }
  &:-ms-input-placeholder {
    color: ${props => `${props.theme.colors.text}`};
  }
  &:-moz-placeholder {
    color: ${props => `${props.theme.colors.text}`};
  }
  &::placeholder {
    color: ${props => `${props.theme.colors.text}`};
  }

  &:focus,
  &:hover {
    &::-webkit-input-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &::-moz-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &:-ms-input-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &:-moz-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &::placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
  }
`;

interface StyledClosedButton {
  variant?: string;
}

export const StyledClosedButton = styled.span<StyledClosedButton>`
  position: absolute;
  right: 5px;
  top: ${props => (props.variant === 'secondary' ? '3px' : '6px')};
  height: ${props => (props.variant === 'secondary' ? '18px' : '24px')};
  width: ${props => (props.variant === 'secondary' ? '18px' : '24px')};

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface StyledSearchIconButton {
  variant?: string;
}

export const StyledSearchIconButton = styled.span<StyledSearchIconButton>`
  display: block;
  height: ${props => (props.variant === 'secondary' ? '18px' : '24px')};
  width: ${props => (props.variant === 'secondary' ? '17px' : '24px')};

  svg {
    width: 100%;
    height: 100%;
  }
`;
