/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass/styled-components';
import Cta from '../../Cta';
import Media from '../../../utils/Media';

export const StyledCard = styled(Box)`
  &.vertical {
    width: 100%;
    background-color: ${props => props.theme.colors.white};
  }
  &.horizontalRight,
  &.horizontalLeft {
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;

    ${Media({
      flexDirection: ['column', null, null, 'row'],
      maxWidth: ['441px', null, null, 'none'],
    })}
  }
  &.horizontalRight {
    ${Media({
      flexDirection: ['column-reverse', null, null, 'row'],
    })}
  }
`;

export const StyledImageBoxWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;

  ${Media({
    width: ['100%', null, null, `calc(310 / 1324 * 100%)`],
  })}
`;

export const StyledImageBox = styled(Box)`
  width: 100%;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};
`;

export const StyledImageBoxLinkWrapper = styled(Box)``;

export const StyledImageBoxLink = styled(Cta)`
  text-decoration: none;
  position: relative;
  display: block;
  ${Media({
    height: ['334px', '334px', '441px', null],
  })}

  display: flex;
  justify-content: center;
  align-items: center;

  > ${StyledImageBox} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const StyledImageBoxLinkHorizontal = styled(StyledImageBoxLink)`
  ${Media({
    height: ['441px', null, null, '300px'],
  })}
`;

export const StyledContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  flex: 1;

  ${Media({
    padding: ['1.5rem', null, null, '2rem'],
  })}

  ${StyledCard}.horizontalRight &,
  ${StyledCard}.horizontalLeft & {
    ${props =>
      Media({
        flexDirection: ['column', null, null, 'row'],
        width: ['100%', null, null, `calc(100% - (310 / 1324 * 100%))`],
        borderTop: [`1px solid ${props.theme.colors.lightGray}`, null, null, 'none'],
      })}
  }

  ${StyledCard}.horizontalRight & {
    ${props =>
      Media({
        borderRight: ['none', null, null, `1px solid ${props.theme.colors.lightGray}`],
      })}
  }

  ${StyledCard}.horizontalLeft & {
    ${props =>
      Media({
        borderLeft: ['none', null, null, `1px solid ${props.theme.colors.lightGray}`],
      })}
  }
`;

export const StyledProductInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;

  > div:first-child {
    flex-grow: 1;
  }
`;

export const StyledTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;

  a {
    color: ${props => props.theme.colors.darkGray};
    text-decoration: none;
  }
`;

export const StyledDescription = styled(Text)`
  font-size: 1rem;
  line-height: 1.75;
  color: ${props => props.theme.colors.darkGray};
  max-width: 700px;
  flex: 1;
  white-space: pre-wrap;

  ${StyledContentBox}.incl-commerce & {
    ${Media({
      paddingRight: [null, null, null, '2rem'],
    })}
  }

  h3 + & {
    ${Media({
      marginTop: ['0.5rem', null, null, '1.5625rem'],
    })}

    ${StyledCard}.horizontalRight &,
    ${StyledCard}.horizontalLeft & {
      ${Media({
        marginTop: ['0.5rem', null, null, '1rem'],
      })}
    }
  }
`;

export const StyledCommercePrice = styled.p`
  font-size: 1.25rem;
  margin-top: 0px !important;
  flex: 1;

  ${Media({
    marginBottom: ['1.25rem', null, null, '1.625rem'],
  })}
`;

export const StyledCommerceWrapperHorizontalNoCta = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  display: flex;
  flex-direction: column-reverse;

  &.horizontalRight,
  &.horizontalLeft {
    padding-top: 2rem;
    ${props =>
      Media({
        background: [null, null, null, props.theme.colors.white],
      })}
  }

  ${Media({
    height: ['auto', 'auto', '172px', 'auto'],
    marginTop: ['1.5rem', null, null, '2rem'],
    marginRight: ['-1.5rem', null, null, '-2rem'],
    marginBottom: ['-1.5rem', null, null, '-2rem'],
    marginLeft: ['-1.5rem', null, null, '-2rem'],
    paddingLeft: ['1.5rem', null, null, '2rem'],
    paddingRight: ['1.5rem', null, null, '2rem'],
    paddingBottom: ['1.5rem', null, null, '24px'],
  })}

  ${StyledProductInfo} + & {
    border: none;

    ${Media({
      display: [null, null, null, 'flex'],

      textAlign: ['left', null, null, 'right'],
      borderTop: ['1px solid #dedede', null, null, 'none'],
      borderLeft: ['none', null, null, '1px solid #dedede'],
      marginTop: ['1.5rem', null, null, '-2rem'],
      marginLeft: ['-1.5rem', null, null, '0'],
      width: ['auto', null, null, '225px'],
    })}
  }

  > p {
    font-size: 1.25rem;
  }

  > *,
  > * > * {
    display: inline-block;
    width: 100%;
    min-width: 0;
  }
`;

export const StyledCommerceWrapperHorizontal = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  &.horizontalRight,
  &.horizontalLeft {
    padding-top: 2rem;
    ${props =>
      Media({
        background: [null, null, null, props.theme.colors.white],
      })}
  }

  ${Media({
    paddingTop: ['1.5rem', '1.5rem', '2rem', null],
    height: ['auto', 'auto', '172px', 'auto'],
    marginTop: ['1.5rem', null, null, '2rem'],
    marginRight: ['-1.5rem', null, null, '-2rem'],
    marginBottom: ['-1.5rem', null, null, '-2rem'],
    marginLeft: ['-1.5rem', null, null, '-2rem'],
    paddingLeft: ['1.5rem', null, null, '2rem'],
    paddingRight: ['1.5rem', null, null, '2rem'],
    paddingBottom: ['1.5rem', null, null, '24px'],
  })}

  ${StyledProductInfo} + & {
    border: none;

    ${Media({
      display: [null, null, null, 'flex'],
      flexDirection: [null, null, null, 'column'],
      textAlign: ['left', null, null, 'right'],
      borderTop: ['1px solid #dedede', null, null, 'none'],
      borderLeft: ['none', null, null, '1px solid #dedede'],
      marginTop: ['1.5rem', null, null, '-2rem'],
      marginLeft: ['-1.5rem', null, null, '0'],
      width: ['auto', null, null, '225px'],
    })}
  }

  > p {
    font-size: 1.25rem;
    margin-top: 0px;
  }

  > *,
  > * > * {
    display: inline-block;
    width: 100%;
    min-width: 0;
  }
`;

export const StyledCommerceWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;

  &.horizontalRight,
  &.horizontalLeft {
    padding-top: 2rem;
    height: auto;
    ${props =>
      Media({
        marginTop: ['1.5rem', '2rem', null],
        background: [null, null, null, props.theme.colors.white],
      })}
  }

  ${Media({
    height: ['auto', 'auto', '166px', null],
    paddingTop: ['1.5rem', '1.5rem', '2rem', null],
    marginTop: ['1.5rem', '2rem', null, '2rem'],
    marginRight: ['-1.5rem', null, null, '-2rem'],
    marginBottom: ['-1.5rem', null, null, '-2rem'],
    marginLeft: ['-1.5rem', null, null, '-2rem'],
    paddingLeft: ['1.5rem', null, null, '2rem'],
    paddingRight: ['1.5rem', null, null, '2rem'],
    paddingBottom: ['1.5rem', null, null, '2rem'],
  })}

  ${StyledProductInfo} + & {
    border: none;

    ${Media({
      display: [null, null, null, 'flex'],
      flexDirection: [null, null, null, 'column'],
      textAlign: ['left', null, null, 'right'],
      borderTop: ['1px solid #dedede', null, null, 'none'],
      borderLeft: ['none', null, null, '1px solid #dedede'],
      marginTop: ['1.5rem', null, null, '-2rem'],
      marginLeft: ['-1.5rem', null, null, '0'],
      width: ['auto', null, null, '225px'],
    })}
  }

  > p {
    font-size: 1.25rem;

    ${Media({
      marginTop: [null, null, null, '2rem'],
      flexGrow: [null, null, null, '1'],
    })}
  }

  > *,
  > * > * {
    display: inline-block;
    width: 100%;
    min-width: 0;
  }
`;

interface StyledCtaWrapper {
  horizontal?: boolean;
}

export const StyledCtaWrapper = styled.div<StyledCtaWrapper>`
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  padding-bottom: 1.4375rem;
  padding-top: 1.4375rem;
  display: flex;
  min-height: 79px;
  justify-content: ${props => (props.horizontal ? 'flex-start' : 'center')};
  flex-wrap: wrap;

  > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props =>
    Media({
      flexDirection: ['column', null, null, props.horizontal ? 'row' : 'column'],
      marginTop: ['1.5rem', null, null, '2rem'],
      marginRight: ['-1.5rem', null, null, '-2rem'],
      marginBottom: ['-1.5rem', null, null, '-2rem'],
      marginLeft: ['-1.5rem', null, null, '-2rem'],
      paddingLeft: ['1.5rem', null, null, '2rem'],
      paddingRight: ['1.5rem', null, null, '2rem'],
    })}
`;

interface StyledCta {
  horizontal?: boolean;
}

export const StyledCta = styled(Cta)<StyledCta>`
  color: ${props => props.theme.cardCtas.color};
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  text-transform: ${props => props.theme.cardCtas.textTransform};
  letter-spacing: ${props => props.theme.cardCtas.letterSpacing};
  font-size: ${props => props.theme.cardCtas.fontSize};
  line-height: ${props => props.theme.cardCtas.lineHeight};

  ${props =>
    Media({
      width: ['auto', null, null, props.horizontal ? 'calc(50% - 10px)' : null],
      marginRight: [0, null, null, props.horizontal ? '10px' : null],
    })}

  > span {
    vertical-align: middle;
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
    transform: translateX(-4px);
    fill: ${props => props.theme.cardCtas.arrowColor};

    > * {
      fill: ${props => props.theme.cardCtas.arrowColor};
    }
  }

  &:hover {
    color: ${props => props.theme.cardCtas.hoverColor};

    > svg {
      fill: ${props => props.theme.cardCtas.arrowHoverColor};

      > * {
        fill: ${props => props.theme.cardCtas.arrowHoverColor};
      }
    }
  }
`;

export const StyledContentWrapper = styled(Flex)`
  height: 100%;
  flex-direction: column;
`;
