import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

export interface ContactsFormModuleProps extends BoxProps {
  onSubmit: (data: any) => void;
}

export const ContactsFormModule = React.forwardRef(({ onSubmit, ...rest }: ContactsFormModuleProps, ref) => {
  // eslint-disable-next-line no-console
  console.log(onSubmit);
  return (
    <Box ref={ref} {...(rest as any)}>
      <h3>Send us a message</h3>
      <p>Fill out the form below and our team will be in touch.</p>
      <p>todo: implementation</p>
    </Box>
  );
});
ContactsFormModule.displayName = 'ContactsFormModule';

export default ContactsFormModule;
