import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  StyledCheckboxContainer,
  StyledHeader,
  StyledDropDown,
  StyledDropDownContainer,
  StyledDropDownItemSeparator,
  StyledDropDownClear,
  StyledDropDownSeparator,
} from './StyledMultiSelectFilter';

interface ArrowProps {
  expanded?: boolean;
}

const Arrow = ({ expanded }: ArrowProps) => {
  return (
    <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d={expanded ? 'M18 15 12 9 6 15' : 'M6 9L12 15 18 9'} />
    </svg>
  );
};

const RoundedCheckBox = ({ checked, ...props }: any) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckboxContainer>
        <StyledCheckbox checked={checked} />
      </StyledCheckboxContainer>
    </CheckboxContainer>
  );
};

const DropdownHeader = ({ label, value }: any) => {
  const noneSelected = value.length === 0;
  const totalLength = value.length;

  return (
    <span>
      {label} {!noneSelected ? `(${totalLength})` : ''}
    </span>
  );
};

const MultiSelect = ({ selected, options, expanded, setExpanded, label }: any) => {
  const [cbState, setCbState] = useState(options);
  const [filterState, setFilterState] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    selected(filterState);
  }, [filterState]);

  const counter = [] as any;
  cbState.map((x: any) => {
    if (x.checked) {
      counter.push(x);
    }

    return x;
  });

  const handleCheckboxChange = (event: any, index: any) => {
    const selectedItem = options[index];
    selectedItem.checked = event.target.checked;

    const filteredObjectsChecked = options.filter((x: any) => x.checked);
    setFilterState(filteredObjectsChecked);
    setCbState(options);
  };

  const clear = () => {
    const optionItems = cbState.map((x: any) => {
      const item = x;
      item.checked = false;

      return item;
    });

    setCbState(optionItems);
    setFilterState([]);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledDropDownContainer expanded={expanded}>
      <StyledHeader onClick={toggleExpanded}>
        <DropdownHeader label={label} value={counter} />
        <Arrow expanded={expanded} />
      </StyledHeader>
      <StyledDropDownSeparator expanded={expanded} />
      {expanded && (
        <StyledDropDown expanded={expanded}>
          {options.map((option: any, index: any) => {
            return (
              <StyledDropDownItemSeparator key={option.value}>
                <label htmlFor={`cb-${option.value}`}>
                  <RoundedCheckBox
                    id={`cb-${option.value}`}
                    checked={cbState[index].checked}
                    onChange={(e: any) => handleCheckboxChange(e, index)}
                  />
                  <span style={{ marginLeft: 8 }}>{option.label}</span>
                </label>
              </StyledDropDownItemSeparator>
            );
          })}
          <StyledDropDownClear onClick={clear}>
            {intl.formatMessage({ id: 'sections.dealer.clear' })}
          </StyledDropDownClear>
        </StyledDropDown>
      )}
    </StyledDropDownContainer>
  );
};

const useMultiSelectContainer = (ref: any, setExpanded: any) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

const MultiSelectContainer = (props: any) => {
  const {
    children: {
      props: { setExpanded },
    },
  } = props;
  const { children } = props;

  const wrapperRef = useRef(null);

  useMultiSelectContainer(wrapperRef, setExpanded);

  return <div ref={wrapperRef}>{children}</div>;
};

const MultiSelectFilter = ({ selected, options, label }: any) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <MultiSelectContainer>
      <MultiSelect selected={selected} options={options} label={label} setExpanded={setExpanded} expanded={expanded} />
    </MultiSelectContainer>
  );
};

export default MultiSelectFilter;
