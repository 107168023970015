import React from 'react';

import {
  StyledSalesPersonInfoSection,
  StyledSalesPerson,
  StyledSalesPersonImage,
  StyledSalesPersonCategory,
  StyledSalesPersonName,
  StyledSalesPersonEmail,
  StyledSalesPersonTelephoneNumber,
  StyledBorder,
} from './Styled';

export type SalesPersonType = {
  id: string;
  title: string;
  category: string;
  telephone?: string;
  email?: string;
  image: string;
};

const SalesPerson = ({ image, category, title, email, telephone }: SalesPersonType) => (
  <StyledSalesPerson>
    <StyledSalesPersonImage
      sx={{
        backgroundImage: `url(${image})`,
      }}
    />
    <StyledSalesPersonInfoSection>
      <StyledSalesPersonCategory>{category}</StyledSalesPersonCategory>
      <StyledBorder />
      <StyledSalesPersonName>{title}</StyledSalesPersonName>
      {email && <StyledSalesPersonEmail>{email}</StyledSalesPersonEmail>}
      {telephone && <StyledSalesPersonTelephoneNumber>{telephone}</StyledSalesPersonTelephoneNumber>}
      {!email && <StyledSalesPersonEmail>&nbsp;</StyledSalesPersonEmail>}
      {!telephone && <StyledSalesPersonTelephoneNumber>&nbsp;</StyledSalesPersonTelephoneNumber>}
    </StyledSalesPersonInfoSection>
  </StyledSalesPerson>
);

export default SalesPerson;
