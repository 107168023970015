import * as React from 'react';
import { useIntl } from 'react-intl';

//
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import ProductCardProps from './types';
import Cta from '../../Cta';
import Button from '../../Button';
import Arrow from '../../../icons/Arrow';
import { fileTypeIconMap } from '../../DocumentDownload';
import {
  StyledTitle,
  StyledImageBoxLink,
  StyledContentBox,
  StyledDescription,
  StyledCtaWrapper,
  StyledCta,
  StyledCommerceWrapper,
  StyledCommercePrice,
  StyledImageBoxLinkWrapper,
  StyledContentWrapper,
} from './Styled';

const Vertical = ({
  title,
  shortDescription,
  image,
  link,
  showLink,
  price,
  enquiryCta,
  showCommerceSection,
  cardCTAText,
  linkToDetailPage,
  linkToCustomPage,
  documents = [],
}: ProductCardProps) => {
  const intl = useIntl();
  const showCta = (showLink && linkToDetailPage) || linkToCustomPage;
  const showDocuments = !linkToDetailPage && !linkToCustomPage && !!documents.length;
  const imageBoxProps: any = {};
  if (linkToDetailPage) {
    imageBoxProps.to = link;
  }
  let linkTo = link;

  if (linkToCustomPage) {
    imageBoxProps.to = linkToCustomPage.internalEntry
      ? linkToCustomPage.internalEntry.slug
      : linkToCustomPage.externalUrl;

    linkTo = linkToCustomPage.internalEntry ? linkToCustomPage.internalEntry.slug : linkToCustomPage.externalUrl;
  }

  return (
    <StyledContentWrapper>
      <StyledImageBoxLinkWrapper>
        <StyledImageBoxLink {...imageBoxProps}>
          {image?.asset && (
            // <Img
            //   fluid={image.asset.fluid}
            //   alt={image.alt}
            //   style={{ width: '80%', maxHeight: '80%', position: 'inherit', margin: '0 auto' }}
            //   imgStyle={{ objectFit: 'contain' }}
            // />
            <GatsbyImage
              image={getImage(image.asset) as IGatsbyImageData}
              alt={image.alt ? image.alt : image.title}
              style={{ width: '80%', maxHeight: '80%', position: 'inherit', margin: '0 auto' }}
              objectFit="contain"
            />
          )}
        </StyledImageBoxLink>
      </StyledImageBoxLinkWrapper>

      <StyledContentBox>
        <StyledTitle>{linkToDetailPage ? <Cta to={linkTo}>{title}</Cta> : title}</StyledTitle>
        <StyledDescription>{shortDescription}</StyledDescription>
        {showCta && (
          <StyledCtaWrapper>
            <StyledCta to={linkTo}>
              <Arrow />
              <span>{cardCTAText || intl.formatMessage({ id: 'cards.product.link' })}</span>
            </StyledCta>
          </StyledCtaWrapper>
        )}

        {showDocuments && (
          <StyledCtaWrapper>
            {documents.map(({ documentTitle, documentType, downloadLink }) => (
              <StyledCta key={documentTitle} to={downloadLink} target="_blank">
                {documentType && fileTypeIconMap[documentType]}
                <span>{cardCTAText || documentTitle}</span>
              </StyledCta>
            ))}
          </StyledCtaWrapper>
        )}

        {!showCta && !showDocuments && <StyledCtaWrapper />}

        {price || enquiryCta || showCommerceSection ? (
          <StyledCommerceWrapper>
            {price ? <StyledCommercePrice>{price}</StyledCommercePrice> : null}

            {enquiryCta ? (
              <Cta to={enquiryCta.link}>
                <Button>{enquiryCta.label}</Button>
              </Cta>
            ) : null}
          </StyledCommerceWrapper>
        ) : null}
      </StyledContentBox>
    </StyledContentWrapper>
  );
};

export default Vertical;
