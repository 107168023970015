import * as React from 'react';
import { FlexProps } from 'rebass';

import LegalFooter from './LegalFooter';
import RichText from '../RichText';
import SubscribeForm from './SubscribeForm';
import StyledFooter, {
  StyledLegalWrapper,
  StyledContactWrapper,
  StyledFooterTopSection,
  StyledFooterMainNavigation,
  StyledFooterMainNavigationList,
  StyledFooterMainNavigationListItem,
  StyledFooterSocialList,
  StyledFooterSocialListItem,
  StyledFooterContactSection,
} from './Styled';
import Cta from '../Cta';
import {
  Youtube as YoutubeIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Linkedin as LinkedinIcon,
} from '../../icons';
import FlexmailSubscribeForm from './FlexmailSubscribeForm';

type link = {
  to: string;
  label: string;
};

interface Props extends FlexProps {
  legalLinks: Array<link>;
  contacts: any;
  footerNavigation: Array<link>;
  socialLinks: Array<link>;
  onSubmitSubscribe: (email: string, country: string, locale: string) => void;
  showSubscribeForm: boolean;
  showSubscribeFormCountrySelector: boolean;
  subscribeFormHeading?: string | null;
  flexmailId?: string | null;
  theme: string;
  pageLocale: string;
}

const socialIconMap: Record<string, React.ReactElement> = {
  facebook: <FacebookIcon />,
  youtube: <YoutubeIcon />,
  twitter: <TwitterIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedinIcon />,
};

const renderFooterMainNavigation = (footerNavigation: Array<link>) => (
  <StyledFooterMainNavigationList>
    {footerNavigation.map((item: link, idx: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <StyledFooterMainNavigationListItem key={`footernav-${item.to}-${idx}`}>
        <Cta to={item.to}>{item.label}</Cta>
      </StyledFooterMainNavigationListItem>
    ))}
  </StyledFooterMainNavigationList>
);

const renderSocialIcons = (socialLinks: Array<link>) => {
  if (Array.isArray(socialLinks) && socialLinks.length > 0) {
    return (
      <StyledFooterSocialList>
        {socialLinks.map((item: link, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledFooterSocialListItem key={`social-${item.to}-${idx}`}>
            <a target="_blank" rel="noopener noreferrer" href={item.to}>
              {socialIconMap[item.label]}
            </a>
          </StyledFooterSocialListItem>
        ))}
      </StyledFooterSocialList>
    );
  }

  return null;
};

const Footer = ({
  legalLinks,
  contacts,
  footerNavigation,
  socialLinks,
  onSubmitSubscribe,
  showSubscribeForm,
  showSubscribeFormCountrySelector,
  subscribeFormHeading = null,
  flexmailId = null,
  theme,
  pageLocale,
}: Props) => {
  return (
    <StyledFooter>
      <StyledFooterTopSection>
        <StyledContactWrapper>
          <RichText data={contacts.json} />
        </StyledContactWrapper>
        <StyledFooterMainNavigation>
          {renderFooterMainNavigation(footerNavigation)}
          {renderSocialIcons(socialLinks)}
        </StyledFooterMainNavigation>
        <StyledFooterContactSection>
          {showSubscribeForm &&
            (flexmailId ? (
              <FlexmailSubscribeForm theme={theme} flexmailId={flexmailId} heading={subscribeFormHeading} />
            ) : (
              <SubscribeForm
                onSubmit={(email: string, country: string) => onSubmitSubscribe(email, country, pageLocale)}
                showCountrySelector={showSubscribeFormCountrySelector}
              />
            ))}
        </StyledFooterContactSection>
      </StyledFooterTopSection>
      <StyledLegalWrapper>
        <LegalFooter items={legalLinks} />
      </StyledLegalWrapper>
    </StyledFooter>
  );
};

export default Footer;
