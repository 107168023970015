import React from 'react';
// import Img, { FluidObject } from 'gatsby-image';

// import breakpoints from '../../utils/breakpoints';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

export type ImageProps = GatsbyImageProps & {
  /** sources */
  sources: any[];
  alt?: string;
};

// interface Source extends IGatsbyImageData {
//   breakpointIndex: number;
//   alt: string;
// }

// type GetMedia = {
//   sources: Source[];
//   index: number;
// };

// const getMedia = ({ sources, index }: GetMedia) => {
//   const { breakpointIndex } = sources[index];
//   const previousBreakpointIndex = breakpointIndex - 1;
//   const minWidth = index === 0 ? '(min-width: 0px)' : `(min-width: ${breakpoints[previousBreakpointIndex]}px)`;
//   const maxWidth = index < sources.length - 1 ? `(max-width: ${breakpoints[breakpointIndex] - 1}px)` : '';

//   if (maxWidth) {
//     return `${minWidth} and ${maxWidth}`;
//   }

//   return minWidth;
// };

const Image = (props: GatsbyImageProps) => {
  // const filteredSources = sources
  //   .map((source, index) =>
  //     source
  //       ? {
  //           ...source,
  //           breakpointIndex: index,
  //         }
  //       : null,
  //   )
  //   .filter(source => source);

  // const imageList = filteredSources.map((item: IGatsbyImageData, index: number) => {
  //   console.log(getMedia({ sources: filteredSources, index }));
  //   return {
  //     media: getMedia({ sources: filteredSources, index }),
  //     image: getImage(item) as IGatsbyImageData,
  //   };
  // });

  // const images = withArtDirection(filteredSources[0], imageList) as Source;

  return <GatsbyImage {...props} />;
};

export default Image;
