import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import Media from '../../../utils/Media';
//

const StyledFooterContainer = styled(Flex)`
  height: 154px;
  z-index: 2;
  top: -20px;

  ${Media({
    height: ['auto', null, '154px'],
    backgroundPosition: [null, null, 'right bottom'],
    backgroundSize: ['0px 0px', null, null, '820px 100%'],
    backgroundRepeat: [null, null, 'no-repeat'],
    position: [null, null, 'absolute', 'static'],
    alignItems: ['center', null, 'flex-start', 'center'],
  })}
`;

export const StyledCopywriteMessage = styled.div`
  color: ${props => props.theme.colors.dark};
  margin-bottom: 8px;
  font-size: ${props => props.theme.footer.fontSize || '12px'};
`;

export const StyledImageContainer = styled(Box)`
  margin-left: auto;
  margin-right: 0;
  height: 104px;
  ${Media({
    display: ['block', null, null, 'none'],
  })}
  img {
    height: 104px;
    position: absolute;
    right: 0;
    ${Media({
      height: ['104px', null, 'auto'],
      width: ['auto', null, '100%'],
    })}
  }
`;

export const StyledLinkList = styled.ul`
  list-style-type: none;
  display: flex;
  padding-left: 0px;
  line-height: 16px;
  margin-top: 0px;
`;

export const StyledLinkItem = styled.li`
  a {
    text-decoration: none;
    margin-right: 10px;
    color: ${props => props.theme.colors.dark};
    font-size: ${props => props.theme.footer.fontSize || '12px'};

    &:hover {
      color: ${props => props.theme.footer.activeColor};
      text-decoration: underline;
    }
  }
`;

export const StyledInfoContainer = styled.div`
  max-width: ${props => props.theme.sizes.xl};
  width: 100%;
  margin: 0 auto;
  ${Media({
    paddingTop: ['30px', null, '0', '30px'],
    paddingLeft: ['1rem', null, '2rem', '58px'],
  })}
`;

export default StyledFooterContainer;
