import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import Button from '../../Button';
import NewsCard from '../../Cards/NewsCard';
import Cta from '../../Cta';
import { StyledTitle, StyledDescription, StyledCardList, StyledCardContainer } from './Styled';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../Layout/Styled';

type CtaProps = {
  to: string;
  label: string;
};

type NewsItem = {
  id: string;
  title: string;
  shortDescription: string;
  date: string;
  link: string;
};

interface LatestNewsModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional module short description */
  shortDescription?: string;
  /** section category */
  latestNews: Array<NewsItem>;
  /** card cta */
  cta?: CtaProps | null;
  /** the background image */
}

const LatestNewsModule = ({ title, shortDescription, latestNews, cta }: LatestNewsModuleProps) => {
  return (
    <StyledModuleContainer data-appsearch-ignore="true">
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{shortDescription}</StyledDescription>
      <StyledCardList>
        {latestNews
          .map((newsItem: NewsItem) => (
            <StyledCardContainer key={newsItem.id}>
              <NewsCard
                id={newsItem.id}
                title={newsItem.title}
                date={newsItem.date}
                shortDescription={newsItem.shortDescription}
                link={newsItem.link}
                variant="vertical"
              />
            </StyledCardContainer>
          ))
          .slice(0, 3)}
      </StyledCardList>
      {cta && cta.to ? (
        <StyledModuleCtaContainer>
          <Cta to={cta.to}>
            <Button>{cta.label}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      ) : null}
    </StyledModuleContainer>
  );
};

export default LatestNewsModule;
