import styled from 'styled-components';
import { Box, Text } from 'rebass/styled-components';
import Media from '../../../utils/Media';

export const StyledTextBoxWrapper = styled(Box)`
  position: relative;
  margin: 0 auto;

  ${Media({
    height: [null, null, null, null, `calc(564px + 59px)`],
    display: [null, null, 'flex', 'block'],
    flexDirection: [null, null, 'row-reverse'],
    marginLeft: [null, null, '-2rem', '0'],
    marginRight: [null, null, '-2rem', '0'],
  })}
`;

export const StyledTextBox = styled(Box)`
  background-color: ${props => props.theme.ctaSection.backgroundColor};
  color: ${props => props.theme.ctaSection.color};

  &.left {
    ${Media({
      left: ['0', null, null, '113px'],
    })}
  }
  &.right {
    ${Media({
      right: ['0', null, null, '113px'],
    })}
  }

  ${Media({
    position: [null, null, null, null, 'absolute'],
    marginRight: ['-1rem', '-1rem', '0', '0px'],
    marginLeft: ['-1rem', '-1rem', '0', '0px'],
    paddingTop: ['2.625rem', null, null, '4.625rem'],
    paddingRight: ['1.5625rem', null, null, '5rem'],
    paddingBottom: ['2.625rem', null, null, '5rem'],
    paddingLeft: ['1.5625rem', null, null, '5rem'],
    maxWidth: [null, null, null, '533px'],
    width: [null, null, null, '100%'],
    flex: [null, null, '1 1 0'],
  })}
`;

export const StyledCategory = styled.span`
  display: block;
`;

export const StyledImage = styled(Box)`
  bottom: 0;
  display: block;
  background-size: cover;
  background-color: ${props => props.theme.colors.muted};

  ${Media({
    position: [null, null, null, 'absolute'],
    zIndex: ['-1'],
    marginLeft: ['-1rem', '-1rem', '0', '-58px'],
    marginRight: ['-1rem', '-1rem', '0', '-58px'],
    width: ['calc(100% + 2rem)', 'auto', 'auto', `calc(100% + 58px + 58px)`],
    height: ['331px', null, 'auto', '564px'],
    top: ['174px', null, '59px'],
    flex: [null, null, '1 1 0'],
  })}
`;

export const StyledTextBoxTitle = styled.h3`
  font-size: 2rem;
  font-weight: ${props => props.theme.ctaSection.titleFontWeight || 'normal'};
  margin: 0;
  word-break: break-word;

  a {
    color: ${props => props.theme.colors.darkGray};
    text-decoration: none;
  }

  ${Media({
    fontSize: ['1.5rem', null, '2rem'],
    marginBottom: ['1rem', null, '1rem'],
  })}
`;

export const StyledTextBoxDescription = styled(Text)`
  font-size: 1rem;
  line-height: 1.75;

  ${Media({
    marginBottom: ['2rem', null, '2.5rem'],
  })}
`;
