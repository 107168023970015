// eslint-disable-next-line import/prefer-default-export
import Arieblok from './Arieblok';
import Avevebiochem from './Avevebiochem';
import Belcrop from './Belcrop';
import Palital from './Palital';
import Dumoulin from './Dumoulin';
import Kasperfaunafood from './Kasperfaunafood';
import Peka from './Peka';
import Pomagro from './Pomagro';
import Sabe from './Sabe';
import Scoriethom from './Scoriethom';
import Servagri from './Servagri';
import Spoormans from './Spoormans';
import Osmo from './Osmo';
import Equifirst from './Equifirst';
import Arvesta from './Arvesta';
import Hobbyfirst from './Hobbyfirst';
import Lannoo from './Lannoo';
import Hermoo from './Hermoo';
import Hafner from './Hafner';
import Alliance from './Alliance';
import Globalfeedmill from './Globalfeedmill';
import Cofabel from './Cofabel';
import Mifuma from './Mifuma';
import AveveAgrarisch from './AveveAgrarisch';
import Walagri from './Walagri';
import Sanac from './Sanac';
import Hortiplan from './Hortiplan';
import PentaTrading from './PentaTrading';
import NaturalGranen from './NaturalGranen';

const themes: { [key: string]: any } = {
  Arieblok,
  Avevebiochem,
  Belcrop,
  Palital,
  Dumoulin,
  Kasperfaunafood,
  Peka,
  Pomagro,
  Sabe,
  Scoriethom,
  Servagri,
  Spoormans,
  Osmo,
  Equifirst,
  Arvesta,
  Hobbyfirst,
  Lannoo,
  Hermoo,
  Hafner,
  Alliance,
  Globalfeedmill,
  Cofabel,
  Mifuma,
  AveveAgrarisch,
  Walagri,
  Sanac,
  Hortiplan,
  PentaTrading,
  NaturalGranen,
};

export default themes;
