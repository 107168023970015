import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import Media from '../../utils/Media';
//

export const StyledBannerContainer = styled(Box)`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: ${props => props.theme.colors.primary};
  z-index: 50;

  ${Media({
    minHeight: ['168px', null, null, '137px'],
  })}

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
`;

export const StyledContentContainer = styled(Box)`
  color: ${props => props.theme.colors.white};
  ${Media({
    paddingLeft: ['1rem', null, '32px', '58px'],
    paddingRight: ['1rem', null, '32px', '58px'],
    paddingTop: ['32px', null, null, '37px'],
  })}

  padding-bottom: 1rem;

  h2 {
    margin-top: 0px;
  }
`;

export const StyledRichTextContainer = styled(Box)`
  p {
    color: ${props => props.theme.colors.white};
    text-overflow: ellipsis;
    font-size: 14px !important;
    font-weight: 300;
    padding-right: 0px !important;
    line-height: 20px !important;
    text-overflow: ellipsis;
  }
  a {
    color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
`;

export const StyledHeading = styled(Box)`
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -0.5px;
  color: ${props => props.theme.colors.white};
`;

export const StyledCloseButton = styled(Box)`
  border-radius: 20px;
  height: 32px;
  width: 32px;
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  top: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${Media({
    right: ['1rem', null, '32px', '54px'],
  })}
`;
