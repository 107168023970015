/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';
import { useIntl, IntlShape } from 'react-intl';

import Cta from '../../Cta';
import Button from '../../Button';
import ProductCardProps from '../../Cards/ProductCard/types';
import {
  StyledModuleHeading,
  StyledModuleDescription,
  StyledCardContainer,
  GreyBoxContainer,
  StyledVerticalCardContainer,
  StyledVerticalProductWrapper,
  StyledModuleHeadingNoDescription,
} from './Styled';

import ProductCard from '../../Cards/ProductCard';
import { StyledModuleContainer, StyledModuleCtaContainer } from '../../Layout/Styled';
import { useCurrentBreakpoint } from '../../../utils/hooks/breakpoints';
import { mediumBreakpoint } from '../../../utils/breakpoints';
import { chunkArray } from '../../../utils/common';

type CtaProps = {
  to: string;
  label: string;
};

export type ProductCardModuleProps = BoxProps & {
  /** optional title */
  title?: string;
  /** optional card short description */
  shortDescription?: string | null;
  /** categories */
  products: ProductCardProps[];
  /** card cta */
  cta?: CtaProps | null;

  greyVariant?: boolean;
};

const renderModule = ({ title, shortDescription, cta, products }: ProductCardModuleProps, intl: IntlShape) => {
  const currentBreakpoint = useCurrentBreakpoint();

  const productChunkSize = currentBreakpoint === mediumBreakpoint ? 2 : 3;

  const chunkedProducts = chunkArray(products.slice(0, 3), productChunkSize);

  return (
    <>
      {title ? ( // eslint-disable-line no-nested-ternary
        title && !shortDescription ? (
          <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
        ) : (
          <StyledModuleHeading>{title}</StyledModuleHeading>
        )
      ) : (
        <StyledModuleHeading>{intl.formatMessage({ id: 'globals.featuredProducts' })}</StyledModuleHeading>
      )}

      {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      <StyledCardContainer className={`cards-${products.length}`}>
        {chunkedProducts.map(({ chunk, key }) => (
          <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`}>
            {chunk.map(({ id, ...productRest }) => (
              <StyledVerticalProductWrapper key={id} className={`cards-${chunk.length}`}>
                <ProductCard id={id} {...productRest} />
              </StyledVerticalProductWrapper>
            ))}
          </StyledVerticalCardContainer>
        ))}
      </StyledCardContainer>

      {cta && cta.to ? (
        <StyledModuleCtaContainer>
          <Cta to={cta.to}>
            <Button>{cta.label}</Button>
          </Cta>
        </StyledModuleCtaContainer>
      ) : null}
    </>
  );
};

const ProductCardModule = React.forwardRef(
  ({ title, shortDescription, cta, products, greyVariant = false, ...rest }: ProductCardModuleProps, ref) => {
    const intl = useIntl();
    if (greyVariant) {
      return (
        <GreyBoxContainer data-appsearch-ignore="true">
          {renderModule({ title, shortDescription, cta, products, ...rest }, intl)}
        </GreyBoxContainer>
      );
    }
    return (
      <StyledModuleContainer ref={ref} {...(rest as any)} data-appsearch-ignore="true">
        {renderModule({ title, shortDescription, cta, products, ...rest }, intl)}
      </StyledModuleContainer>
    );
  },
);
ProductCardModule.displayName = 'ProductCardModule';

export default ProductCardModule;
