import React from 'react';

import { StyledVideoEmbedded } from './Styled';
import { getEmbedIframe } from '../../utils/video';

type Props = {
  videoUrl: string;
};

const Video = ({ videoUrl }: Props) => <StyledVideoEmbedded>{getEmbedIframe({ url: videoUrl })}</StyledVideoEmbedded>;

export default Video;
