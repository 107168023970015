import React, { MutableRefObject } from 'react';
//
import { StyledWrapper, DocumentDownloadContainer } from './Styled';
import DocumentDownload from '../../DocumentDownload';
import { DocumentDownloadProps } from '../types';

const DocumentDownloadModule = React.forwardRef(
  ({ documentTitle, documentType, fileSize, downloadLink }: DocumentDownloadProps, ref) => (
    <StyledWrapper ref={ref as MutableRefObject<HTMLDivElement>}>
      <DocumentDownloadContainer>
        <DocumentDownload
          documentTitle={documentTitle}
          documentType={documentType}
          fileSize={fileSize}
          downloadLink={downloadLink}
          showDocumentInfo
        />
      </DocumentDownloadContainer>
    </StyledWrapper>
  ),
);
DocumentDownloadModule.displayName = 'DocumentDownloadModule';

export default DocumentDownloadModule;
