import styled from 'styled-components';
//

export const StyledWrapper = styled.span`
  width: 100%;
  position: relative;
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  margin-right: 8px;

  &:focus,
  &:hover {
    svg {
      fill: ${props => `${props.theme.search.activeColor}`};

      > * {
        fill: ${props => `${props.theme.search.activeColor}`};
      }
    }
  }

  svg {
    position: absolute;
    left: 8px;
    top: 8px;
  }

  span:last-child {
    cursor: pointer;

    svg {
      transform: scale(0.85);
      top: 10px;
    }
  }

  &.focused-input {
    padding: 0 30px 0 0;
    input {
      &::-webkit-input-placeholder {
        color: #999;
      }
      &::-moz-placeholder {
        color: #999;
      }
      &:-ms-input-placeholder {
        color: #999;
      }
      &:-moz-placeholder {
        color: #999;
      }
      &::placeholder {
        color: #999;
      }
    }
  }
`;

export const StyledInput = styled.input<any>`
  background-color: transparent;
  border: none;
  width: 100%;
  height: 40px;
  padding: 0 10px 0 42px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: ${props => props.theme.search.fontWeight || 'bold'};
  outline: none;

  &::-webkit-input-placeholder {
    color: ${props => `${props.theme.colors.lightGrey}`};
  }
  &::-moz-placeholder {
    color: ${props => `${props.theme.colors.lightGrey}`};
  }
  &:-ms-input-placeholder {
    color: ${props => `${props.theme.colors.lightGrey}`};
  }
  &:-moz-placeholder {
    color: ${props => `${props.theme.colors.lightGrey}`};
  }
  &::placeholder {
    color: ${props => `${props.theme.colors.lightGrey}`};
  }

  &:focus,
  &:hover {
    &::-webkit-input-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &::-moz-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &:-ms-input-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &:-moz-placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
    &::placeholder {
      color: ${props => `${props.theme.search.activeColor}`};
    }
  }
`;

export const StyledClosedButton = styled.span`
  position: absolute;
  right: 40px;
  top: -2px;
`;
