import React from 'react';
import { useIntl } from 'react-intl';

export const getID = (url: string): string => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length === 11) {
    return match[2];
  }

  return '';
};

export const getEmbedUrl = (id: string): string => `https://www.youtube-nocookie.com/embed/${id}`;

export const getEmbedProps = () => {
  const intl = useIntl();
  const { locale } = intl;

  return `cc_locale_pref=${locale}&hl=${locale}&modestbranding=1&rel=0`;
};

export const getEmbedAttributes = (): object => {
  return {
    frameBorder: '0',
    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowFullScreen: true,
    enablejsapi: 1,
  };
};

interface GetEmbedSrcProps {
  url: string;
}

export const getEmbedSrc = ({ url }: GetEmbedSrcProps) =>
  `${getEmbedUrl(getID(url))}?${getEmbedProps()}&controls=1&autoplay=0`;

interface GetEmbedIframeProps extends GetEmbedSrcProps {
  url: string;
  title?: string;
}

export const getEmbedIframe = ({ url, title = '' }: GetEmbedIframeProps) => {
  return <iframe title={title || ''} src={getEmbedSrc({ url })} {...getEmbedAttributes()} />;
};
