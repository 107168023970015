import React from 'react';
import { useIntl, IntlShape } from 'react-intl';

import {
  StyledOfficeTitle,
  StyledOffice,
  StyledOfficeAddress,
  StyledMapButtonContainer,
  StyledMapIcon,
  StyledMapText,
  StyledTelephoneNumber,
  StyledOpeningHoursTitle,
  StyledOpeningHoursText,
} from './Styled';

import { LocationPin as LocationPinIcon } from '../../../../icons';

type Office = {
  title: string;
  address: string;
  mapUrl: string;
  telephone: string;
  openingHours?: string;
};

const renderMapButton = (link: string, intl: IntlShape) => (
  <StyledMapButtonContainer target="_blank" href={link}>
    <StyledMapIcon>
      <LocationPinIcon />
    </StyledMapIcon>
    <StyledMapText>{intl.formatMessage({ id: 'sections.contact.viewonmap' })}</StyledMapText>
  </StyledMapButtonContainer>
);

const Office = ({ title, address, mapUrl, telephone, openingHours }: Office) => {
  const intl = useIntl();
  return (
    <StyledOffice>
      <StyledOfficeTitle>{title}</StyledOfficeTitle>
      <StyledOfficeAddress>{address}</StyledOfficeAddress>
      {renderMapButton(mapUrl, intl)}
      <StyledTelephoneNumber>{telephone}</StyledTelephoneNumber>
      {openingHours && (
        <>
          <StyledOpeningHoursTitle>
            {intl.formatMessage({ id: 'sections.contact.openinghours' })}
          </StyledOpeningHoursTitle>
          <StyledOpeningHoursText>{openingHours}</StyledOpeningHoursText>
        </>
      )}
    </StyledOffice>
  );
};

export default Office;
