import styled from 'styled-components';
//
import Media from '../../../utils/Media';

// eslint-disable-next-line import/prefer-default-export
export const StyledContainer = styled.div`
  ${Media({
    minWidth: ['100%', null, '400px'],
    maxWidth: ['320px', null, '400px'],
  })}

  iframe {
    overflow: hidden;
    height: 170px;
    max-height: 170px;
    width: 100%;
  }
`;

export const StyledHeading = styled.p`
  white-space: pre-wrap;
  margin-block-start: 0px;
  font-size: 12px;
  &.kasperfaunafood {
    font-family: 'Open Sans', sans-serif;
  }
  &.arieblok {
    font-family: 'Ropa Sans', Arial, sans-serif !important;
  }
`;

export const customIframeStyles: any = {
  arieblok: `
  body{
    font-family: 'Ropa Sans', Arial, sans-serif !important;
    font-size: 1em;
    line-height: 1.5;
    overflow-y: scroll;
    min-height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: 'kern';
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;

    -webkit-overflow-scrolling: touch;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-top: 0;
    background-color: #ffffff;
  }

  h3 {
    font-weight:bold;
    font-size:18px;
    color: #333333;
  }

  p {
    margin-bottom: 12px;
    margin-top: 12px;
    font-size: 12px;
  }

  table {
  color: #434343;
  background-color: #ffffff;
  }

  a{
    color: #2C00AC;
    text-decoration: none;
  }
  a:hover{
    color: #2C00AC;
    text-decoration: underline;
  }

  input, button, select, textarea {
    font-size: 13px !important;
  }
  p.small {
    color: #999999;
    font-size: 10px;
    margin: 0;
    text-align: right;
  }

  /* custom code */
  #btn-send {
  background-color: #bd2817 !important;
  border-width: 1px !important;
  color: #FBFEEE !important;
  background-image: none !important;
  border-radius: 0px !important;
  float:right;
  }

  #btn-send:hover{
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #bd2817 !important;
    border-radius: 0px !important;

  }
  * {
    font-family: 'Ropa Sans', Arial, sans-serif !important;
  }

  input {
    background-color: #eeeeee !important;
    color: #333333 !important;
    border-width:1px !important;
    border-radius:0px !important;
  }
  .fm_body{
    float:left;
    background-color: #ffffff;
  }
  .div_content{
    background-color: #ffffff;
  }
  @media screen and (max-width: 600px) {
    input {
      max-width: 240px;
    }
  }
  @media screen and (max-width: 320px) {
    input {
      max-width: 170px;
    }
  }
`,
  kasperfaunafood: `
  body{
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    line-height: 1.5;
    overflow-y: scroll;
    min-height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: 'kern';
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-top: 0;
    background-color: #ffffff;
  }

  h3 {
    font-weight:bold;
    font-size:18px;
    color: #333333;
  }

  p {
    margin-bottom: 12px;
    margin-top: 12px;
    font-size: 12px;
  }

  table {
  color: #434343;
  background-color: #ffffff;
  }

  a{
    color: #2C00AC;
    text-decoration: none;
  }
  a:hover{
    color: #2C00AC;
    text-decoration: underline;
  }

  input, button, select, textarea {
    font-size: 13px !important;
  }
  p.small {
    color: #999999;
    font-size: 10px;
    margin: 0;
    text-align: right;
  }

  /* custom code */
  #btn-send {
  background-color: #004A90 !important;
  border-width: 1px !important;
  color: #FBFEEE !important;
  background-image: none !important;
  border-radius: 35px !important;
  float:right;
  }

  #btn-send:hover{
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #004A90 !important;
    border-radius: 35px !important;

  }
  * {
    font-family: 'Open Sans', sans-serif !important;
  }

  input {
    background-color: #eeeeee !important;
    color: #333333 !important;
    border-width:1px !important;
    border-radius:35px !important;
  }

  .fm_body{
    float:left;
    background-color: #ffffff;
  }
  .div_content{
    background-color: #ffffff;
  }
  @media screen and (max-width: 600px) {
    input {
      max-width: 240px;
    }
  }
  @media screen and (max-width: 320px) {
    input {
      max-width: 170px;
    }
  }
`,
};
