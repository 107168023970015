import * as React from 'react';

const LocationPin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path
      fill="#3D3D3D"
      d="M12 3.667A5.83 5.83 0 006.167 9.5C6.167 13.875 12 20.333 12 20.333s5.833-6.458 5.833-10.833A5.83 5.83 0 0012 3.667zm0 7.916a2.084 2.084 0 11.002-4.168A2.084 2.084 0 0112 11.583z"
    />
  </svg>
);

export default LocationPin;
