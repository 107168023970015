/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import CustomBlockCard from '../../../Cards/CustomBlockCard';
import { StyledVerticalCardContainer, StyledVerticalCardWrapper, StyledWrapper, StyledBlueBox } from './Styled';

import { useCurrentBreakpoint } from '../../../../utils/hooks/breakpoints';
import { mediumBreakpoint } from '../../../../utils/breakpoints';
import { chunkArrayCategories } from '../../../../utils/common';

interface VerticalProps extends BoxProps {
  customBlocks: any[];
  twoColumns?: boolean;
}

export const Vertical = ({ customBlocks, twoColumns }: VerticalProps) => {
  const currentBreakpoint = useCurrentBreakpoint();
  const categoryChunkSize = currentBreakpoint === mediumBreakpoint || twoColumns ? 2 : 3;
  const chunkedCustomBlocks = chunkArrayCategories(customBlocks, categoryChunkSize);

  return (
    <StyledWrapper>
      {chunkedCustomBlocks.map(({ chunk, key }) => (
        <StyledVerticalCardContainer key={key} className={`cards-${chunk.length}`} twoColumns={twoColumns}>
          {chunk.map(({ id, ...categoryRest }) => (
            <StyledVerticalCardWrapper key={id} className={`cards-${chunk.length}`} twoColumns={twoColumns}>
              <CustomBlockCard id={id} {...categoryRest} />
              <StyledBlueBox />
            </StyledVerticalCardWrapper>
          ))}
        </StyledVerticalCardContainer>
      ))}
    </StyledWrapper>
  );
};

export default Vertical;
