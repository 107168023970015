import * as React from 'react';

import { StyledContainer, StyledHeading, customIframeStyles } from './Styled';

type Props = {
  heading?: string | null;
  flexmailId: string;
  theme: string;
};

const flexDomain = 'https://www.flexmail.eu/';

const FlexmailSubscribeForm = ({ theme, heading, flexmailId }: Props) => {
  const styles = customIframeStyles[theme] || '';

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!loaded && typeof document !== 'undefined') {
      const iframe = document.createElement('iframe');
      iframe.id = 'iframe_flxml_form';
      iframe.style.display = 'hidden';
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = 'none';
      iframe.scrolling = 'no';
      iframe.frameBorder = '0';
      // eslint-disable-next-line func-names
      iframe.onload = function() {
        iframe.style.display = 'block';
        iframe.contentWindow?.postMessage(styles, flexDomain);
        setLoaded(true);
      };
      iframe.src = `${flexDomain}sf-${flexmailId}`;
      const container = document.getElementById('iframe_container');
      if (container) {
        container.appendChild(iframe);
      }
    }
  }, []);

  return (
    <StyledContainer>
      <div id="div_content">
        {loaded && <StyledHeading className={theme}>{heading}</StyledHeading>}
        <div id="iframe_container" />
      </div>
    </StyledContainer>
  );
};

export default FlexmailSubscribeForm;
