import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import { getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import Arrow from '../../../../icons/Arrow';
import { chunkArray } from '../../../../utils/common';
import { StyledCategoriesAndImageContainer, StyledCategoriesContainer, StyledCategoryCta, StyledImage } from './Styled';
import breakpoints from '../../../../utils/breakpoints';

type ProductProps = {
  id: string;
  title: string;
  link: string;
};

export type ImageProps = {
  sources: (IGatsbyImageData | null)[];
  alt?: string;
};

interface Source extends IGatsbyImageData {
  breakpointIndex: number;
  alt: string;
}

type GetMedia = {
  sources: Source[];
  index: number;
};

interface TertiaryCategoriesProps extends BoxProps {
  /** categories */
  categories: ProductProps[];
  /** image */
  image: ImageProps;
}

const getMedia = ({ sources, index }: GetMedia) => {
  const { breakpointIndex } = sources[index];
  const previousBreakpointIndex = breakpointIndex - 1;
  const minWidth = index === 0 ? '(min-width: 0px)' : `(min-width: ${breakpoints[previousBreakpointIndex]}px)`;
  const maxWidth = index < sources.length - 1 ? `(max-width: ${breakpoints[breakpointIndex] - 1}px)` : '';

  if (maxWidth) {
    return `${minWidth} and ${maxWidth}`;
  }

  return minWidth;
};

export const TertiaryCategories = ({ image, categories }: TertiaryCategoriesProps) => {
  const eventsChunkSize = 3;

  const chunkedCategories = chunkArray(categories.slice(0, 6), eventsChunkSize);

  const filteredSources = image.sources
    .map((source, index) =>
      source
        ? {
            ...source,
            breakpointIndex: index,
          }
        : null,
    )
    .filter(source => source);

  const imageList = filteredSources.map((item: IGatsbyImageData | null, index: number) => {
    return {
      media: getMedia({ sources: filteredSources as Source[], index }),
      image: getImage(item as IGatsbyImageData) as IGatsbyImageData,
    };
  });

  const images = withArtDirection(filteredSources[0] as IGatsbyImageData, imageList);

  return (
    <StyledCategoriesAndImageContainer>
      {chunkedCategories.map(({ chunk, key }) => (
        <StyledCategoriesContainer key={key} className={`cards-${chunk.length}`}>
          {chunk.map(({ id, title, link }) => {
            return (
              <StyledCategoryCta className={`cards-${chunk.length}`} key={id} to={link}>
                <span>{title}</span>
                <Arrow />
              </StyledCategoryCta>
            );
          })}
        </StyledCategoriesContainer>
      ))}
      <StyledImage image={images} alt={image.alt as string} />
    </StyledCategoriesAndImageContainer>
  );
};

export default TertiaryCategories;
