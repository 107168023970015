import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 559.78668 190.86667" width="162px" height="50px">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,190.86667)" id="g10">
      <g transform="scale(0.1)" id="g12">
        <path
          id="path14"
          fill="#6ec72e"
          fillOpacity={1}
          fillRule="nonzero"
          stroke="none"
          d="m 559.66,277.66 c 1.223,-0.18 2.063,-0.301 2.063,-0.301 0,0 133.828,119.121 187.218,320.118 C 607.664,532.984 525.301,398.809 559.66,277.66"
        />
        <path
          id="path16"
          fill="#6ec72e"
          d="m 315.969,372.801 c 45.449,-28.211 89.531,-48.473 127.539,-62.77 -12.625,173.727 120.469,349.098 325.019,411.617 0.219,0.067 0.442,0.118 0.661,0.184 1.894,29.863 1.902,60.887 -0.727,93.164 -8.731,-2.324 -17.465,-4.762 -26.191,-7.43 C 509.898,736.535 346.266,560.066 315.969,372.801"
        />
        <path
          id="path18"
          fill="#6ec72e"
          d="m 189.184,808.18 c -5.153,11.539 -9.868,23.312 -13.563,35.429 -49.5,161.931 41.981,333.931 203.899,383.421 77.07,23.56 157.761,15.1 226.339,-18.99 -167.363,192.75 -396.906,223.46 -396.906,223.46 0,0 -304.8827,-270.59 -178.0936,-685.418 10.9375,-35.781 24.0586,-68.703 38.6992,-99.309 14.0039,24.415 29.4023,48.457 46.2614,71.969 22.36,31.188 46.942,61.004 73.364,89.438"
        />
        <path
          id="path20"
          fill="#6ec72e"
          d="m 204.695,655.016 c -27.492,-38.34 -50.379,-77.754 -69.007,-117.782 26.457,-35.382 55.054,-66.074 84.585,-92.648 56.743,206.066 238.981,390.82 490.032,467.555 13.453,4.105 26.91,7.738 40.371,11.117 -3.199,13.035 -6.801,26.191 -10.867,39.512 -4.02,13.128 -8.313,25.89 -12.864,38.3 -13.617,-3.55 -27.242,-7.39 -40.859,-11.55 C 486.586,928.535 315.637,809.746 204.695,655.016"
        />
        <path
          id="path22"
          fill="#6ec72e"
          d="M 411.48,1122.45 C 312.32,1092.14 254.371,990.484 276.355,890.906 c 94.598,79.367 206.071,143.014 328.227,186.644 -50.699,47.54 -124.199,65.96 -193.102,44.9"
        />
        <path
          id="path24"
          fill="#6ec72e"
          d="m 723.309,98.4883 c -8.36,-29.8789 -35.45,-52.4102 -69.883,-52.4102 -45.071,0 -73.68,34.6914 -73.68,72.9099 0,40.774 31.149,72.934 72.41,72.934 32.918,0 62.543,-20.242 71.153,-53.434 h -27.09 c -7.09,18.481 -24.313,28.864 -43.043,28.864 -13.93,0.257 -24.047,-4.301 -33.676,-13.411 -8.102,-8.101 -13.414,-21.011 -13.414,-34.953 0,-28.3591 20.754,-48.3591 47.09,-48.3591 23.043,0.2813 37.472,14.1797 43.043,27.8594 h 27.09"
        />
        <path
          id="path26"
          fill="#6ec72e"
          d="M 865.602,50.1289 H 840.781 V 71.3984 C 834.211,57.2188 818.762,45.8398 794.199,46.0781 749.891,46.332 738.492,78.4883 738.492,107.109 v 80.75 h 26.34 v -74.687 c 0,-24.8009 9.617,-42.5431 36.707,-42.5431 25.832,0.2813 37.723,16.7227 37.723,40.2811 v 76.949 h 26.34 V 50.1289"
        />
        <path id="path28" fill="#6ec72e" d="m 889.914,237.488 h 26.328 V 50.1289 H 889.914 V 237.488" />
        <path
          id="path30"
          fill="#6ec72e"
          d="m 950.418,167.359 h -24.82 v 20.5 h 24.82 v 49.629 h 26.344 v -49.629 h 26.568 v -20.5 H 976.762 V 50.1289 H 950.418 V 167.359"
        />
        <path
          id="path32"
          fill="#6ec72e"
          d="m 1012.96,237.488 h 26.32 v -27.84 h -26.32 z m 0,-49.629 h 26.32 V 50.1289 h -26.32 V 187.859"
        />
        <path
          id="path34"
          fill="#6ec72e"
          d="m 1128.15,50.1289 h -26.33 l -52.66,137.7301 h 27.6 l 38.23,-104.8199 38.22,104.8199 h 27.61 L 1128.15,50.1289"
        />
        <path
          id="path36"
          fill="#6ec72e"
          d="m 1175.75,119.238 c 0,36.731 27.09,72.684 73.44,72.684 46.33,0 73.42,-35.953 73.42,-72.922 0,-36.9609 -27.09,-72.668 -73.42,-72.668 -46.35,0 -73.44,35.7071 -73.44,72.668 z m 26.35,-0.488 c 0,-27.3516 20.74,-47.8516 47.09,-47.8516 26.32,0 47.09,20.5 47.09,48.1016 0,27.609 -20.77,48.109 -47.09,48.109 -26.35,0 -47.09,-20.5 -47.09,-48.109 v -0.25"
        />
        <path
          id="path38"
          fill="#6ec72e"
          d="m 1339.32,187.859 h 24.8 v -21.257 c 6.59,14.179 22.04,25.57 46.59,25.32 44.06,-0.512 55.7,-32.41 55.7,-61.012 V 50.1289 h -26.58 V 124.82 c 0,24.821 -9.62,42.539 -36.46,42.282 -25.82,-0.25 -37.73,-16.454 -37.73,-40.25 V 50.1289 h -26.32 V 187.859"
        />
        <path
          id="path40"
          fill="#6ec72e"
          d="m 1550.98,151.922 c 0,10.629 -6.57,18.726 -20,18.476 -12.91,0 -20,-8.609 -19.75,-17.726 0.52,-10.883 10.38,-15.942 25.07,-20 21.02,-5.82 44.06,-11.902 44.81,-41.5236 0.51,-26.8398 -21.77,-45.0703 -49.11,-45.0703 -21.78,0 -48.87,12.4102 -50.14,43.8008 h 26.08 c 1.01,-16.707 14.43,-22.2773 24.31,-22.2773 12.66,0 22.53,8.8672 22.53,20.5078 -0.26,13.6796 -10.63,18.2306 -35.19,26.3316 -19.25,5.829 -34.43,14.938 -34.68,35.707 0,26.071 21.25,41.774 46.32,41.774 19,0 43.81,-9.363 45.84,-40 h -26.09"
        />
        <path id="path42" fill="#6ec72e" d="m 1662.65,237.488 h 26.32 V 50.1289 h -26.32 V 237.488" />
        <path id="path44" fill="#6ec72e" d="m 1725.43,237.5 h 23.81 l -22.29,-52.418 h -20.5 l 18.98,52.418" />
        <path
          id="path46"
          fill="#6ec72e"
          d="m 1780.11,110.648 c 1.27,-27.8589 25.58,-42.0386 46.34,-42.0386 18.48,0 32.16,8.8594 39.75,23.3008 h 27.34 c -6.58,-15.1914 -16.19,-26.5899 -27.84,-34.1797 -11.66,-7.8516 -25.34,-11.6524 -39.25,-11.6524 -45.07,0 -72.93,36.211 -72.93,72.6719 0,39.488 30.9,73.172 72.68,73.172 19.74,0 37.72,-7.344 50.88,-20.25 16.21,-16.473 23.05,-37.992 20.76,-61.024 z m 91.66,20.5 c -1.27,18.231 -19.5,38.75 -45.57,38.231 -26.84,0 -44.32,-19.5 -45.59,-38.231 h 91.16"
        />
        <path
          id="path48"
          fill="#6ec72e"
          d="m 1941.65,121.781 -46.32,66.078 h 31.89 l 30.63,-47.347 30.39,47.347 h 31.41 l -46.08,-66.078 49.87,-71.6521 h -32.15 L 1957.85,101.27 1924.2,50.1289 h -32.17 l 49.62,71.6521"
        />
        <path
          id="path50"
          fill="#6ec72e"
          d="m 2032.05,187.859 h 25.05 V 163.82 c 10.39,16.45 28.11,28.102 52.68,28.102 47.34,0 69.63,-39.5 69.63,-73.684 0,-38.988 -28.11,-72.1599 -69.38,-72.1599 -28.61,0 -45.57,15.6914 -51.66,26.8438 V 50.1289 0 h -26.32 z m 120.77,-68.609 c 0,22.539 -15.94,48.109 -48.11,48.109 -13.68,0 -25.57,-5.32 -34.18,-14.179 -8.36,-8.61 -13.43,-20.758 -13.43,-34.442 0,-13.656 5.07,-25.5661 13.68,-34.1794 8.61,-8.6094 20.77,-13.9297 34.18,-13.9297 27.86,0 47.86,21.5313 47.86,48.3711 v 0.25"
        />
        <path
          id="path52"
          fill="#6ec72e"
          d="m 2213.08,110.648 c 1.27,-27.8589 25.56,-42.0386 46.34,-42.0386 18.47,0 32.14,8.8594 39.74,23.3008 h 27.35 c -6.6,-15.1914 -16.21,-26.5899 -27.86,-34.1797 -11.65,-7.8516 -25.33,-11.6524 -39.23,-11.6524 -45.07,0 -72.93,36.211 -72.93,72.6719 0,39.488 30.89,73.172 72.67,73.172 19.75,0 37.73,-7.344 50.89,-20.25 16.2,-16.473 23.05,-37.992 20.75,-61.024 z m 91.66,20.5 c -1.27,18.231 -19.5,38.75 -45.58,38.231 -26.83,0 -44.31,-19.5 -45.58,-38.231 z m -16.71,122.801 9.11,-19.238 -61.52,-30.902 -7.86,16.211 60.27,33.929"
        />
        <path
          id="path54"
          fill="#6ec72e"
          d="m 2346.51,187.859 h 24.8 v -20.25 c 7.85,16.211 22.29,24.063 41.02,24.313 v -26.344 h -1.77 c -24.83,0 -37.74,-13.656 -37.74,-41.008 V 50.1289 h -26.31 V 187.859"
        />
        <path
          id="path56"
          fill="#6ec72e"
          d="m 2422.97,237.488 h 26.32 v -27.84 h -26.32 z m 0,-49.629 h 26.32 V 50.1289 h -26.32 V 187.859"
        />
        <path
          id="path58"
          fill="#6ec72e"
          d="m 2490.57,110.648 c 1.27,-27.8589 25.57,-42.0386 46.35,-42.0386 18.47,0 32.14,8.8594 39.74,23.3008 h 27.35 c -6.6,-15.1914 -16.21,-26.5899 -27.86,-34.1797 -11.65,-7.8516 -25.33,-11.6524 -39.23,-11.6524 -45.08,0 -72.93,36.211 -72.93,72.6719 0,39.488 30.89,73.172 72.67,73.172 19.74,0 37.72,-7.344 50.88,-20.25 16.21,-16.473 23.05,-37.992 20.76,-61.024 z m 91.66,20.5 c -1.27,18.231 -19.49,38.75 -45.57,38.231 -26.83,0 -44.31,-19.5 -45.58,-38.231 h 91.15"
        />
        <path
          id="path60"
          fill="#6ec72e"
          d="m 2624.01,187.859 h 24.79 v -21.257 c 6.59,14.179 22.04,25.57 46.59,25.32 44.06,-0.512 55.71,-32.41 55.71,-61.012 V 50.1289 h -26.59 V 124.82 c 0,24.821 -9.62,42.539 -36.46,42.282 -25.82,-0.25 -37.73,-16.454 -37.73,-40.25 V 50.1289 h -26.31 V 187.859"
        />
        <path
          id="path62"
          fill="#6ec72e"
          d="m 2910.61,98.4883 c -8.36,-29.8789 -35.45,-52.4102 -69.88,-52.4102 -45.06,0 -73.68,34.6914 -73.68,72.9099 0,40.774 31.15,72.934 72.41,72.934 32.92,0 62.54,-20.242 71.15,-53.434 h -27.09 c -7.09,18.481 -24.31,28.864 -43.04,28.864 -13.93,0.257 -24.05,-4.301 -33.68,-13.411 -8.1,-8.101 -13.42,-21.011 -13.42,-34.953 0,-28.3591 20.76,-48.3591 47.1,-48.3591 23.04,0.2813 37.47,14.1797 43.04,27.8594 h 27.09"
        />
        <path
          id="path64"
          fill="#6ec72e"
          d="m 2943.02,110.648 c 1.27,-27.8589 25.58,-42.0386 46.34,-42.0386 18.48,0 32.16,8.8594 39.75,23.3008 h 27.34 c -6.58,-15.1914 -16.19,-26.5899 -27.84,-34.1797 -11.66,-7.8516 -25.34,-11.6524 -39.25,-11.6524 -45.07,0 -72.93,36.211 -72.93,72.6719 0,39.488 30.89,73.172 72.68,73.172 19.74,0 37.72,-7.344 50.88,-20.25 16.21,-16.473 23.05,-37.992 20.76,-61.024 z m 91.66,20.5 c -1.27,18.231 -19.5,38.75 -45.57,38.231 -26.84,0 -44.32,-19.5 -45.59,-38.231 h 91.16"
        />
        <path
          id="path66"
          fill="#1f3e6d"
          d="m 895.105,847.355 h 126.055 l 101.04,-354.078 97.33,337.399 h 102.88 l 99.18,-337.399 99.17,354.078 h 126.08 L 1483.7,338.469 h -114.02 l -100.1,343.91 -97.33,-343.91 H 1058.23 L 895.105,847.355"
        />
        <path
          id="path68"
          fill="#1f3e6d"
          d="m 2196.46,338.469 h -121.44 v 73.242 c -32.43,-59.332 -100.1,-88.992 -171.47,-88.992 -163.15,0 -258.61,126.984 -258.61,270.668 0,160.351 115.85,269.73 258.61,269.73 92.69,0 149.23,-49.121 171.47,-89.902 v 74.14 h 121.44 z m -430.1,252.133 c 0,-63.028 45.42,-154.793 154.8,-154.793 67.66,0 112.15,35.214 135.33,81.57 12.05,22.246 17.61,46.348 19.46,71.379 0.93,24.101 -3.71,49.121 -13.9,71.367 -21.32,48.191 -68.59,89.902 -141.82,89.902 -98.25,0 -153.87,-79.707 -153.87,-158.488 v -0.937"
        />
        <path id="path70" fill="#1f3e6d" d="m 2298.36,1024.41 h 121.43 V 338.48 h -121.43 v 685.93" />
        <path
          id="path72"
          fill="#1f3e6d"
          d="M 3045.44,338.469 H 2924 v 73.242 c -32.44,-59.332 -100.1,-88.992 -171.47,-88.992 -163.15,0 -258.61,126.984 -258.61,270.668 0,160.351 115.85,269.73 258.61,269.73 92.69,0 149.23,-49.121 171.47,-89.902 v 74.14 h 121.44 z m -430.11,252.133 c 0,-63.028 45.42,-154.793 154.81,-154.793 67.65,0 112.15,35.214 135.33,81.57 12.05,22.246 17.62,46.348 19.45,71.379 0.94,24.101 -3.71,49.121 -13.89,71.367 -21.32,48.191 -68.6,89.902 -141.82,89.902 -98.25,0 -153.88,-79.707 -153.88,-158.488 v -0.937"
        />
        <path
          id="path74"
          fill="#1f3e6d"
          d="m 3667.35,398.73 c 0,-83.421 -15.76,-137.191 -47.28,-179.82 -43.55,-58.398 -128.84,-104.738 -233.57,-104.738 -158.52,0 -234.53,94.527 -253.06,173.328 h 140.9 c 11.11,-23.18 30.57,-40.781 52.84,-51.898 25.02,-13.911 51.89,-17.622 66.73,-17.622 72.29,0 151.09,50.051 151.09,152.942 v 42.637 c -25.03,-45.418 -78.8,-90.829 -165,-90.829 -146.44,0 -259.54,111.223 -259.54,267.872 0,165.918 118.64,271.586 259.54,271.586 73.23,0 136.26,-33.36 165,-83.418 v 68.589 h 122.35 z m -425.46,194.645 c 0,-88.973 63.95,-156.648 152.02,-156.648 45.42,0 81.56,15.769 108.46,43.574 26.86,26.883 42.63,64.883 43.55,108.457 0,38.926 -12.04,79.715 -37.07,109.363 -24.1,30.586 -62.11,50.988 -114.94,50.988 -82.5,0 -152.02,-63.957 -152.02,-154.796 v -0.938"
        />
        <path
          id="path76"
          fill="#1f3e6d"
          d="m 3765.54,847.355 h 114.94 v -66.726 c 22.24,45.418 63.95,81.559 140.88,82.488 V 740.77 h -5.56 c -85.28,0 -128.85,-39.864 -128.85,-127.911 v -274.39 h -121.41 v 508.886"
        />
        <path
          id="path78"
          fill="#1f3e6d"
          d="m 4076.95,915.961 h 121.41 v 108.449 h -121.41 z m 0,-577.481 h 121.41 V 847.359 H 4076.95 V 338.48"
        />
      </g>
    </g>
  </svg>
);

export default Logo;
