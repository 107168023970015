import React from 'react';

import { Alert as AlertIcon } from '../../../icons';
import { StyledFormRowRadio, StyledLabel, StyledRadio, StyledError, StyledRadioSpan } from './Styled';

type InputProps = {
  field: any;
  label: string;
  options: Array<any>;
  handleChange: (e: React.FormEvent<HTMLInputElement>, name: string, value: string) => void;
};

const RadioFieldGroup = ({ field, label, options, handleChange }: InputProps) => (
  <StyledFormRowRadio className={field.error && 'error'}>
    <StyledLabel>{label}</StyledLabel>
    {options.map((option: any) => (
      <StyledLabel key={option.name}>
        <StyledRadio
          className="form-radio-button"
          id={option.name}
          name={field.name}
          value={field.name}
          onClick={e => handleChange(e, field.name, option.name)}
          defaultChecked={field.value !== '' ? field.value === field.name : undefined}
        />
        <StyledRadioSpan className={field.value.toLowerCase() === option.name.toLowerCase() ? 'true' : 'false'} />
        {option.label}
      </StyledLabel>
    ))}

    {field.error && (
      <StyledError>
        <AlertIcon />
        <span>{field.error}</span>
      </StyledError>
    )}
  </StyledFormRowRadio>
);

export default RadioFieldGroup;
