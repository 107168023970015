import { postcodeValidator } from 'postcode-validator';

let { COUNTRY_CODES } = process.env;

if (!COUNTRY_CODES) {
  COUNTRY_CODES = 'NL,BE,FR,DE,UK';
}

const locate = (address: string) => {
  const geocoder = new google.maps.Geocoder(); // eslint-disable-line
  let options: any;

  const targetCountry = COUNTRY_CODES?.split(',').find((country: any) => {
    if (postcodeValidator(address.trim(), country)) {
      return country;
    }
    return null;
  });

  if (targetCountry) {
    options = {
      region: targetCountry,
      address: address.trim(),
      componentRestrictions: {
        country: targetCountry,
        postalCode: address.trim(),
      },
    };
  } else {
    options = {
      address: address.trim(),
      region: COUNTRY_CODES?.split(',')[0],
    };
  }

  return new Promise(resolve => {
    geocoder.geocode(options, (response: Array<any>, status: string) => {
      return resolve({
        results: response,
        status,
      });
    });
  });
};

export default locate;
