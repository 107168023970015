import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass/styled-components';
//
import CustomBlockCard from '../../Cards/CustomBlockCard';
import Media from '../../../utils/Media';

export const StyledModuleHeadingContainer = styled.div`
  ${Media({
    minHeight: [null, null, null, '7.25rem'],
  })}
`;

export const StyledModuleHeading = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.customBlockCards.titleFontWeight || 'normal'};
  word-break: break-word;
  font-size: 40px;
  line-height: 1.2;

  ${Media({
    marginBottom: ['1rem', null, null, '0.5rem'],
  })}
`;

export const StyledModuleHeadingNoDescription = styled.h2`
  color: ${props => props.theme.colors.text};
  font-weight: normal;
  word-break: break-word;

  ${Media({
    fontSize: ['2rem', null, null, '2.5rem'],
    marginBottom: ['3rem', null, null, '3.25rem'],
  })}

  ${Media({
    maxWidth: ['none', null, '60%', '50%'],
    marginBottom: ['3.25rem', null, '2rem', '3.25rem'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['3rem', null, null, '3.25rem'],
  })}

  ${Media({
    maxWidth: ['none', null, '60%', '50%'],
    marginBottom: ['3.25rem', null, '2rem', '3.25rem'],
  })}
`;

export const StyledContainer = styled(Box)`
  position: relative;
  max-width: ${props => props.theme.sizes.xl};

  ${Media({
    marginTop: ['56px', null, null, '81px'],
    marginLeft: ['1rem', null, '2rem', '58px'],
    marginRight: ['1rem', null, '2rem', '58px'],
  })};
`;

export const StyledBlueBox = styled(Box)`
  position: absolute;
  width: calc(591 / 1440 * 100%);
  top: 0px;
  right: 0px;
  background-color: ${props => props.theme.customBlockCards.backgroundColor};
  z-index: -1;

  ${Media({
    marginTop: ['0px', null, '95px', '0px'],
    height: ['188px', null, '288px', '402px'],
    width: [`calc(266 / 375 * 100%)`, null, `calc(591 / 1440 * 100%)`],
    transform: ['translate(15px, -2.125rem)', null, 'translate(59px, -2rem)'],
  })}

  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    height: 350px !important;
    width: calc(691 / 1440 * 100%) !important;
  }
`;

export const StyledCardContainer = styled(Flex)`
  align-items: top;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;

  ${Media({
    marginBottom: ['1rem', null, null, '0'],
  })}

  &.cards-1 {
    ${Media({
      width: ['100%', null, null, '33.33%'],
    })}
  }

  ${Media({
    position: ['relative', null, 'static'],
    flexWrap: ['wrap', null, null, 'nowrap'],
  })}

  > *:not(:last-child) {
    ${Media({
      marginBottom: ['1rem', null, '32px', '0'],
    })}
  }
`;

export const StyledCustomBlockCard = styled(CustomBlockCard)`
  max-width: none;

  ${Media({
    width: ['100%', null, 'calc(50% - 16px)', 'calc(33.33% - 16px)'],
  })}

  &.cards-2 {
    ${Media({
      width: ['100%', null, 'calc(50% - 16px)'],
    })}
  }

  &.cards-1 {
    ${Media({
      width: ['100%', null, 'calc(50% - 16px)', '100%'],
    })}
  }
`;

export const StyledCenteredModuleTitle = styled.div`
  max-width: 650px;
  margin: 0 auto;
  h2 {
    ${Media({
      fontSize: ['40px', null, null, '56px'],
    })}
  }
`;
