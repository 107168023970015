import styled from 'styled-components';
import { Box, Text, Flex } from 'rebass/styled-components';

import Media from '../../../utils/Media';

export const StyledModuleHeading = styled.h1`
  color: ${props => props.theme.colors.text};
  font-weight: ${props => props.theme.adviceCardModule.titleFontWeight || 'bold'};
  word-break: break-word;

  ${Media({
    fontSize: ['2.5rem', null, null, '3.5rem'],
    marginBottom: ['1rem', null, null, '1.5rem'],
  })}
`;

export const StyledModuleDescription = styled(Text)`
  color: ${props => props.theme.colors.text};
  line-height: 1.875rem;
  font-size: 1.25rem;

  ${Media({
    marginBottom: ['3.0625rem', null, null, '3.25rem'],
  })}
`;

export const StyledHeadingAndDescriptionWrapper = styled(Box)`
  max-width: 605px;
`;

export const StyledContainer = styled(Flex)`
  max-width: 1099px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  ${Media({
    paddingLeft: ['1rem', null, '2rem', '58px'],
    paddingRight: ['1rem', null, '2rem', '58px'],
  })}
`;

export const StyledLoadMoreWrapper = styled(Box)`
  align-self: flex-start;

  ${Media({
    marginTop: ['2.5rem', null, null, '3.5rem'],
  })}
`;

export const StyledCardsWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;
`;
