/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React from 'react';
// import Img from 'gatsby-image';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Video from '../Video';
import {
  StyledWrapper,
  StyledHeadingAndDescriptionWrapper,
  StyledModuleHeading,
  StyledModuleDescription,
  StyledColoredBox,
  StyledMediaWrapper,
  StyledImageWrapper,
  StyledCaption,
  StyledEmbedly,
} from './Styled';

interface Media {
  title?: string;
  shortDescription?: string;
  caption?: string;
  image?: any;
  videoUrl?: string;
  halfWidthImage?: boolean;
  embedlyContent?: string;
}

const Media = ({ title, shortDescription, image, caption, halfWidthImage, videoUrl, embedlyContent }: Media) => {
  const specificImage = image?.asset?.landscapeImage || image?.asset?.portraitImage || image?.asset?.squareImage;

  React.useEffect(() => {
    if (typeof window !== 'undefined' && embedlyContent && embedlyContent.length > 0) {
      // eslint-disable
      const initEmbedly = (w: any, d: any) => {
        // eslint-disable-next-line one-var
        const id = 'embedly-platform',
          n = 'script';
        if (!d.getElementById(id)) {
          w.embedly =
            w.embedly ||
            // eslint-disable-next-line func-names
            function() {
              // eslint-disable-next-line prefer-rest-params
              (w.embedly.q = w.embedly.q || []).push(arguments);
            };
          const e = d.createElement(n);
          e.id = id;
          e.async = 1;
          // this trick is required to make size of player more adequate
          e.onload = () => {
            // @ts-ignore
            if (window.embedly) {
              // @ts-ignore
              window.embedly('on', 'card.rendered', (iframe: any) => {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                if (iframeDocument) {
                  const playerWrapper = iframeDocument.getElementsByClassName('resp-media');

                  if (playerWrapper.length) {
                    playerWrapper[0].style.paddingBottom = '';
                  }
                }
              });
            }
          };
          e.src =
            // eslint-disable-next-line prefer-template
            (document.location.protocol === 'https:' ? 'https' : 'http') + '://cdn.embedly.com/widgets/platform.js';
          const s = d.getElementsByTagName(n)[0];
          s.parentNode.insertBefore(e, s);
        }
      };
      initEmbedly(window, document);
    }
  }, []);

  return (
    <StyledWrapper className="media">
      <StyledHeadingAndDescriptionWrapper>
        {title && <StyledModuleHeading>{title}</StyledModuleHeading>}
        {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
      </StyledHeadingAndDescriptionWrapper>
      {embedlyContent && embedlyContent.length > 0 ? (
        // eslint-disable-next-line react/no-danger
        <StyledEmbedly dangerouslySetInnerHTML={{ __html: embedlyContent }} />
      ) : (
        <>
          <StyledColoredBox />
          <StyledMediaWrapper>
            <StyledImageWrapper halfWidthImage={halfWidthImage}>
              {specificImage && (
                // <Img
                //   loading="eager"
                //   fluid={image.asset.fluid}
                //   alt={image.alt}
                //   style={{ width: '100%', height: '100%' }}
                // />
                <GatsbyImage image={specificImage} alt={image.alt ? image.alt : image.title} />
              )}
              {!image && videoUrl && <Video videoUrl={videoUrl} />}
            </StyledImageWrapper>
          </StyledMediaWrapper>
          {caption && <StyledCaption>{caption}</StyledCaption>}
        </>
      )}
    </StyledWrapper>
  );
}

export default Media;
