import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import Media from '../../../utils/Media';

export const StyledTitle = styled(Box)`
  font-size: 40px;
  line-height: 1.2;
  color: ${props => props.theme.colors.text};
  margin-bottom: 16px;
  word-break: break-word;
`;

export const StyledDescription = styled(Box)`
  font-size: 20px;
  line-height: 1.5;
  color: ${props => props.theme.colors.darkGrey};
`;

export const StyledCardList = styled(Box)`
  display: flex;
  align-items: top;
  justify-content: left;

  ${Media({
    position: ['relative', null, null, 'static'],
    flexWrap: ['wrap', null, null, 'nowrap'],
  })}

  > *:not(:last-child) {
    ${Media({
      marginBottom: ['1rem', '0px', null, null],
    })}
  }
`;

export const StyledCardContainer = styled(Box)`
  > div:first-child {
    max-width: 100%;
  }

  ${Media({
    width: ['100%', null, 'calc(50% - 0.5rem)', 'calc(33.33% - 3.5rem)'],
    marginRight: [null, null, null, '3.5rem'],
  })}

  &:nth-child(odd) {
    ${Media({
      marginRight: [null, null, '1rem', '3.5rem'],
    })}
  }
`;
