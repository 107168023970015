import styled from 'styled-components';
import { Box, Flex } from 'rebass/styled-components';
import Media from '../../../../utils/Media';

// eslint-disable-next-line import/prefer-default-export
export const StyledHorizontalProductWrapper = styled(Box)`
  ${Media({
    width: ['100%', null, '50%', '100%'],
    marginBottom: ['1rem', null, '0', '1rem'],
  })}

  &.cards-1 {
    ${Media({
      width: ['100%'],
      boxShadow: ['none', null, null, '0 2px 20px 0 rgba(0,0,0,0.1)'],
    })}
    > .horizontalLeft,
    > .horizontalRight {
      ${Media({
        boxShadow: [null, '0 2px 20px 0 rgba(0,0,0,0.1)', 'none'],
      })}
    }
  }

  > .horizontalLeft,
  > .horizontalRight {
    max-width: none;
    ${Media({
      boxShadow: [null, null, 'inset -1px 0px 0px 0px #dedede', '0 2px 20px 0 rgba(0,0,0,0.1)'],
    })}
  }

  &:nth-child(even) {
    > .horizontalLeft,
    > .horizontalRight {
      ${Media({
        boxShadow: [null, null, 'none', '0 2px 20px 0 rgba(0,0,0,0.1)'],
      })}
    }
  }
`;

export const StyledCardsWrapper = styled(Flex)`
  flex-wrap: wrap;
  width: 100%;

  &.cards-1 {
    ${Media({
      width: ['100%', null, '50%', '100%'],
      boxShadow: ['none', null, '0 2px 20px 0 rgba(0,0,0,0.1)', 'none'],
    })}
  }

  &.cards-2 {
    ${Media({
      boxShadow: [null, null, '0 2px 20px 0 rgba(0,0,0,0.1)', 'none'],
    })}
  }

  ${Media({
    boxShadow: [null, null, null, 'none'],
    marginBottom: [null, null, '1rem', '0px'],
  })}
`;
