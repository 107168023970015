/* eslint-disable react/require-default-props */
import React from 'react';
import { BoxProps } from 'rebass/styled-components';

import { CardProps } from '../types';
import {
  StyledModuleHeadingContainer,
  StyledModuleHeading,
  StyledModuleDescription,
  StyledCustomBlockCard,
  StyledCardContainer,
  StyledBlueBox,
  StyledModuleHeadingNoDescription,
} from './Styled';
import { StyledModuleContainer } from '../../Layout/Styled';
import { Vertical } from './Vertical/Vertical';

export interface CustomBlocksModuleProps extends BoxProps {
  /** title */
  title: string;
  /** optional card short description */
  shortDescription?: string | null;
  /** customBlocks */
  customBlocks: CardProps[];
  twoColumns?: boolean;
}

const renderTitleWithShortDescription = (title: string, shortDescription: string | null | undefined) => (
  <StyledModuleHeadingContainer>
    {!shortDescription ? (
      <StyledModuleHeadingNoDescription>{title}</StyledModuleHeadingNoDescription>
    ) : (
      <StyledModuleHeading>{title}</StyledModuleHeading>
    )}

    {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
  </StyledModuleHeadingContainer>
);

const CustomBlocksModule = React.forwardRef(
  ({ title, shortDescription, customBlocks, twoColumns, ...rest }: CustomBlocksModuleProps, ref) => (
    <StyledModuleContainer ref={ref} {...(rest as any)}>
      {renderTitleWithShortDescription(title, shortDescription)}

      {twoColumns ? (
        <Vertical customBlocks={customBlocks} twoColumns={twoColumns} />
      ) : (
        <StyledCardContainer className={`cards-${customBlocks.length}`}>
          {customBlocks
            .map((card: CardProps) => (
              <StyledCustomBlockCard
                id={card.id}
                key={`customBlock-${card.id}`}
                title={card.title}
                shortDescription={card.shortDescription || null}
                link={card.link}
                image={card.image}
                className={`cards-${customBlocks.length}`}
              />
            ))
            .slice(0, 3)}

          <StyledBlueBox />
        </StyledCardContainer>
      )}
    </StyledModuleContainer>
  ),
);
CustomBlocksModule.displayName = 'CustomBlocksModule';

export default CustomBlocksModule;
