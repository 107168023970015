import * as React from 'react';
import { useIntl } from 'react-intl';

import StyledFooterContainer, {
  StyledCopywriteMessage,
  StyledLinkList,
  StyledInfoContainer,
  StyledLinkItem,
  StyledImageContainer,
} from './Styled';
import Cta from '../../Cta';

export interface Props {
  items: Array<{
    to: string;
    label: string;
  }>;
}

const LegalFooter = ({ items }: Props) => {
  const intl = useIntl();

  return (
    <>
      <StyledFooterContainer
        sx={{
          backgroundImage: `url(/images/arvesta-footer.jpg)`,
        }}
      >
        <StyledInfoContainer>
          <StyledCopywriteMessage>
            {intl.formatMessage({ id: 'footer.copyright' }, { year: new Date().getFullYear() })}
          </StyledCopywriteMessage>
          <StyledLinkList>
            {items.map((item, key) => (
              <StyledLinkItem key={`${item.to}-${key}`}>
                <Cta to={item.to}>{item.label}</Cta>
              </StyledLinkItem>
            ))}
          </StyledLinkList>
        </StyledInfoContainer>
      </StyledFooterContainer>
      <StyledImageContainer>
        <img src="/images/arvesta-footer.jpg" alt="Arvesta" />
      </StyledImageContainer>
    </>
  );
};

export default LegalFooter;
