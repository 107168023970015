import React from 'react';

const FlagUk = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <rect id="a-uk" width="34" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-uk" fill="#fff">
        <use xlinkHref="#a-uk" />
      </mask>
      <use fill="#FFF" xlinkHref="#a-uk" />
      <path fill="#0A17A7" d="M0 0h34v24H0z" mask="url(#b-uk)" />
      <path
        fill="#FFF"
        d="M36.178-2L38 .69 26.454 8.443l8.156.001v8.112h-8.155L38 24.31 36.178 27l-14.605-9.808v9.098h-8.147v-9.098L-1.178 27-3 24.31l11.545-7.754H.39V8.444l8.155-.001L-3 .69-1.178-2l14.604 9.807V-1.29h8.148l-.001 9.097L36.178-2z"
        mask="url(#b-uk)"
      />
      <path stroke="#DB1F35" strokeLinecap="round" strokeWidth="0.667" d="M23 8L38-2" mask="url(#b-uk)" />
      <path
        stroke="#DB1F35"
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M24 26l14-9"
        mask="url(#b-uk)"
        transform="matrix(1 0 0 -1 0 43)"
      />
      <path stroke="#DB1F35" strokeLinecap="round" strokeWidth="0.667" d="M10 8L-4-2" mask="url(#b-uk)" />
      <path
        stroke="#DB1F35"
        strokeLinecap="round"
        strokeWidth="0.667"
        d="M11 27L-5 17"
        mask="url(#b-uk)"
        transform="matrix(1 0 0 -1 0 44)"
      />
      <path fill="#E6273E" d="M0 14.4h14.571V24h4.858v-9.6H34V9.6H19.429V0H14.57v9.6H0z" mask="url(#b-uk)" />
    </g>
  </svg>
);

const FlagDe = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <filter id="c-de" width="108.9%" height="137.5%" x="-4.5%" y="-18.8%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius="0.5" result="shadowSpreadOuter1" />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feColorMatrix in="shadowOffsetOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
      </filter>
      <filter id="e-de" width="108.9%" height="137.5%" x="-4.5%" y="-18.8%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" operator="dilate" radius="0.5" result="shadowSpreadOuter1" />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feColorMatrix in="shadowOffsetOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
      </filter>
      <path id="d-de" d="M0 8h33.6v8H0z" />
      <path id="f-de" d="M0 16h33.6v8H0z" />
      <rect id="a-de" width="33.6" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-de" fill="#fff">
        <use xlinkHref="#a-de" />
      </mask>
      <use fill="#FFF" xlinkHref="#a-de" />
      <path fill="#262626" d="M0 0h33.6v8H0z" mask="url(#b-de)" />
      <g mask="url(#b-de)">
        <use fill="#000" filter="url(#c-de)" xlinkHref="#d-de" />
        <use fill="#F01515" xlinkHref="#d-de" />
      </g>
      <g mask="url(#b-de)">
        <use fill="#000" filter="url(#e-de)" xlinkHref="#f-de" />
        <use fill="#FFD521" xlinkHref="#f-de" />
      </g>
    </g>
  </svg>
);

const FlagFr = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <rect id="a-fr" width="33.6" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-fr" fill="#fff">
        <use xlinkHref="#a-fr" />
      </mask>
      <rect width="33.1" height="23.5" x="0.25" y="0.25" stroke="#F5F5F5" strokeWidth="0.5" rx="2" />
      <path fill="#F44653" d="M22.4 0h11.2v24H22.4z" mask="url(#b-fr)" />
      <path fill="#1035BB" d="M0 0h11.2v24H0z" mask="url(#b-fr)" />
    </g>
  </svg>
);

const FlagNl = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <rect id="a-nl" width="33.6" height="24" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-5)">
      <mask id="b-nl" fill="#fff">
        <use xlinkHref="#a-nl" />
      </mask>
      <rect width="33.1" height="23.5" x="0.25" y="0.25" stroke="#F5F5F5" strokeWidth="0.5" rx="2" />
      <path fill="#CA2B39" d="M0 0h33.6v8H0z" mask="url(#b-nl)" />
      <path fill="#2C56A2" d="M0 16h33.6v8H0z" mask="url(#b-nl)" />
    </g>
  </svg>
);

const flags: Record<string, JSX.Element> = {
  en: <FlagUk />,
  fr: <FlagFr />,
  nl: <FlagNl />,
  de: <FlagDe />,
};

export default flags;
