import styled from 'styled-components';
import { Box, Text } from 'rebass/styled-components';
import Media from '../../utils/Media';

export const StyledWrapper = styled(Box)`
  max-width: 646px;
  margin: 0 auto;
  position: relative;
  flex-direction: column;

  ${Media({
    display: ['flex', null, null, 'block'],
    paddingBottom: [0, null, null, 40],
  })}
`;

export const StyledImage = styled(Box)`
  width: 100%;
  order: 2;

  ${Media({
    height: [295, null, null, 418],
    marginTop: ['-60px!important', null, null, '0px!important'],
  })}
`;

export const StyledTextBox = styled(Box)`
  background-color: ${props => props.theme.shortBanner.backgroundColor};
  color: ${props => props.theme.shortBanner.color};
  padding: 40px 40px 48px;
  z-index: 1;
  position: relative;
  order: 1;
  min-height: 312px;

  ${Media({
    marginTop: [0, null, null, -340],
    top: [0, null, null, -40],
    width: ['100%', null, null, 436],
  })}

  &.left {
    ${Media({
      left: ['auto', null, null, -100],
      right: ['-1.5rem', null, '-2.5rem', 'auto'],
    })}
  }
  &.right {
    ${Media({
      right: ['-1.5rem', null, '-2.5rem', -330],
    })}
  }
`;

export const StyledTextBoxTitle = styled.h3`
  font-size: 2rem;
  margin-top: 0 !important;
  line-height: 1.1 !important;
  word-break: break-word;
  text-transform: ${props => props.theme.shortBanner.titleTextTransform};
  font-weight: ${props => props.theme.shortBanner.titleFontWeight};

  ${Media({
    fontSize: ['1.5rem!important', null, '2rem!important'],
    marginBottom: ['1rem!important', null, '1.2rem!important'],
  })}
`;

export const StyledTextBoxDescription = styled(Text)`
  font-size: 1rem;
  line-height: 1.75;
  white-space: pre-wrap;

  ${Media({
    marginBottom: ['2rem'],
  })}
`;
