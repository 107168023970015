import React from 'react';
import { BoxProps } from 'rebass/styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from 'react-cookie';

import { useIntl } from 'react-intl';
import Button from '../../Button';
import { Check as CheckIcon, Alert as AlertIcon } from '../../../icons';
import InputField from './InputField';
import RadioFieldGroup from './RadioFieldGroup';
import {
  StyledContainer,
  StyledHeading,
  StyledDescription,
  StyledForm,
  StyledFormRow,
  StyledError,
  StyledSuccess,
} from './Styled';
import { Config, EMAIL_REGEX } from '../../../utils';

export interface ContactsFormProps extends BoxProps {
  /** title */
  title: string;
  /** optional short description */
  shortDescription?: string;
  /** submit handler */
  handleFormSubmit: (name: string, formData: { [key: string]: string }) => void;
  /** show email subscribe options */
  showSubscribeOptions?: boolean;
}

type FieldType = {
  name: string;
  value: string;
  error: string;
};

type FormDataType = {
  name: FieldType;
  email: FieldType;
  message: FieldType;
  newsletter: FieldType;
};

const defaultFormDataType: FormDataType = {
  name: { name: 'name', value: '', error: '' },
  email: { name: 'email', value: '', error: '' },
  message: { name: 'message', value: '', error: '' },
  newsletter: { name: 'newsletter', value: '', error: '' },
};

const NETLIFY_FORM_NAME = Config.NETLIFY_CONTACT_FORM_NAME || 'contact-storybook';

export const ContactsForm = React.forwardRef(
  ({ title, shortDescription, handleFormSubmit, showSubscribeOptions, ...rest }: ContactsFormProps, ref) => {
    const [cookies] = useCookies(['OptanonConsent', 'OptanonAlertBoxClosed']);
    const intl = useIntl();
    const recaptchaRef = React.useRef();
    const [formData, setFormData] = React.useState(defaultFormDataType);
    const [submitting, setSubmitting] = React.useState(false);
    const [submitStatus, setSubmitStatus] = React.useState('');
    const [captchaSolved, setCaptchaSolved] = React.useState(false);

    const validateField = (name: keyof FormDataType) => {
      let error = '';
      const field: FieldType = formData[name];
      const { value } = field;

      switch (name) {
        case 'name':
        case 'message':
        case 'newsletter':
          if (!value.length && showSubscribeOptions) {
            error = intl.formatMessage({ id: 'forms.contact.errors.required' });
          }
          break;
        case 'email':
          if (!value.length) {
            error = intl.formatMessage({ id: 'forms.contact.errors.required' });
          } else if (!EMAIL_REGEX.test(value)) {
            error = intl.formatMessage({ id: 'forms.contact.errors.email' });
          }
          break;
        default:
      }

      return error;
    };

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>, field: string) => {
      const target = e.target as HTMLInputElement;
      const newFormDataType: any = { ...formData };
      newFormDataType[field].value = target.value;
      newFormDataType[field].error = validateField(field as keyof FormDataType);
      setFormData(newFormDataType);
    };

    const handleRadioChange = (_e: React.FormEvent<HTMLInputElement>, field: string, value: string) => {
      const newFormDataType: any = { ...formData };
      newFormDataType[field].value = value;
      newFormDataType[field].error = validateField(field as keyof FormDataType);
      setFormData(newFormDataType);
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
      e.preventDefault();

      const newFormData: FormDataType = { ...formData };
      const values: { [key: string]: string } = {};
      let errors = false;

      Object.keys(formData).forEach((key: string) => {
        values[key] = formData[key as keyof FormDataType].value;
        newFormData[key as keyof FormDataType].error = validateField(key as keyof FormDataType);
        if (newFormData[key as keyof FormDataType].error.length) {
          errors = true;
        }
      });

      if (errors || !captchaSolved) {
        setFormData(newFormData);
      } else {
        setSubmitting(true);

        (async function performSubmission() {
          const result: any = await handleFormSubmit(NETLIFY_FORM_NAME, values);
          if (!result.status || result.status !== 200) {
            setSubmitStatus('error');
            setSubmitting(false);
          } else {
            setSubmitStatus('success');
            const newFormDataType: any = { ...formData };
            newFormDataType.name.value = '';
            newFormDataType.email.value = '';
            newFormDataType.message.value = '';
            newFormDataType.newsletter.value = '';
            setFormData(newFormDataType);
          }
        })();
      }
    };

    const onCaptchaChange = (value: any) => {
      if (value) {
        setCaptchaSolved(true);
      } else {
        setCaptchaSolved(false);
      }
    };

    const hasOneTrustEnabled = process.env.ONE_TRUST_ID;
    const hasConsent = hasOneTrustEnabled && cookies.OptanonAlertBoxClosed;

    return (
      <StyledContainer ref={ref} {...(rest as any)}>
        <StyledHeading>{title}</StyledHeading>
        {shortDescription && <StyledDescription>{shortDescription}</StyledDescription>}

        <StyledForm
          as="form"
          name={NETLIFY_FORM_NAME}
          onSubmit={handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />

          <InputField
            field={formData.name}
            label={intl.formatMessage({ id: 'forms.contact.fullname.label' })}
            placeholder={intl.formatMessage({ id: 'forms.contact.fullname.placeholder' })}
            handleChange={handleInputChange}
          />

          <InputField
            field={formData.email}
            label={intl.formatMessage({ id: 'forms.contact.email.label' })}
            placeholder={intl.formatMessage({ id: 'forms.contact.email.placeholder' })}
            handleChange={handleInputChange}
          />

          <InputField
            field={formData.message}
            label={intl.formatMessage({ id: 'forms.contact.message.label' })}
            placeholder={intl.formatMessage({ id: 'forms.contact.message.placeholder' })}
            handleChange={handleInputChange}
            textarea
          />

          {showSubscribeOptions && (
            <RadioFieldGroup
              field={formData.newsletter}
              options={[
                { name: 'yes', label: intl.formatMessage({ id: 'globals.yes' }) },
                { name: 'no', label: intl.formatMessage({ id: 'globals.no' }) },
              ]}
              label={intl.formatMessage({ id: 'forms.contact.newsletter.title' })}
              handleChange={handleRadioChange}
            />
          )}

          {((hasOneTrustEnabled && hasConsent) || !hasOneTrustEnabled) && (
            <StyledFormRow>
              <ReCAPTCHA
                ref={recaptchaRef}
                hl={intl.locale}
                sitekey={Config.RECAPTCHA_KEY}
                onChange={onCaptchaChange}
                size="compact"
              />
            </StyledFormRow>
          )}

          <StyledFormRow>
            <Button disabled={(hasOneTrustEnabled && !hasConsent) || submitting || !captchaSolved}>
              {intl.formatMessage({ id: 'forms.contact.submit' })}
            </Button>
          </StyledFormRow>

          {submitStatus && (
            <>
              {submitStatus === 'success' && (
                <StyledSuccess>
                  <AlertIcon />
                  <span>{intl.formatMessage({ id: 'forms.contact.success' })}</span>
                </StyledSuccess>
              )}
              {submitStatus === 'error' && (
                <StyledError>
                  <CheckIcon />
                  <span>{intl.formatMessage({ id: 'forms.contact.failure' })}</span>
                </StyledError>
              )}
            </>
          )}
        </StyledForm>
      </StyledContainer>
    );
  },
);
ContactsForm.displayName = 'ContactsForm';

export default ContactsForm;
