import * as React from 'react';
import { BoxProps } from 'rebass';
//
import { StyledNavigation, StyledNavItem } from './Styled';
import Cta from '../../Cta';
import { NavItemType } from '../types';
import matchUrl from '../matchUrl';

interface Props extends BoxProps {
  items: Array<NavItemType> | undefined;
  activeMenu: string | null;
}

const SecondaryNavigation = ({ items, activeMenu }: Props) => {
  return (
    <StyledNavigation>
      {items &&
        items.map((item: NavItemType, idx: number) => (
          <StyledNavItem
            // eslint-disable-next-line react/no-array-index-key
            key={`secnav-${item.to}-${idx}`}
            className={matchUrl(activeMenu as string, item) ? 'active' : 'inactive'}
          >
            <Cta to={item.to}>{item.label}</Cta>
          </StyledNavItem>
        ))}
    </StyledNavigation>
  );
};

export default SecondaryNavigation;
