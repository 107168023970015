import React from 'react';

import ProductCardProps from './types';
import Vertical from './Vertical';
import Horizontal from './Horizontal';
import { StyledCard } from './Styled';

const ProductCard = React.forwardRef(
  (
    {
      id,
      title,
      shortDescription,
      image,
      link,
      showLink = true,
      variant = 'vertical',
      price,
      enquiryCta,
      showCommerceSection,
      cardCTAText,
      linkToDetailPage = true,
      linkToCustomPage,
      documents,
      ...rest
    }: ProductCardProps,
    ref,
  ) => {
    const cardProps = {
      id,
      title,
      shortDescription,
      image,
      link,
      showLink,
      price,
      enquiryCta,
      variant,
      showCommerceSection,
      cardCTAText,
      linkToDetailPage,
      linkToCustomPage,
      documents,
    };

    return (
      <StyledCard ref={ref} {...(rest as any)} className={variant}>
        {variant === 'vertical' ? <Vertical {...cardProps} /> : <Horizontal {...cardProps} />}
      </StyledCard>
    );
  },
);
ProductCard.displayName = 'ProductCard';

export default ProductCard;
