export default {
  'globals.yes': 'Ja',
  'globals.no': 'Nein',
  'globals.download': 'Download',
  'globals.featuredProducts': 'vorgestellte Produkte',
  'header.search.placeholder': 'Suche',
  'header.selectlanguage': 'Sprache auswählen',
  'footer.copyright': '© {year} Arvesta. Alle Rechte vorbehalten.',
  'footer.newsletter.heading':
    'Möchten Sie Tipps und Ratschläge erhalten, die Ihren Interessen entsprechen? Wir erledigen das gerne für Sie!',
  'footer.newsletter.subscribe':
    'Möchten Sie Tipps und Ratschläge erhalten, die Ihren Interessen entsprechen? Wir erledigen das gerne für Sie!',
  'footer.newsletter.placeholder': 'E-Mail Adresse eingeben',
  'footer.newsletter.success': 'Sie sind abonniert, danke. Sie werden in Kürze von uns hören.',
  'footer.newsletter.error':
    'Bei den von Ihnen eingegebenen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'footer.subscribe.enteremail': 'E-Mail Adresse eingeben',
  'footer.subscribe.subscribe': 'abonnieren',
  'footer.subscribe.invalid_email': 'Können Sie Ihre E-Mail-Adresse eingeben?',
  'footer.subscribe.success': 'Sie sind abonniert, danke. Sie werden in Kürze von uns hören.',
  'footer.subscribe.failure':
    'Bei den von Ihnen eingegebenen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'footer.subscribe.subscribing': 'Bitte warten Sie, während wir versuchen, eine Verbindung zum Server herzustellen',
  'product.certifications.title': 'Produktzertifizierungen',
  'forms.contact.errors.required': 'Bitte füllen Sie dieses Pflichtfeld aus.',
  'forms.contact.errors.email': 'Können Sie Ihre E-Mail-Adresse eingeben?',
  'forms.contact.failure':
    'Bei den von Ihnen eingegebenen Informationen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  'forms.contact.success': 'Vielen Dank, wir prüfen bereits Ihre Nachricht.',
  'forms.contact.title': 'Kontaktieren Sie uns',
  'forms.contact.description':
    'Füllen Sie das unten stehende Formular aus und unser Team wird sich mit Ihnen in Verbindung setzen.',
  'forms.contact.fullname.label': 'Vor- und Nachname',
  'forms.contact.fullname.placeholder': 'Bitte tragen sie Ihren vollständigen Namen ein',
  'forms.contact.email.label': 'E-Mail-Adresse',
  'forms.contact.email.placeholder': 'Bitte geben Sie Ihre E-Mail-Adresse an',
  'forms.contact.message.label': 'Ihre Nachricht',
  'forms.contact.message.placeholder': 'Bitte geben Sie hier Ihre Nachricht ein',
  'forms.contact.newsletter.title':
    'Möchten Sie Tipps und Ratschläge erhalten, die Ihren Interessen entsprechen? Wir erledigen das gerne für Sie!',
  'forms.contact.submit': 'Senden',
  'sections.dealer.placeholder': 'Geben Sie die Postleitzahl und den Ort ein',
  'sections.dealer.loadmore': 'Zeige alles',
  'sections.dealer.invalidaddress': 'Wir konnten keine Adresse finden',
  'sections.dealer.search': 'Suche',
  'sections.dealer.maplink': 'Ansicht auf Karte',
  'sections.dealer.total': 'Ergebnisse für',
  'sections.dealer.clear': 'Auswahl aufheben',
  'sections.search.results.title': 'Ergebnisse für',
  'sections.search.noresults.title': 'Entschuldigung, wir haben keine Ergebnisse für gefunden ',
  'cards.category.link': 'Mehr sehen',
  'cards.product.link': 'Mehr sehen',
  'cards.event.link': 'Ereignis anzeigen',
  'cards.news.link': 'Lesen Sie weiter',
  'cards.advice.link': 'Artikel lesen',
  'events.loadmore': 'Laden Sie weitere Ratschläge',
  'events.loadmorepast': 'Laden Sie mehr vergangene Ereignisse',
  'sections.events.loadmore': 'Laden Sie mehr Produkte',
  'sections.events.loadmorepast': 'Laden Sie mehr vergangene Ereignisse',
  'sections.advice.loadmore': 'Laden Sie weitere Ratschläge',
  'sections.news.loadmore': 'Weitere Nachrichten laden',
  'sections.product.loadmore': 'Laden Sie mehr',
  'sections.category.loadmore': 'Mehr laden',
  'sections.product.noproductsfoundfor': 'Keine Produkte gefunden für',
  'sections.product.table.number': 'art.nr',
  'sections.product.table.product': 'Produkt',
  'sections.product.table.weight': 'Gewicht',
  'sections.product.table.brochure': 'Flugblatt',
  'sections.product.table.download': 'Download',
  'sections.contact.offices': 'Büros',
  'sections.contact.salesteam': 'Ihre Ansprechpartner',
  'sections.contact.viewonmap': 'Ansicht auf Karte',
  'sections.contact.openinghours': 'Öffnungszeiten',
  'legacy.cookie.description': 'Wenn Sie weiterhin auf der Website surfen, stimmen Sie zu der Verwendung von',
  'legacy.header.products': 'Produkte und Dienstleistungen',
  'legacy.header.about': 'über uns',
  'legacy.header.news': 'Nachrichten & Presse',
  'legacy.header.jobs': 'jobs',
  'legacy.header.contact': 'Kontaktieren Sie uns',
  'legacy.footer.headoffice': 'Hauptsitz',
  'legacy.footer.headquarters': 'Firmensitz',
  'sections.search.products': 'products',
  'sections.search.news': 'News',
  'sections.search.events': 'Events',
  'sections.search.noncategorised': 'Non - categorised',
  'sections.search.seeallproducts': 'See all Products',
  'sections.search.seeallevents': 'See all Events',
  'sections.search.seeallnews': 'See all News',
  'brandListing.byCategories': 'By categories',
  'brandListing.seeProductsFor': 'See products for',
  'brandListing.productsAndServices': 'Products & Services',
  'brandListing.similarBrands': 'Brands with similar products & services',
  'brandListing.visitWebsite': 'Visit Website',
  'brandListing.all': 'All',
  'brandListing.clear': 'Auswahl aufheben',
};
