import styled from 'styled-components';
import Media from '../../../../utils/Media';

export const StyledWrapper = styled.div`
  ${Media({
    width: ['100%', null, '75%'],
  })}
`;

export const StyledCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Media({
    marginBottom: ['30px', null, null, 0],
  })}

  > * {
    margin: 0 0 20px 0;

    @media (min-width: 600px) {
      margin: 0 22px 32px 0;
    }

    @media (min-width: 1024px) and (max-width: 1439px) {
      margin: 0 32px 32px 0;

      &:nth-of-type(3n) {
        margin: 0 0 32px 0;
      }
    }

    @media (min-width: 1440px) {
      margin: 0 32px 32px 0;

      &:nth-of-type(2n) {
        margin: 0 32px 32px 0;
      }

      &:nth-of-type(3n) {
        margin: 0 0 32px 0;
      }
    }
  }
`;

export const StyledPaginationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;

  ${Media({
    display: ['flex', null, null, 'none'],
  })}
`;

export const StyledPaginationListItem = styled.li``;

interface StyledPaginationButton {
  isSelected: boolean;
}

export const StyledPaginationButton = styled.button<StyledPaginationButton>`
  width: 28px;
  height: 24px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => (props.isSelected ? 'bold' : 'normal')};
  outline: none;

  &:focus-visible {
    outline: 1px solid blue;
  }
`;
