import * as React from 'react';
import { useIntl } from 'react-intl';
import Img from 'gatsby-image';

import {
  StyledResultsHeader,
  StyledResultsHeading,
  StyledResultsContainer,
  StyledResultsList,
  StyledResultRow,
  StylededResultContent,
  StyledText,
  StyledResultImageWrapper,
  StyledResultImage,
  StylededResultContentImage,
  StyledResultImageWrapperProductImage,
  StyledImageBoxLink,
  StyledHeading,
  StyledResultsOuterContainer,
} from './Styled';

type ResultType = {
  id: string;
  title: string;
  headings: Array<string>;
  description: string;
  image: string;
  page_type: string; // eslint-disable-line camelcase
  text: string;
  preview_image: any; // eslint-disable-line camelcase
  path: string;
  date: string;
  location: string;
};

type ResultsProps = {
  query: string;
  results: Array<ResultType>;
  productPageSlug: string;
  eventPageSlug: string;
  newsPageSlug: string;
};

const renderDescription = (result: ResultType) => {
  return <StyledText>{result.description}</StyledText>;
};

const Results = ({ query, results }: ResultsProps) => {
  const slicedResults = results.slice(0, 10);

  const totalResults = slicedResults.length;

  const intl = useIntl();

  return (
    <StyledResultsOuterContainer>
      <StyledResultsHeader>
        <StyledResultsHeading>
          {intl.formatMessage({ id: 'sections.search.results.title' }, { total: totalResults, query })}
        </StyledResultsHeading>
      </StyledResultsHeader>
      <StyledResultsContainer>
        <StyledResultsList>
          {slicedResults.map((result: ResultType) => (
            <StyledResultRow key={result.id}>
              {result.page_type === 'product' && result.preview_image ? ( // eslint-disable-line camelcase
                <StylededResultContentImage to={result.path}>
                  <StyledHeading to={result.path}>
                    {result.headings && result.headings.length ? result.headings[0] : result.title}
                  </StyledHeading>
                  {renderDescription(result)}
                </StylededResultContentImage>
              ) : (
                <StylededResultContent>
                  <StyledHeading to={result.path}>
                    {result.headings && result.headings.length ? result.headings[0] : result.title}
                  </StyledHeading>
                  {renderDescription(result)}
                </StylededResultContent>
              )}

              {result.page_type !== 'product' && result.image ? ( // eslint-disable-line camelcase
                <StyledResultImageWrapper to={result.path}>
                  <StyledResultImage>
                    <img alt={result.title} src={result.image} />
                  </StyledResultImage>
                </StyledResultImageWrapper>
              ) : null}

              {result.page_type === 'product' && result.preview_image ? ( // eslint-disable-line camelcase
                <StyledResultImageWrapperProductImage>
                  <StyledImageBoxLink to={result.path}>
                    <Img
                      fluid={JSON.parse(result.preview_image).asset.fluid}
                      alt={JSON.parse(result.preview_image).alt}
                      style={{ width: '80%', maxHeight: '80%', position: 'inherit', margin: '0 auto' }}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </StyledImageBoxLink>
                </StyledResultImageWrapperProductImage>
              ) : null}
            </StyledResultRow>
          ))}
        </StyledResultsList>
      </StyledResultsContainer>
    </StyledResultsOuterContainer>
  );
};

export default Results;
