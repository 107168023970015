import { LocaleType } from './LocaleType';

const locales: Record<string, LocaleType> = {
  en: {
    id: 'en',
    name: 'English',
    path: 'en',
  },
  fr: {
    id: 'fr',
    name: 'French',
    path: 'fr',
  },
  nl: {
    id: 'nl',
    name: 'Dutch',
    path: 'nl',
  },
  de: {
    id: 'de',
    name: 'German',
    path: 'de',
  },
};

export default locales;
