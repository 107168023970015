/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Box } from 'rebass';
import { useIntl } from 'react-intl';

import { getSrc, IGatsbyImageData } from 'gatsby-plugin-image';
import Arrow from '../../../../icons/Arrow';
import {
  StyledAdviceWrapper,
  StyledAdviceContentWrapper,
  StyledAdviceContent,
  StyledAdviceHeading,
  StyledAdviceDescription,
  StyledImageBoxWrapper,
  StyledImageBox,
  StyledAdviceCtaWrapper,
  StyledAdviceCta,
} from './Styled';

export type AdviceCardProps = {
  title: string;
  shortDescription: string;
  link: string;
  image: IGatsbyImageData;
  slug: string;
};

const AdviceCard = React.forwardRef(({ title, shortDescription, image, link }: AdviceCardProps, ref) => {
  const intl = useIntl();

  return (
    <StyledAdviceWrapper ref={ref}>
      <StyledAdviceContentWrapper>
        <StyledAdviceContent>
          <StyledAdviceHeading>{title}</StyledAdviceHeading>
          <StyledAdviceDescription>{shortDescription}</StyledAdviceDescription>
        </StyledAdviceContent>
        <Box>
          <StyledAdviceCtaWrapper>
            <StyledAdviceCta to={link}>
              <Arrow />
              <span>{intl.formatMessage({ id: 'cards.advice.link' })}</span>
            </StyledAdviceCta>
          </StyledAdviceCtaWrapper>
        </Box>
      </StyledAdviceContentWrapper>
      <StyledImageBoxWrapper>
        <StyledImageBox
          sx={{
            backgroundImage: `url(${getSrc(image)})`,
          }}
        />
      </StyledImageBoxWrapper>
    </StyledAdviceWrapper>
  );
});
AdviceCard.displayName = 'AdviceCard';

export default AdviceCard;
