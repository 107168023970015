import styled from 'styled-components';
import Media from '../../../utils/Media';

interface StyledNavigation {
  variant?: string;
}

export const StyledNavigation = styled.ul<StyledNavigation>`
  justify-content: ${props => (props.variant === 'secondary' ? 'flex-start' : 'space-around')};
  list-style: none;
  margin: 0;
  padding: 0;

  @media all and (min-width: 920px) {
    width: ${props => (props.variant === 'secondary' ? '100%' : 'calc(774 / 1324 * 100%)')};
  }

  @media all and (min-width: ${props => props.theme.sizes.lg}) {
    width: ${props => (props.variant === 'secondary' ? '100%' : 'calc(624 / 1324 * 100%)')};
  }

  ${Media({
    display: ['none', null, null, 'flex'],
  })}
`;

interface StyledNavItem {
  variant?: string;
}

export const StyledNavItem = styled.li<StyledNavItem>`
  list-style: none;
  display: inline-block;
  margin-right: ${props => (props.variant === 'secondary' ? '30px' : '0')};

  a {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    padding: 2rem 0px;
    text-decoration: none;
    font-size: ${props => props.theme.navItems.fontSize};
    text-transform: ${props => props.theme.navItems.textTransform};
    font-weight: ${props => props.theme.navItems.fontWeight};
    letter-spacing: ${props => props.theme.navItems.letterSpacing};
    line-height: 2;
    color: ${props => props.theme.colors.darkGray};

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      height: 4px;
      width: 100%;
      background: ${props => props.theme.header.activeColor};
    }

    &:focus,
    &:hover {
      color: ${props => props.theme.header.activeColor};
    }
  }
`;

export const StyledSelectedNavItem = styled(StyledNavItem)`
  margin-right: ${props => (props.variant === 'secondary' ? '24px' : '0')};

  a {
    font-weight: ${props => props.theme.navItems.selectedFontWeight || 'bold'};
    &::after {
      content: '';
    }
  }
`;
