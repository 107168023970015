import * as React from 'react';
import { ThemeConsumer } from 'styled-components';

//
import themes from '../../../themes';
import Cta from '../../Cta';
import StyledLogo from './Styled';

type Props = {
  siteName: string;
  variant?: string;
  defaultLocale?: { id?: string } | null | undefined;
};

const Logo = ({ siteName, variant, defaultLocale = { id: '' } }: Props) => {
  const locale = defaultLocale ? defaultLocale.id : '';

  return (
    <ThemeConsumer>
      {theme => (
        <StyledLogo variant={variant}>
          <Cta to="/" aria-current="page" title={siteName} forceLocale>
            {typeof themes[theme.name].logo === 'string' ? (
              <img src={themes[theme.name].logo} alt="Logo" width="63px" height="84px" />
            ) : (
              themes[theme.name].logo({ locale })
            )}
          </Cta>
        </StyledLogo>
      )}
    </ThemeConsumer>
  );
};

export default Logo;
