import styled from 'styled-components';
//

export const StyledNavigationWrapper = styled.nav`
  width: 100%;
  background: #fff;
  margin: 0 auto;
  padding: 24px 34px;
  max-width: 1800px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 24px;
  align-items: center;
  flex-flow: row nowrap;

  @media all and (max-width: 992px) {
    flex-flow: column wrap;
  }
`;

export const StyledNavigation = styled.div`
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;

  @media all and (max-width: 992px) {
    display: none !important;
  }
`;

export const StyledNavigationMobile = styled.div`
  margin: -72px 0 0;
  background: #f5f5f6;
  z-index: 9;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  padding: 90px 60px 60px;

  @media all and (min-width: 992px) {
    display: none !important;
  }

  @media all and (max-width: 767.98px) {
    padding: 90px 40px 60px;
  }
`;

export const StyledNavigationList = styled.ul`
  margin-left: auto !important;
  margin-right: auto !important;
  flex-direction: row;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0px;
`;

export const StyledNavigationListMobile = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0px;
  list-style: none;
`;

export const StyledNavItem = styled.li`
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.0313rem;
  color: #333;
  -webkit-font-smoothing: antialiased;
`;

export const StyledNavItemMobile = styled.li`
  display: inline-block;
  width: 100%;
  margin: 0 0 10px;
`;

export const StyledNavItemLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  display: block;
  letter-spacing: 0.0313rem;
  background-color: transparent;
  color: #000;
  font-family: 'Encode Sans', sans-serif;
  &:hover {
    color: #28a745;
  }
`;

export const StyledNavItemLinkMobile = styled.a`
  &:hover {
    color: #28a745;
    border-bottom: 1px solid #28a745;
  }
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgb(51, 51, 51);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-block;
  font-family: 'Encode Sans', sans-serif;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5008px;
  line-height: 33px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  text-align: left;
  -webkit-match-parentlileftbodytext-decoration-color: rgb(0, 0, 0);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-size-adjust: 100%;
`;

export const StyledLangSwitcherContainer = styled.div`
  position: relative;
  width: 216px;
  text-align: right;
  display: inline-block;
  @media all and (max-width: 1100px) {
    width: auto;
  }
  &:after {
    content: '\f0d7';
    font-family: fontawesome;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 0.75rem;
  }
`;

export const StyledSelect = styled.select`
  font-weight: 400;
  font-size: 0.75rem;
  color: #666;
  border: 0;
  background: #fff;
  letter-spacing: 0.5px;
  width: 110px;
  display: inline-block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-family: 'Encode Sans', sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  &::-ms-expand {
    display: none;
  }
`;

export const StyledLogo = styled.a`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
`;

export const StyledToggleButton = styled.button`
  margin-top: 20px;
  z-index: 10;
  background: #ccc;
  color: #fff;
  font-size: 0.9375rem;
  padding: 10px 15px;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid transparent;

  @media all and (min-width: 992px) {
    display: none;
  }
`;

export const StyledToggleButtonText = styled.div`
  font-size: 15px;
  font-family: 'Encode Sans', sans-serif;
  font-weight: 600;
  text-align: center;
`;

export const StyledToggleButtonIcon = styled.i`
  margin-left: 12px;
`;
