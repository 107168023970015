import React from 'react';
import { BoxProps } from 'rebass/styled-components';
import { useIntl } from 'react-intl';

import Cta from '../../Cta';
import Button from '../../Button';
import {
  StyledModuleHeading,
  StyledModuleDescription,
  StyledContainer,
  StyledHeadingAndDescriptionWrapper,
  StyledLoadMoreWrapper,
  StyledNewsCardWrapper,
  StyledCardsWrapper,
  StyledHeadingAndDescriptionWrapperSecondary,
  StyledModuleHeadingSecondary,
  StyledModuleDescriptionSecondary,
} from './Styled';
import NewsCard from '../../Cards/NewsCard';
import NewsCardProps from '../../Cards/NewsCard/types';
import { StyledModuleCtaContainer } from '../../Layout/Styled';

export interface NewsListingModuleProps extends BoxProps {
  title: string;
  shortDescription?: string | null;
  news: NewsCardProps[];
  initialAmount?: number;
  incrementAmount?: number;
  variant?: string;
}

const NewsListingModule = React.forwardRef(
  (
    {
      title,
      shortDescription,
      news,
      initialAmount = 6,
      incrementAmount = 8,
      variant = 'primary',
      ...rest
    }: NewsListingModuleProps,
    ref,
  ) => {
    const intl = useIntl();
    const [amountOfNewsItemsToDisplay, setDisplayLimit] = React.useState(initialAmount);

    return (
      <StyledContainer ref={ref} {...(rest as any)} data-appsearch-ignore="true">
        {variant === 'primary' ? (
          <StyledHeadingAndDescriptionWrapper>
            <StyledModuleHeading>{title}</StyledModuleHeading>
            {shortDescription && <StyledModuleDescription>{shortDescription}</StyledModuleDescription>}
          </StyledHeadingAndDescriptionWrapper>
        ) : (
          <StyledHeadingAndDescriptionWrapperSecondary>
            <StyledModuleHeadingSecondary>{title}</StyledModuleHeadingSecondary>
            {shortDescription && (
              <StyledModuleDescriptionSecondary>{shortDescription}</StyledModuleDescriptionSecondary>
            )}
          </StyledHeadingAndDescriptionWrapperSecondary>
        )}
        <StyledCardsWrapper>
          {news
            .map(({ id, ...newsRest }: NewsCardProps) => (
              <StyledNewsCardWrapper key={id}>
                <NewsCard id={id} variant="horizontal" {...newsRest} />
              </StyledNewsCardWrapper>
            ))
            .slice(0, amountOfNewsItemsToDisplay)}
        </StyledCardsWrapper>
        {news.length > amountOfNewsItemsToDisplay ? (
          <StyledLoadMoreWrapper>
            <StyledModuleCtaContainer>
              <Cta
                onClick={() => {
                  setDisplayLimit(amountOfNewsItemsToDisplay + incrementAmount);
                }}
              >
                <Button
                  onClick={() => {
                    setDisplayLimit(amountOfNewsItemsToDisplay + incrementAmount);
                  }}
                >
                  {intl.formatMessage({ id: 'sections.news.loadmore' })}
                </Button>
              </Cta>
            </StyledModuleCtaContainer>
          </StyledLoadMoreWrapper>
        ) : null}
      </StyledContainer>
    );
  },
);
NewsListingModule.displayName = 'NewsListingModule';

export default NewsListingModule;
