import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import Media from '../../utils/Media';
//

const StyledFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
  margin-top: 104px;

  ${Media({
    paddingTop: ['2rem', '2rem', '56px', '56px'],
  })}
`;

export const StyledLegalWrapper = styled.div`
  align-self: flex-end;
  width: 100%;
  height: 152px;
  position: relative;

  ${Media({
    marginTop: [null, null, '3.5rem', '0'],
  })}
`;

export const StyledContactWrapper = styled(Box)`
  margin-right: 15px;
  p {
    line-height: 1.67;
    font-size: ${props => props.theme.footer.fontSize || '12px'};
  }

  color: ${props => props.theme.colors.text};

  ${Media({
    width: ['100%', 'auto'],
    marginBottom: ['2rem', null, '0px'],
  })}
`;

export const StyledFooterTopSection = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 auto;
  max-width: ${props => props.theme.sizes.xl};
  width: 100%;

  ${Media({
    paddingLeft: ['1rem', null, '2rem', '58px'],
    paddingRight: ['1rem', null, '2rem', '58px'],
  })}
`;

// export const StyledFooterBottomSection = styled(Box)`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;

//   margin: 0 auto;
//   max-width: ${props => props.theme.sizes.xl};
//   width: 100%;

//   ${Media({
//     paddingLeft: ['1rem', null, '2rem', '58px'],
//     paddingRight: ['1rem', null, '2rem', '58px'],
//   })}
// `;

export const StyledFooterMainNavigation = styled.div`
  ${Media({
    width: ['100%', 'auto'],
  })}
`;

export const StyledFooterMainNavigationList = styled.ul`
  list-style-type: none;
  padding: 0px;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  margin-top: 8px;
`;

export const StyledFooterMainNavigationListItem = styled.li`
  text-transform: ${props => props.theme.footer.textTransform || 'uppercase'};
  ${Media({
    lineHeight: ['1.71', null, null, '2'],
    marginBottom: ['8px', '8px', '0px', '0px'],
  })}
  a {
    color: ${props => props.theme.colors.darkGray};
    letter-spacing: ${props => props.theme.footer.letterSpacing || '2px'};
    text-decoration: none;
    &:hover {
      color: ${props => props.theme.footer.activeColor};
      border-bottom: 1px solid ${props => props.theme.footer.activeColor};
    }
    font-size: ${props => props.theme.footer.navigationFontSize || '12px'};
  }
`;

export const StyledFooterSocialList = styled.ul`
  display: flex;
  padding: 0;
  margin-top: 10px;
  list-style-type: none;
  margin-top: 41px;
`;

export const StyledFooterSocialListItem = styled.li`
  margin-right: 25px;
  a svg path {
    fill: ${props => props.theme.colors.darkGray};
  }

  &:hover {
    svg > * {
      fill: ${props => props.theme.footer.activeColor};
    }
  }
`;

export const StyledFooterContactSection = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  ${Media({
    width: ['100%', null, null, 'auto'],
  })}
`;

export default StyledFooter;
