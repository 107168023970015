import * as React from 'react';
import { FlexProps } from 'rebass';
//
import { LocaleType } from '../../i18n/LocaleType';
import { Search } from './Search';
import { LangSwitcher } from './LangSwitcher';
import {
  StyledHeader,
  StyledRightSection,
  StyledHeaderTop,
  StyledSeparator,
  StyledHeaderInnerContainer,
  StyledHamburgerMenu,
  StyledHeaderBottom,
  StyledMobileNavigation,
  StyledSearchMenu,
  StyledMobileIcons,
  StyledHeaderTopSecondaryVariant,
  StyledHeaderTopSecondaryVariantInner,
} from './Styled';
import Logo from './Logo';
import PrimaryNavigation from './PrimaryNavigation';
import { useScrollDirection } from '../../utils';
import { Hamburger as HamburgerIcon, Search as SearchIcon } from '../../icons';

import { LocationContext } from '../Layout/Location';
import SecondaryNavigation from './SecondaryNavigation';
import SecondaryNavigationMobile from './SecondaryNavigationMobile';
import { NavItemType } from './types';
import { MobileSearch } from './MobileSearch';
import matchUrl from './matchUrl';

interface Props extends FlexProps {
  siteName: string;
  navigation: Array<NavItemType>;
  defaultLocale: LocaleType;
  activeLocales: Array<string>;
  searchFunction: (searchTerm: string) => void;
  variant?: string;
}

const isOnChildPage = (currentPageUrl: string, childItems: Array<NavItemType>) => {
  return childItems.find(navItem => matchUrl(currentPageUrl, navItem));
};

const showActivePageSubnav = (activeMenuItem: NavItemType | undefined, currentPageUrl: string): boolean => {
  if (!activeMenuItem) {
    return false;
  }

  return Boolean(activeMenuItem && activeMenuItem.children && isOnChildPage(currentPageUrl, activeMenuItem.children));
};

const renderSecondaryNavigation = (
  activeMenuItem: NavItemType | undefined,
  activeMenuUrl: string,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
) => {
  if (activeMenuItem && activeMenuItem.children && activeMenuItem.children.length > 0) {
    return (
      <StyledHeaderBottom onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <SecondaryNavigation items={activeMenuItem.children} activeMenu={activeMenuUrl} />
      </StyledHeaderBottom>
    );
  }

  return null;
};

// Could maybe tidy this up
const renderSecondaryMobileNavigation = (
  isVisible: boolean,
  menuItems: Array<NavItemType>,
  activeMenuUrl: string,
  closeNav: () => void,
  defaultLocale: LocaleType,
  activeLocales: Array<string>,
) => {
  if (isVisible) {
    return (
      <StyledMobileNavigation>
        <SecondaryNavigationMobile
          items={menuItems}
          activeMenuUrl={activeMenuUrl}
          onClose={() => {
            closeNav();
          }}
          defaultLocale={defaultLocale}
          activeLocales={activeLocales}
        />
      </StyledMobileNavigation>
    );
  }

  return null;
};

const Header = ({ siteName, navigation, defaultLocale, activeLocales, searchFunction, variant }: Props) => {
  const currentLocation = React.useContext(LocationContext);
  const [currentPageUrl] = React.useState(currentLocation.pathname);
  const [visible, setVisible] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [currentHoverItem, setCurrentHoverItem] = React.useState({} as NavItemType);
  const [previousHoverItem, setPreviousHoverItem] = React.useState({} as NavItemType);
  const [mobileSecondaryMenuVisible, setMobileSecondaryMenuVisible] = React.useState(false);
  const [mobileSearchVisible, setMobileSearchVisible] = React.useState(false);
  const direction = useScrollDirection({ initialDirection: 'up', thresholdPixels: 50, off: false });

  React.useEffect(() => {
    if (loaded) {
      setVisible(direction === 'up');
    }
    setLoaded(true);
  }, [direction]);

  const activeMenuItem = navigation.find(navItem => {
    if (!currentPageUrl) {
      return false;
    }
    return matchUrl(currentPageUrl, navItem);
  });

  React.useEffect(() => {
    if (document && document.getElementById('main-wrapper')) {
      if (activeMenuItem && showActivePageSubnav(activeMenuItem, currentPageUrl) && window.document) {
        const mainElement: HTMLElement | null = document.getElementById('main-wrapper') || null;
        mainElement && mainElement.classList.add('active-secondary-menu'); // eslint-disable-line no-unused-expressions
      }
    }
  }, [direction]);

  React.useEffect(() => {
    setPreviousHoverItem(currentHoverItem);
  }, [setPreviousHoverItem, currentHoverItem]);

  const secondary = (activeMenuItem && activeMenuItem.children) || null;
  const classes = [];

  const handleNavItemHover = (navItem: NavItemType) => {
    if (navItem.children && navItem.children.length > 0) {
      setCurrentHoverItem(navItem);
    }

    if (!navItem.children) {
      setCurrentHoverItem({} as NavItemType);
    }
  };

  const handleMouseEnterSecondaryNav = () => {
    setCurrentHoverItem(previousHoverItem);
  };

  const handleMouseLeaveSecondaryNav = () => {
    setCurrentHoverItem({} as NavItemType);
  };

  if (secondary) {
    classes.push('has-secondary');
  }

  if (loaded) {
    if (!visible) {
      classes.push('is-hidden');
    }

    if (activeMenuItem && showActivePageSubnav(activeMenuItem, currentPageUrl)) {
      classes.push('active-secondary-menu');
    }
  }

  return (
    <>
      <StyledHeader className={classes.join(' ')}>
        {variant === 'secondary' && (
          <StyledHeaderTopSecondaryVariant>
            <StyledHeaderTopSecondaryVariantInner>
              <Search
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
                variant={variant}
              />
              <LangSwitcher defaultLocale={defaultLocale.id} activeLocales={activeLocales} variant={variant} />
            </StyledHeaderTopSecondaryVariantInner>
          </StyledHeaderTopSecondaryVariant>
        )}
        <StyledHeaderInnerContainer>
          <StyledHeaderTop>
            {mobileSearchVisible ? (
              <MobileSearch
                searchFunction={searchTerm => {
                  searchFunction(searchTerm);
                }}
                onClose={() => setMobileSearchVisible(false)}
              />
            ) : (
              <>
                <Logo defaultLocale={defaultLocale} siteName={siteName} variant={variant} />
                <PrimaryNavigation
                  currentHoverItem={currentHoverItem}
                  items={navigation}
                  activeMenuItem={activeMenuItem || null}
                  onMouseEnter={handleNavItemHover}
                  variant={variant}
                />
                {variant !== 'secondary' && (
                  <StyledRightSection>
                    <Search
                      searchFunction={searchTerm => {
                        searchFunction(searchTerm);
                      }}
                    />
                    <LangSwitcher defaultLocale={defaultLocale.id} activeLocales={activeLocales} />
                  </StyledRightSection>
                )}
                <StyledMobileIcons>
                  <StyledSearchMenu>
                    <span
                      onClick={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      onKeyPress={() => setMobileSearchVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      <SearchIcon />
                    </span>
                  </StyledSearchMenu>
                  <StyledHamburgerMenu>
                    <span
                      onClick={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      onKeyPress={() => setMobileSecondaryMenuVisible(!mobileSecondaryMenuVisible)}
                      role="button"
                      tabIndex={0}
                    >
                      <HamburgerIcon />
                    </span>
                  </StyledHamburgerMenu>
                </StyledMobileIcons>
              </>
            )}
          </StyledHeaderTop>
        </StyledHeaderInnerContainer>
        <StyledSeparator />
        {!currentHoverItem.to && activeMenuItem && showActivePageSubnav(activeMenuItem, currentPageUrl) ? (
          <StyledHeaderBottom>
            <SecondaryNavigation items={activeMenuItem.children} activeMenu={currentPageUrl} />
          </StyledHeaderBottom>
        ) : null}
        {currentHoverItem && currentHoverItem.to
          ? renderSecondaryNavigation(
              currentHoverItem,
              currentPageUrl,
              handleMouseEnterSecondaryNav,
              handleMouseLeaveSecondaryNav,
            )
          : null}
      </StyledHeader>
      {renderSecondaryMobileNavigation(
        mobileSecondaryMenuVisible,
        navigation,
        currentPageUrl,
        setMobileSecondaryMenuVisible as () => void,
        defaultLocale,
        activeLocales,
      )}
    </>
  );
};

export default Header;
