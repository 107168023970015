import * as React from 'react';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';

// eslint-disable-next-line import/no-duplicates
import { enGB as en, nl, fr, de } from 'date-fns/locale';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
//
// import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import NewsCardProps from './types';
import Cta from '../../Cta';
import Arrow from '../../../icons/Arrow';
import {
  StyledTitle,
  StyledImageBoxLink,
  StyledContentBox,
  StyledNewsDate,
  StyledDescription,
  StyledCtaWrapper,
  StyledCta,
} from './Styled';

const getDate = (date: string, dateLocale: any) => {
  try {
    return format(new Date(date), 'MMMM do, yyyy', { locale: dateLocale });
  } catch (e) {
    return date;
  }
};

const Vertical = ({ title, shortDescription, image, link, date }: NewsCardProps) => {
  const intl = useIntl();
  const dateLocales: any = { en, nl, fr, de };
  const dateLocale = dateLocales[intl.locale] || dateLocales.en;
  return (
    <Box
      sx={{
        height: '100%', // theme.colors.primary
      }}
    >
      {image && image.asset ? (
        <StyledImageBoxLink to={link}>
          {/* <Img
            fluid={image.asset.fluid}
            alt={image.alt}
            style={{ margin: '0 auto', height: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          /> */}
          <GatsbyImage
            image={image.asset.gatsbyImageData}
            alt={image.alt ? image.alt : image.title}
            style={{ margin: '0 auto', height: '100%' }}
            objectFit="cover"
          />
        </StyledImageBoxLink>
      ) : null}

      <StyledContentBox>
        {date && <StyledNewsDate>{getDate(date, dateLocale)}</StyledNewsDate>}

        <StyledTitle>
          <Cta to={link}>{title}</Cta>
        </StyledTitle>

        <StyledDescription>{shortDescription}</StyledDescription>

        <StyledCtaWrapper>
          <StyledCta to={link}>
            <Arrow />
            <span>{intl.formatMessage({ id: 'cards.news.link' })}</span>
          </StyledCta>
        </StyledCtaWrapper>
      </StyledContentBox>
    </Box>
  );
};

export default Vertical;
