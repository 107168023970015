/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const GlobalStyle = createGlobalStyle<any>`
    ${normalize}

    * {
        box-sizing: border-box;
    }
    html {
        overflow-y: auto;
    }
    html, body {
        width: 100%;
    }
    body {
        ${props => ({ ...props.theme.styles.root })};
        >div {
            height: 100%;
        }
        #gatsby-focus-wrapper {
            height: 100%;
        }
    }
    `;

export default GlobalStyle;
