import React from 'react';
import Helmet from 'react-helmet';
import { withTheme } from 'styled-components';
import { IntlContext } from '../../i18n';
import Header from '../Header';
import Footer from '../Footer';
import CookieBanner from '../CookieBanner';
import Metatags from './Metatags';
import { EnabledLocalesContext } from './PageWrapper';
import { StyledContainer, StyledFooter, StyledHeader, StyledMain, StyledRootContainer } from './Styled';
import { RichTextProps } from '../RichText';
import { TranslatedSlugProvider } from './TranslatedSlugContext';
import {
  ContentfulBasicPage,
  ContentfulEvent,
  ContentfulNews,
  ContentfulAdvice,
  ContentfulProduct,
} from '../../../generated/gatsby.types';

type PageProps = {
  /** Sitedata including navigation elements */
  siteData: any;
  /** standard children prop: accepts any valid React Node */
  children: React.ReactNode;
  /** controlls if swifttype can index this pages contents */
  indexContents: boolean;
  headerSearchFunction: (serchTerm: string) => void;
  loadCookieBanner?: boolean;
  cookieBannerTitle: string;
  cookieBannerDescription: RichTextProps;
  showFooterSubscribeForm: boolean;
  showFooterSubscribeFormCountryField: boolean;
  subscribeFormHeading?: string | null;
  flexmailId?: string | null;
  searchPreviewImage: any;
  theme: any;
  headerVariant?: string;
  pageLocale: string;
  onSubscribeFormSubmit: (email: string, country: string, locale: string) => void;
  translatedSlugs: Array<ContentfulBasicPage | ContentfulEvent | ContentfulNews | ContentfulAdvice | ContentfulProduct>;
};

const FONTMAP: Record<string, string[]> = {
  avevebiochem: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  belcrop: [],
  pomagro: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  palital: [],
  arieblok: ['/fonts/ropasans.css'],
  peka: ['/fonts/helvetica.css'],
  sabe: ['https://fonts.googleapis.com/css2?family=Krub:wght@300;400;600;700&display=swap'],
  servagri: ['/fonts/encodesans.css'],
  scoriethom: ['/fonts/encodesans.css'],
  aveveevents: ['/fonts/encodesans.css'],
  kasperfaunafood: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  dumoulin: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  spoormans: ['https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600;700&display=swap'],
  osmo: ['https://fonts.googleapis.com/css2?family=Hind:wght@300;400;600;700&display=swap'],
  equifirst: ['/fonts/myriadpro.css'],
  arvesta: ['/fonts/encodesans.css'],
  hobbyfirst: ['/fonts/marine.css'],
  lannoo: ['https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap'],
  hermoo: ['https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700&display=swap'],
  hafner: ['/fonts/futura.css'],
  alliance: [
    'https://fonts.googleapis.com/css2?family=Encode+Sans:wght@300;400;600;700&display=swap',
    'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap',
  ],
  globalfeedmill: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  cofabel: ['/fonts/johndeeresans.css'],
  walagri: ['/fonts/encodesans.css'],
  sanac: ['/fonts/encodesans.css'],
  pentatrading: ['https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'],
  hortiplan: [
    'https://fonts.googleapis.com/css2?family=Encode+Sans&family=Nunito+Sans:wght@300;400;600;700&display=swap',
  ],
  aveveagrarisch: ['https://use.typekit.net/hji8mfd.css'],
};

const MainLayout = ({
  siteData,
  children,
  headerSearchFunction,
  loadCookieBanner = true,
  cookieBannerTitle,
  cookieBannerDescription,
  showFooterSubscribeForm,
  showFooterSubscribeFormCountryField,
  subscribeFormHeading = null,
  flexmailId = null,
  searchPreviewImage,
  headerVariant,
  pageLocale,
  translatedSlugs,
  onSubscribeFormSubmit,
  ...rest
}: PageProps) => {
  const locale: any = React.useContext(IntlContext);
  const locales: any = React.useContext(EnabledLocalesContext);
  const activeLocales = Object.keys(locales);

  const { header, footer } = siteData;

  return (
    <StyledRootContainer>
      <Helmet>
        {FONTMAP[rest.theme.name.toLowerCase()].map((font: string) => (
          <link key={font} rel="stylesheet" href={font} />
        ))}
        <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=geocoding`} />
      </Helmet>
      <Metatags
        siteName={siteData.metaData.siteName ? siteData.metaData.siteName : ''}
        title={siteData.metaData.title ? siteData.metaData.title : ''}
        description={siteData.metaData.description ? siteData.metaData.description : ''}
        canonical={siteData.metaData.canonical ? siteData.metaData.canonical : null}
        nofollow={siteData.metaData.nofollow ? siteData.metaData.nofollow : null}
        noindex={siteData.metaData.noindex ? siteData.metaData.noindex : null}
        swiftTypeDate={siteData.metaData.swiftTypeDate ? siteData.metaData.swiftTypeDate : null}
        swiftTypeLocation={siteData.metaData.swiftTypeLocation ? siteData.metaData.swiftTypeLocation : null}
        image={siteData.metaData.image ? siteData.metaData.image : null}
        pageLocale={siteData.metaData.pageLocale ? siteData.metaData.pageLocale : locale.id}
        pageType={siteData.metaData.pageType ? siteData.metaData.pageType : ''}
        updatedAt={siteData.metaData.updatedAt ? siteData.metaData.updatedAt : ''}
        searchPreviewImage={searchPreviewImage}
        locale={siteData.metaData.pageLocale}
        ogPageType={siteData.metaData.ogPageType}
        searchTags={siteData.metaData.searchTags}
      />
      <StyledContainer>
        <StyledHeader data-appsearch-ignore="true">
          <TranslatedSlugProvider value={translatedSlugs}>
            <Header
              siteName={siteData.metaData.siteName}
              defaultLocale={locale}
              activeLocales={activeLocales}
              navigation={header.navigation}
              searchFunction={headerSearchFunction}
              variant={headerVariant}
            />
          </TranslatedSlugProvider>
        </StyledHeader>
        <StyledMain id="main-wrapper">{children}</StyledMain>
      </StyledContainer>
      <StyledFooter>
        <Footer
          footerNavigation={footer.navigation}
          legalLinks={footer.legalLinks}
          socialLinks={footer.socialLinks}
          contacts={footer.contacts}
          onSubmitSubscribe={onSubscribeFormSubmit}
          showSubscribeForm={showFooterSubscribeForm}
          showSubscribeFormCountrySelector={showFooterSubscribeFormCountryField}
          subscribeFormHeading={subscribeFormHeading}
          flexmailId={flexmailId}
          theme={rest.theme.name.toLowerCase()}
          pageLocale={pageLocale}
        />
      </StyledFooter>
      {loadCookieBanner && <CookieBanner title={cookieBannerTitle} description={cookieBannerDescription} />}
    </StyledRootContainer>
  );
};

export default withTheme(MainLayout);
