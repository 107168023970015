export default {
  'globals.yes': 'Oui',
  'globals.no': 'Non',
  'globals.download': 'Download',
  'globals.featuredProducts': 'Produits populaires',
  'header.search.placeholder': 'Chercher',
  'header.selectlanguage': 'Choisir la langue',
  'footer.copyright': '© {year} Arvesta. Tous les droits sont réservés.',
  'footer.newsletter.heading':
    'Souhaitez-vous recevoir des nouvelles et des conseils qui correspondent à ce qui vous intéresse? Nous sommes heureux de le faire pour vous!',
  'footer.newsletter.subscribe': 'souscrire',
  'footer.newsletter.placeholder': 'Entrez votre adresse e-mail',
  'footer.newsletter.success': 'Vous êtes abonné, merci. Vous aurez bientôt de nos nouvelles',
  'footer.newsletter.error': 'l y a eu une erreur avec les informations que vous avez saisies, veuillez réessayer',
  'footer.subscribe.enteremail': 'Entrez votre adresse e-mail',
  'footer.subscribe.subscribe': 'souscrire',
  'footer.subscribe.invalid_email': 'Pouvez-vous saisir votre adresse e-mail ?',
  'footer.subscribe.success': 'Vous êtes abonné, merci. Vous aurez bientôt de nos nouvelles.',
  'footer.subscribe.failure': 'l y a eu une erreur avec les informations que vous avez saisies, veuillez réessayer',
  'footer.subscribe.subscribing': 'Veuillez patienter pendant que nous essayons de nous connecter au serveur',
  'product.certifications.title': 'Certifications du produit',
  'forms.contact.errors.required': 'Veuillez remplir ce champ obligatoire',
  'forms.contact.errors.email': 'Pouvez-vous saisir votre adresse e-mail ?',
  'forms.contact.failure': 'l y a eu une erreur avec les informations que vous avez saisies, veuillez réessayer',
  'forms.contact.success': 'merci, nous étudions déjà votre message',
  'forms.contact.title': 'Prenez contact avec nous',
  'forms.contact.description': 'Remplissez le formulaire ci-dessous et notre équipe vous contactera',
  'forms.contact.fullname.label': 'Nom complet',
  'forms.contact.fullname.placeholder': 'Introduisez votre nom complet',
  'forms.contact.email.label': 'adresse électronique',
  'forms.contact.email.placeholder': 'Entrez votre adresse e-mail',
  'forms.contact.message.label': 'Message',
  'forms.contact.message.placeholder': 'Communiquez-nous votre message',
  'forms.contact.newsletter.title':
    'Souhaitez-vous recevoir des nouvelles et des conseils qui correspondent à ce qui vous intéresse? Nous sommes heureux de le faire pour vous!',
  'forms.contact.submit': 'Envoyez',
  'sections.dealer.placeholder': 'Remplissez le code postal et la localité',
  'sections.dealer.loadmore': 'Afficher tout',
  'sections.dealer.invalidaddress': "Nous n'avons pas pu trouver l'adresse",
  'sections.dealer.search': 'Rechercher',
  'sections.dealer.maplink': 'voir sur la carte',
  'sections.dealer.total': 'résultats pour',
  'sections.dealer.clear': 'Décocher',
  'sections.search.results.title': '{total} résultats pour {query}',
  'sections.search.noresults.title': 'résultats pour',
  'cards.category.link': 'voir plus',
  'cards.product.link': 'Voir plus',
  'cards.event.link': "voir l'événement",
  'cards.news.link': 'continuer à lire',
  'cards.advice.link': "lire l'article",
  'sections.events.loadmore': "charger plus d'événements",
  'sections.events.loadmorepast': "charger plus d'événements passés",
  'sections.advice.loadmore': 'charger plus de conseils',
  'sections.news.loadmore': 'charger plus de nouvelles',
  'sections.product.loadmore': 'Voir plus',
  'sections.category.loadmore': 'voir plus de produits',
  'sections.product.noproductsfoundfor': 'aucun produit trouvé pour',
  'sections.product.table.number': 'n°',
  'sections.product.table.product': 'produit',
  'sections.product.table.weight': 'poids',
  'sections.product.table.brochure': 'Brochure',
  'sections.product.table.download': 'Download',
  'sections.contact.offices': "l'offices",
  'sections.contact.salesteam': "l'équipe",
  'sections.contact.viewonmap': 'voir sur la carte',
  'sections.contact.openinghours': "Horaires d'ouvertures",
  'legacy.cookie.description': 'En continuant à naviguer sur le site, vous acceptez notre utilisation des',
  'legacy.header.products': 'produits & services',
  'legacy.header.about': 'à propos de nous',
  'legacy.header.news': 'nouvelles & presse',
  'legacy.header.jobs': 'jobs',
  'legacy.header.contact': 'contactez-nous',
  'legacy.footer.headoffice': 'Siège central',
  'legacy.footer.headquarters': 'Siège social',
  'sections.search.products': 'products',
  'sections.search.news': 'News',
  'sections.search.events': 'Events',
  'sections.search.noncategorised': 'Non - categorised',
  'sections.search.seeallproducts': 'See all Products',
  'sections.search.seeallevents': 'See all Events',
  'sections.search.seeallnews': 'See all News',
  'brandListing.byCategories': 'By categories',
  'brandListing.seeProductsFor': 'See products for',
  'brandListing.productsAndServices': 'Products & Services',
  'brandListing.similarBrands': 'Brands with similar products & services',
  'brandListing.visitWebsite': 'Visit Website',
  'brandListing.all': 'All',
  'brandListing.clear': 'Décocher',
};
